import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap';
import EnvironmentBanner from '../../components/Environment/EnvironmentBanner/EnvironmentBanner';
import EnvironmentContent from '../../components/Environment/EnvironmentContent/EnvironmentContent';
import NewsAndUpdates from '../../components/homepage/NewsAndUpdates/NewsAndUpdates';


const EnvironmentPage = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'instant' });
    }, []);
    
    return (
        <Container fluid={true} className="p-0">
            <EnvironmentBanner />
            <EnvironmentContent />
            <NewsAndUpdates />
        </Container>
    )
}

export default EnvironmentPage
