import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import OfferPage from '../../components/OfferPage/OfferPage'
import NewsAndUpdates from '../../components/homepage/NewsAndUpdates/NewsAndUpdates'
import OfferPageBanner from '../../components/OfferPage/OfferPageBanner/OfferPageBanner'

const OfferPackage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  const [selectedCategory, setSelectedCategory] = useState('All');

  return (
    <Container fluid={true} className="p-0">
      <OfferPageBanner 
        selectedCategory={selectedCategory} 
        setSelectedCategory={setSelectedCategory} 
      />
      <OfferPage selectedCategory={selectedCategory} />
      <NewsAndUpdates />
    </Container>
  )
}

export default OfferPackage
