import { Badge, Button, Image } from 'antd'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import NonVeg1 from '../../../assets/images/dining/nonveg/img-butter-chicken.webp'
import NonVeg2 from '../../../assets/images/dining/nonveg/img-rogan-josh.webp'
import NonVeg3 from '../../../assets/images/dining/nonveg/img-chicken-biryani.webp'
import NonVeg4 from '../../../assets/images/dining/nonveg/img-fish-curry.webp'
import NonVeg5 from '../../../assets/images/dining/nonveg/img-kadhai-chicken.webp'
import NonVeg6 from '../../../assets/images/dining/nonveg/img-mutton-korma.webp'
import NonVeg7 from '../../../assets/images/dining/nonveg/img-prawn-masala.webp'
import NonVeg8 from '../../../assets/images/dining/nonveg/img-tandoori-chicken.webp'
import './NonVegetarianFood.css';
import { ArrowRightOutlined } from '@ant-design/icons'

const nonVegItems = [
    {
        title: 'Chicken Thali',
        price: 150,
        reviews: 58,
        image: NonVeg1,
    },
    {
        title: 'Chicken Briyani',
        price: 150,
        reviews: 72,
        image: NonVeg2,
    },
    {
        title: 'Chilly Kabab',
        price: 150,
        reviews: 44,
        image: NonVeg3,
    },
    {
        title: 'Chicken Noodles',
        price: 150,
        reviews: 57,
        image: NonVeg4,
    },
    {
        title: 'Chicken Fried Rice',
        price: 150,
        reviews: 61,
        image: NonVeg5,
    },
    {
        title: 'Egg Noodles',
        price: 150,
        reviews: 36,
        image: NonVeg6,
    },
    {
        title: 'Egg Fried Rice',
        price: 150,
        reviews: 40,
        image: NonVeg7,
    },
    {
        title: 'Fish',
        price: 150,
        reviews: 50,
        image: NonVeg8,
    },
];

const NonVegetarianFood = () => {
    const handleGetDirection = () => {
        window.open(
          "https://www.google.com/maps/place/JOY+N+JOYY+WATER+PARK/@14.2720495,74.4605375,17z/data=!3m1!4b1!4m5!3m4!1s0x3bbc3b4fbead1153:0x849a5b8d43639a05!8m2!3d14.2720495!4d74.4627262",
          "_blank"
        );
    };

    return (
        <Container className='py-5'>
            <Row className="d-flex align-items-center justify-content-center">
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='text-center'>
                
                <div className="non-vegbanner"></div>

                <Row className="d-flex align-items-center justify-content-between py-5">
                    <Col xxl={7} xl={7} lg={7} md={12} sm={12} xs={12} className="food-content text-start">
                        <Badge 
                            color="#AE2100" 
                            text="NON-VEGETARIAN FEASTS"
                            className={`${ window?.innerWidth > 834.98 ? "fs-20" : window?.innerWidth <= 425 ? "fs-14" : "fs-20" } fw-600 badge-custom2`}
                        />
                        <h2 className={`${ window?.innerWidth > 834.98 ? "fs-40" : window?.innerWidth <= 425 ? "fs-24" : "fs-32" } fw-800 pt-2 italic`}>
                            Satisfy Your Cravings
                        </h2>
                        <p className={`${ window?.innerWidth > 834.98 ? "fs-20" : window?.innerWidth <= 425 ? "fs-16" : "fs-18" } fw-400`}>
                            Indulge in a range of non-vegetarian specialties cooked to perfection.
                            From succulent meats to flavorful gravies, this section promises a feast for meat lovers.
                        </p>
                    </Col>

                    {/* <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12} className="food-content text-start">
                        <div className="d-flex ratings">
                            <div className='food-rate px-2'>
                                <span className="fw-600 fs-20 text-white">4.5</span>                                           
                                <span className="fw-600 fs-20 text-white ms-2">★</span>
                            </div>
                            <p className="fs-22 fw-700 mb-0 ms-3">623
                            <span className={`${ window?.innerWidth > 834.98 ? "fs-20" : window?.innerWidth <= 425 ? "fs-16" : "fs-18" } fw-400 ms-2`}>Dining ratings</span>
                            </p>
                        </div>
                        
                        <div className='pt-3'>
                            <p className={`${ window?.innerWidth > 834.98 ? "fs-20" : window?.innerWidth <= 425 ? "fs-16" : "fs-18" } fw-400 mb-0`}>Breakfast timing: 7.00pm to 10.30pm</p>
                            <p className={`${ window?.innerWidth > 834.98 ? "fs-20" : window?.innerWidth <= 425 ? "fs-16" : "fs-18" } fw-400`}>Lunch timing: 12.00pm to 3.30pm</p>
                        </div>                            
                    </Col> */}
                
                    <Col xxl={5} xl={5} lg={5} md={6} sm={12} xs={12} className="food-content text-end">
                        <div>
                            <Button 
                                className={`${ window?.innerWidth > 834.98 ? "fs-20" : window?.innerWidth <= 425 ? "fs-16" : "fs-16" } fw-700 booking-btn direct-btn`}
                                size="large"
                                onClick={handleGetDirection}
                            >
                                <span className='fs-5'><ArrowRightOutlined/></span>
                                Get Direction
                            </Button>
                            <p className={`${ window?.innerWidth > 834.98 ? "fs-20" : window?.innerWidth <= 425 ? "fs-16" : "fs-18" } fw-400 pt-3`}>Dining only available at the park</p>
                        </div>
                    </Col>
                </Row>

                <Row>
                    {nonVegItems.map((item, index) => (
                        <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12} className="mb-4">
                            <div className="text-center">
                                <Image
                                    src={item.image}
                                    alt={item.title}
                                    preview={false}
                                    style={{ borderRadius: '24px' }}
                                />
                                <div className="d-flex justify-content-between align-items-center pt-3">
                                    <h4 className={`${ window?.innerWidth > 834.98 ? "fs-24" : window?.innerWidth <= 425 ? "fs-22" : "fs-22" } fw-700 italic`} style={{textAlign: 'left'}}>{item.title}</h4>
                                    <div className="d-flex align-items-center">
                                        <span className="ms-2 fs-20 text-success">★</span>
                                        <span className="fw-400 fs-20 ms-1 text-success">4.5</span>                                           
                                        <p className="fw-400 fs-20 mb-0 ms-2">({item.reviews})</p>
                                    </div>
                                </div>

                                <p className="fs-24 fw-400 text-start">${item.price}</p>
                            </div>
                        </Col>
                    ))}
                </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default NonVegetarianFood
