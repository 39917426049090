import BreakFast from "./breakfast";
import Evening from "./evening";
import Water from "./water";

class Icons {
  static Water = Water;
  static BreakFast = BreakFast;
  static Evening = Evening;
}

export default Icons;
