import { Button, Card, Checkbox, Form, Image, Input, Select } from 'antd';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Signup.css';
import SignupIcon from '../../../assets/images/bookingimages/SignupIcon.png';
import GoogleIcon from '../../../assets/images/bookingimages/google.png';
import FormItem from 'antd/es/form/FormItem';
import { useNavigate } from 'react-router-dom';
import useSignUp from '../../../hooks/common/useSignUp';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const Signup = () => {
    const navigate = useNavigate();
    const { Option } = Select;
    const onFinish = (values) => {
        navigate('/accountsuccess');
    };

    const { isLoading, form, onSubmit } = useSignUp();
    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const validateEmailOrPhone = (_, value) => {
        const phoneRegex = /^[0-9]{10}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!value || emailRegex.test(value) || phoneRegex.test(value)) {
            return Promise.resolve();
        }

        return Promise.reject(new Error('Please enter a valid email or phone number.'));
    };

    const validatePassword = (_, value) => {
        if (!value || value.length >= 6) {
            return Promise.resolve();
        }

        return Promise.reject(new Error('Password must be at least 6 characters long.'));
    };

    const validateConfirmPassword = ({ getFieldValue }) => ({
        validator(_, value) {
            if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
            }

            return Promise.reject(new Error('Passwords do not match!'));
        },
    });

    return (
        <Container fluid >
            <Row className='align-items-center justify-content-center py-5'>
                <Col xxl={4} xl={4} lg={6} md={8} sm={12} xs={12}>
                    <Card className='p-3' style={{ borderRadius: '16px' }}>
                        <Row className='d-flex align-items-center justify-content-center'>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='text-center'>
                                <Image
                                    src={SignupIcon}
                                    alt="Signup"
                                    preview={false}
                                    style={{ width: '88px', height: '88px' }}
                                />
                                <h2 className='fs-24 fw-700 text-black'>Signup</h2>
                                <p className='fs-16 fw-400' style={{ color: '#60616C' }}>
                                    Already have an account,
                                    <span
                                        className='fs-16 fw-600 text-black'
                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                        onClick={() => navigate('/login')}
                                    >
                                        Login
                                    </span>
                                </p>

                                <Button
                                    type='default'
                                    size='large'
                                    className='booking-btn w-100'
                                    style={{ border: '1px solid #303136'}}
                                >
                                    <div>
                                        <Image
                                            src={GoogleIcon}
                                            alt="Google"
                                            preview={false}
                                            className="pt-2"
                                            // style={{ width: '20px', height: '20px' }}
                                        />
                                    </div>
                                    <span className='fs-16 fw-700 text-black'>Log in with Google</span>
                                </Button>

                                <div className="d-flex align-items-center justify-content-center py-2" style={{ position: 'relative', margin: '20px 0' }}>
                                    <hr style={{ width: '45%', borderTop: '1px solid #E2E4E9', margin: '0 20px' }} />
                                    <span style={{ position: 'absolute', backgroundColor: '#fff' }}>OR</span>
                                    <hr style={{ width: '45%', borderTop: '1px solid #E2E4E9', margin: '0 20px' }} />
                                </div>
                            </Col>
                        </Row>

                        <Row className='d-flex align-items-center justify-content-center'>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='text-center'>
                                <Form
                                    onFinish={onSubmit}
                                >
                                    <FormItem
                                        name="name"
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div>
                                            <Input
                                                className="floating-input fs-14 fw-400"
                                                placeholder="Full Name"
                                                style={{ border: '1px solid #303136' }}
                                            />
                                            <label className="floating-label fs-12">Full Name</label>
                                        </div>
                                    </FormItem>

                                    <Form.Item
                                        name="customerType"
                                    >
                                        <Select size='large' placeholder="Group Type" className='signup-type'>
                                            <Option value="Individual">Individual</Option>
                                            <Option value="Bulkbooking">Bulk Booking</Option>
                                            <Option value="College">College</Option>
                                            <Option value="Corporate">Corporate</Option>
                                            <Option value="Institution">Institution</Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="email"
                                        rules={[{ required: true, message: 'Please input your mail!' }]}
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div>
                                            <Input
                                                className="floating-input fs-14 fw-400"
                                                placeholder="Email or Phone"
                                                style={{ border: '1px solid #303136' }}
                                            />
                                            <label className="floating-label fs-12">Email or Phone</label>
                                        </div>
                                    </Form.Item>

                                    <Form.Item
                                        name="password"
                                        rules={[{ validator: validatePassword }]}
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div>
                                            <Input
                                                className="floating-input fs-14 fw-400"
                                                type={passwordVisible ? "text" : "password"}
                                                placeholder="Password"
                                                style={{ border: '1px solid #303136' }}
                                                suffix={
                                                    <span onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                                                        {passwordVisible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                                                    </span>
                                                }
                                            />
                                            <label className="floating-label fs-12">Password</label>
                                        </div>
                                    </Form.Item>

                                    <Form.Item
                                        name="confirmPassword"
                                        dependencies={['password']}
                                        rules={[validateConfirmPassword]}
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div>
                                            <Input
                                                className="floating-input fs-14 fw-400"
                                                type={passwordVisible ? "text" : "password"}
                                                placeholder="Password"
                                                style={{ border: '1px solid #303136' }}
                                                suffix={
                                                    <span onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                                                        {passwordVisible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                                                    </span>
                                                }
                                            />
                                            <label className="floating-label fs-12">Confirm Password</label>
                                        </div>
                                    </Form.Item>

                                    <Form.Item
                                        name="phone"
                                        rules={[{ required: true, message: 'Please input your phone number!' }]}
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div>
                                            <Input
                                                className="floating-input fs-14 fw-400"
                                                placeholder="Email or Phone"
                                                style={{ border: '1px solid #303136' }}
                                                type='number'
                                            />
                                            <label className="floating-label fs-12">Phone Number</label>
                                        </div>
                                    </Form.Item>

                                    <Form.Item
                                        // name="agreement"
                                        valuePropName="checked"
                                        style={{ textAlign: 'left', marginBottom: 0 }}
                                        rules={[{ required: true }]}
                                    >
                                        <Checkbox>
                                            <p className='fs-14 fw-400 text-black'>I have read and agreed to the Terms of Service and Privacy Policy.</p>
                                        </Checkbox>
                                    </Form.Item>

                                    <Button
                                        type="primary"
                                        size='large'
                                        htmlType="submit"
                                        className='booking-btn fs-16 fw-700'
                                        style={{
                                            borderRadius: '28px',
                                            padding: '24px',
                                            width: '100%',
                                        }}
                                    >
                                        Create Account
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Signup;
