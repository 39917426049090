import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import TimeSharing from '../../components/Packages/TimeSharing/TimeSharing';
import Corporate from '../../components/Packages/Corporate/Corporate';
import SchoolCollege from '../../components/Packages/SchoolCollege/SchoolCollege';
import Associates from '../../components/Packages/Associates/Associates';
import NewsAndUpdates from '../../components/homepage/NewsAndUpdates/NewsAndUpdates';
import './PackagesPage.css'

const PackagesPage = () => {
    const [selectedCategory, setSelectedCategory] = useState('All');

    const renderComponent = () => {
        switch (selectedCategory) {
            case 'All':
                return (
                    <>
                        <TimeSharing />
                        <Corporate />
                        <SchoolCollege />
                        <Associates />
                    </>
                )
            case 'Time sharing':
                return <TimeSharing />;
            case 'Corporate':
                return <Corporate />;
            case 'Schools/ Colleges':
                return <SchoolCollege />;
            case 'Associates':
                return <Associates />;
            default:
                return null;
        }
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'instant' });
      }, []);

    return (
        <>       
            <div>
                <video
                    className="banner-wrapper"
                    autoPlay
                    loop
                    muted
                    playsInline
                    controls={false}
                    disablePictureInPicture
                    controlsList="nodownload noplaybackrate nofullscreen"
                    style={{width: '100%', height: '428px', objectFit: 'cover', pointerEvents: "none"}}
                >
                    <source 
                        src={require('../../assets/videos/headervideo/header-video.mp4')} 
                        type="video/mp4"
                    />
                </video>
                
                <div className="gradient-overlay-packages">
                <Container fluid={false} className='py-5'>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xxl={6} xl={6} lg={8} md={10} sm={12} xs={12} className='text-center'>
                            <p className="fs-16 fw-400 text-black">
                                Choose your package
                            </p>
                            <p className='heading-text fw-800 italic'>
                                Packages
                            </p>
                            <p className='sub-heading-text fw-400 text-black'>
                                Our souvenir shops are fully equipped to help explorers prepare for all the exciting challenges in Water World!
                            </p>
                        </Col>
                    </Row>

                    <Row className='d-flex align-items-center justify-content-center pt-4'>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='text-center'>
                            {['All', 'Time sharing', 'Corporate', 'Schools/ Colleges', 'Associates'].map((category) => (
                                <Button
                                    key={category}
                                    className="fs-16 fw-700 m-1 booking-btn"
                                    size="large"
                                    style={
                                        selectedCategory === category
                                            ? { background: 'black', color: 'white', borderColor: 'black' }
                                            : { borderColor: 'black' }
                                    }
                                    onClick={() => setSelectedCategory(category)}
                                >
                                    {category}
                                </Button>
                            ))}
                        </Col>
                    </Row>
                </Container>
            </div>
            </div>
            <Container fluid={true}>
                <Row className="d-flex align-items-center justify-content-center pt-4">
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        {renderComponent()}
                    </Col>
                </Row>
            </Container>
        <NewsAndUpdates />
        </>
    )
}

export default PackagesPage
