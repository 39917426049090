import React from "react";
import "./ReachUs.css";
import { Button } from "antd";
import { Col, Container, Row } from "react-bootstrap";

const ReachUs = () => {
  const handleGetDirection = () => {
    window.open(
      "https://www.google.com/maps/place/JOY+N+JOYY+WATER+PARK/@14.2720495,74.4605375,17z/data=!3m1!4b1!4m5!3m4!1s0x3bbc3b4fbead1153:0x849a5b8d43639a05!8m2!3d14.2720495!4d74.4627262",
      "_blank"
    );
  };

  return (
    <Container fluid={false} style={{backgroundColor: '#F8F8F9'}}>
      <Row className="align-items-center justify-content-center my-5 px-4">
        <Col
          xxl={6}
          xl={6}
          lg={8}
          md={10}
          sm={10}
          xs={12}
          className="text-center"
        >
          <div>
            <p className="text-center fs-16 fw-400">Park Map and Tour</p>
            <p
              className={`${
                window?.innerWidth > 834.98
                  ? "fs-36"
                  : window?.innerWidth <= 425
                  ? "fs-24"
                  : "fs-36"
              } fw-800 italic`}
            >
              Reach us
            </p>
            <p
              className={`${
                window?.innerWidth > 834.98
                  ? "fs-18"
                  : window?.innerWidth <= 425
                  ? "fs-16"
                  : "fs-18"
              } mb-3 fw-400 text-black`}
            >
              Sign up and be the first to know the latest news, offers,
              promotions and special events of our Water World.
            </p>
          </div>
          <Row className="justify-content-center align-items-center">
            <Col
              xxl={3}
              xl={4}
              lg={5}
              md={6}
              sm={12}
              xs={12}
              className="text-end"
            >
              <Button
                type="primary"
                className="fs-16 fw-700 booking-btn my-3 reach-button"
                size="large"
                onClick={handleGetDirection}
              >
                Get direction
              </Button>
            </Col>
            <Col
              xxl={3}
              xl={4}
              lg={5}
              md={6}
              sm={12}
              xs={12}
              className="text-start"
            >
              <Button
                className="fs-16 fw-700 booking-btn reach-button"
                size="large"
                style={{borderColor: 'black'}}
              >
                Public transport
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ReachUs;
