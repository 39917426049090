import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Card, Image } from "antd";
import "./routecard.css";
import Clock1 from "../../../../assets/images/home/routingcard/park-tickets-icon.webp";
import Clock2 from "../../../../assets/images/home/routingcard/packages-icon.webp";
import Clock3 from "../../../../assets/images/home/routingcard/deals & offers-icon.webp";
import Clock4 from "../../../../assets/images/home/routingcard/park-tickets-icon-1.webp";
import Clock5 from "../../../../assets/images/home/routingcard/annual-passes-icon.webp";
import Clock6 from "../../../../assets/images/home/routingcard/merchandise-icon.webp";
import { useNavigate } from "react-router-dom";

const routes = [
  { name: "Park Tickets", icon: Clock1, path: "/parktimings" },
  { name: "Packages", icon: Clock2, path: "/offerpackage" },
  { name: "Deals & Offers", icon: Clock3, path: "/offerbooking" },
  { name: "Restaurants", icon: Clock4, path: "/dining" },
  { name: "Annual Passes", icon: Clock5, path: "/" },
  { name: "Merchandise", icon: Clock6, path: "/shopping" },
];

const RouteCard = () => {
  const navigate = useNavigate();

  return (
    <Container fluid={false}>
    <Row className={`${window?.innerWidth > 834.98 ? 'p-5' : window?.innerWidth <= 425 ? 'py-4' : 'p-5'}`}>
      {routes.map((route, index) => {
        const isWeb = index === 0 ? "24px 0 0 24px" : index === routes.length - 1 ? "0 24px 24px 0" : "0";
        const isTab = index === 0 ? "24px 0px 0 0" :index === 2 ? "0 24px 0 0" : index === 3 ? "0 0 0 24px" : index === 5 ? "0 0 24px 0" : "0";
        const isMobile = index === 0 ? "24px 0 0 0" : index === 1 ? "0 24px 0px 0" : index === 4 ? "0 0 0 24px" : index === 5 ? "0 0 24px 0" : "0";

        const borderRadius = window?.innerWidth > 834
            ? isWeb
            : window?.innerWidth > 425.98
            ? isTab
            : isMobile;

        return (
          <Col className="p-0" key={index} xs={6} sm={6} md={4} lg={2} xl={2} xxl={2}>
            <Card
              bordered={false}
              style={{
                textAlign: "center",
                backgroundColor: "#ffffff",
                borderRadius: borderRadius
              }}
              
              className="p-1"
              onClick={() => navigate(route.path)}
            >
              <div>
                <Image
                  src={route.icon}
                  alt={route.name}
                  preview={false}
                  style={{
                    // objectFit: "contain",
                    width: "4rem",
                    height: "4rem",
                  }}
                />
              </div>
              <Card.Meta
                className={`${ window?.innerWidth > 834.98 ? 'fs-20' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-18'} mb-0 fw-700 text-black py-1`}
                title={route.name}
              />
            </Card>
          </Col>
        );
      })}
    </Row>
    </Container>
  );
};

export default RouteCard;
