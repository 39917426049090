import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './DressCodePolicy.css';
import { Image } from 'antd';
import Wear1 from '../../../assets/images/dresscode/wear1.svg';
import Wear2 from '../../../assets/images/dresscode/wear2.svg';
import Wear3 from '../../../assets/images/dresscode/wear3.svg';
import Wear4 from '../../../assets/images/dresscode/wear4.svg';
import Wear5 from '../../../assets/images/dresscode/wear5.svg';
import NotWear1 from '../../../assets/images/dresscode/notwear1.svg';
import NotWear2 from '../../../assets/images/dresscode/notwear2.svg';
import NotWear3 from '../../../assets/images/dresscode/notwear3.svg';
import NotWear4 from '../../../assets/images/dresscode/notwear4.svg';
import NotWear5 from '../../../assets/images/dresscode/notwear5.svg';
import NotWear6 from '../../../assets/images/dresscode/notwear6.svg';
import NotWear7 from '../../../assets/images/dresscode/notwear7.svg';
import NotWear8 from '../../../assets/images/dresscode/notwear8.svg';
import NotWear9 from '../../../assets/images/dresscode/notwear9.svg';
import NotWear10 from '../../../assets/images/dresscode/notwear10.svg';


const DressCodePolicy = () => {
    const permittedItems = [
        { "name": "T-shirts", "image": Wear1 },
        { "name": "Shorts", "image": Wear2 },
        { "name": "Bermudas", "image": Wear3 },
        { "name": "Capris", "image": Wear4 },
        { "name": "Swimsuits", "image": Wear5 }
    ];

    const notpermittedItems = [
        { "name": "Sarees", "image": NotWear1 },
        { "name": "Salwar kameez", "image": NotWear2 },
        { "name": "Dupattas", "image": NotWear3 },
        { "name": "Formal trousers", "image": NotWear4 },
        { "name": "Dress shirts", "image": NotWear5 },
        { "name": "Burkhas", "image": NotWear6 },
        { "name": "Shawls", "image": NotWear7 },
        { "name": "Scarves", "image": NotWear8 },
        { "name": "School uniform ", "image": NotWear9 },
        { "name": "Jeans", "image": NotWear10 }
    ];

    return (
        <Container className='py-4'>
            <Row className="d-flex align-items-center justify-content-center">
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12} className='text-left'>
                            <div>
                                <h3 className={`${ window?.innerWidth > 834.98 ? 'fs-32' : window?.innerWidth <= 425 ? 'fs-22' : 'fs-28'} fw-700 text-black`}>
                                    For your safety and hygiene, following internationally recognized standards, 
                                    permitted clothing must be made of 100% nylon or other synthetic materials.
                                </h3>
                                <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    Our amusement park offers a variety of rides designed for enjoyment. Many of these involve movement or mechanical parts, 
                                    which necessitate specific dress guidelines to guarantee your health and safety. For example, loose clothing such as sarees, 
                                    salwar kameez, dupattas, churidars, shawls, or scarves can become entangled and pose risks to you and others. 
                                    To ensure everyone's safety, please avoid wearing loose garments on the land rides. 
                                    To maintain optimal water quality for all rides, we enforce a strict dress code.
                                </p>                               
                            </div>

                            <div className='text-center'>
                                <h3 className={`${ window?.innerWidth > 834.98 ? 'fs-32' : window?.innerWidth <= 425 ? 'fs-22' : 'fs-28'} fw-800 text-black italic py-3`}>
                                    What’s Permitted
                                </h3>
                                <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    For our water rides, suitable attire includes T-shirts, shorts, bermudas, capris, and swimsuits. 
                                    Acceptable clothing materials are 100% nylon or other synthetic fabrics.
                                </p>
                                <Row className="d-flex align-items-center justify-content-center py-3 m-1" style={{ backgroundColor: '#DBEBFE', borderRadius: '24px' }}>
                                    {permittedItems.map((item, index) => (
                                        <Col xxl={2} xl={2} lg={2} md={2} sm={6} xs={6} key={index}>
                                            <div className='text-center py-2'>
                                            <Image
                                                src={item.image}
                                                alt={item.name}
                                                preview={false}
                                                // style={{ width: '104px', height: '104px'}}
                                            />
                                            <p className='fs-14 fw-700 pt-1'>{item.name}</p>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>

                            <div className='text-center py-3'>
                                <h3 className={`${ window?.innerWidth > 834.98 ? 'fs-32' : window?.innerWidth <= 425 ? 'fs-22' : 'fs-28'} fw-800 text-black italic py-3`}>
                                    What’s not Permitted
                                </h3>
                                <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    To ensure your safety, please avoid wearing clothing that could put you at risk on our water rides. 
                                    For your well-being, we don’t allow the following types of attire: sarees, salwar kameez, dupattas, 
                                    formal trousers, dress shirts, burkhas, shawls, scarves, school uniforms, jeans, capris, cargo pants, 
                                    loose garments, or any other clothing not listed in the approved options.
                                </p>
                                <Row className="d-flex align-items-center justify-content-center py-5 m-1" style={{ backgroundColor: '#FFDDDA', borderRadius: '24px' }}>
                                    {notpermittedItems.map((item, index) => (
                                        <Col xxl={2} xl={2} lg={2} md={2} sm={6} xs={6} key={index} className='dress-code-icon'>
                                            <div className='text-center py-3'>
                                            <Image
                                                src={item.image}
                                                alt={item.name}
                                                preview={false}
                                                // style={{ width: '104px', height: '104px'}}
                                            />
                                            <p className='fs-14 fw-700 pt-1'>{item.name}</p>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default DressCodePolicy;
