import React, { useEffect } from "react";
import ShoppingSection from "../../components/Shopping/ShoppingSection/ShoppingSection";
import { Container } from "react-bootstrap";
import ShopCelibrate from "../../components/Shopping/ShopCelibrate/ShopCelibrate";
import NewsAndUpdates from "../../components/homepage/NewsAndUpdates/NewsAndUpdates";
import ShopCollection from "../../components/Shopping/ShopCollection.jsx/ShopCollection";

const ShoppingPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  return (
    <Container fluid={true} className="p-0">
      <ShoppingSection />
      <ShopCelibrate />
      <ShopCollection />
      <NewsAndUpdates />
    </Container>
  );
};

export default ShoppingPage;
