import React from "react";
import "./DiningOrder.css";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useBookingRestrictionStore } from "../../../context/BookingRestrictionContext";

const DiningOrder = () => {
  const navigate = useNavigate();
  const { showRestrictionModal } = useBookingRestrictionStore();

  return (   
    <div>
      <video
        className="banner-wrapper"
        autoPlay
        loop
        muted
        playsInline
        controls={false}
        disablePictureInPicture
        controlsList="nodownload noplaybackrate nofullscreen"
        style={{width: '100%', height: '470px', objectFit: 'cover', pointerEvents: "none"}}
      >
        <source 
          src={require('../../../assets/videos/headervideo/header-video.mp4')} 
          type="video/mp4"
        />
      </video>
      
      <div className="gradient-overlay">
        <Container className="py-5" >
          <Row className="d-flex align-items-center justify-content-center">
            <Col xxl={6} xl={6} lg={8} md={10} sm={10} xs={10} className="text-center">
              <p className="fs-16 fw-400 text-black">
                A Feast for Every Tastebud Awaits!
              </p>
              <p className='heading-text fw-800 italic'>
                Savor the Flavor: Dining Delights
              </p>
              <p className='sub-heading-text fw-400 text-black'>
                From hearty vegetarian and non-vegetarian meals to quick bites and refreshing beverages, 
                JoynJoyy Waterpark’s dining options have something for everyone. Dive into deliciousness while you soak up the fun! 
              </p>
              </Col>
            </Row>

            <Row className='d-flex align-items-center justify-content-center'>
              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='text-center'>
                <Button
                  className="fs-16 fw-700 booking-btn"
                  size="large"
                  style={{borderColor: '#272727'}}
                  onClick={() => {
                    // navigate('/booking');
                    showRestrictionModal(true)
                  }}
                >
                  Book tickets
                </Button>
              </Col>
            </Row>              
        </Container>
      </div>
    </div>
  );
};

export default DiningOrder;
