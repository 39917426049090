import React, { useContext, createContext, useState } from 'react';

export const BookingRestrictionContext = createContext(undefined);

export const useBookingRestrictionStore = () => {
    const context = useContext(BookingRestrictionContext);
    if (!context) {
        throw new Error(
            'useBookingRestrictionStore must be used within a BookingRestrictionContextProvider'
        )
    }
    return context;
};

const BookingRestrictionContextProvider = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showRestrictionModal = (value) => {
        setIsModalOpen(value)
    };

    return (
        <BookingRestrictionContext.Provider
            value={{
                isModalOpen,
                showRestrictionModal
            }}
        >
            {children}
        </BookingRestrictionContext.Provider>
    );
};
export default BookingRestrictionContextProvider;