import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './WalletPage.css';

const WalletPage = () => {
  const balanceData = {
    totalBalance: 150,
    yourCash: 100,
    offerCash: 50
  };

  const transactions = [
    {
      date: '12/08/2024',
      ticketId: '#DEA234FRL',
      transaction: 'Corporate group (6) Offer Cash',
      amount: '+$500',
    },
    {
      date: '12/08/2024',
      ticketId: '#DEA234FRL',
      transaction: 'Offer Cash expiry date - 01/01/25',
      amount: '+$500',
    },
    {
      date: '12/08/2024',
      ticketId: '#DEA234FRL',
      transaction: 'Corporate group (6) Offer Cash',
      amount: '+$500',
    },
  ];

  return (
    <Container fluid={false} className='wallet-container'> 
      <Row className="justify-content-center mb-4">
        <Col xxl={10} xl={10} lg={12} md={12} sm={12} xs={12}>         
          <h3 className="fs-32 fw-700">Balance</h3>
          <Row className="justify-content-center my-4">
            <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} className='py-2'>
              <div className="py-2" style={{ backgroundColor: '#F1F1F3', borderRadius: '16px', textAlign: 'center' }}>
                <p className={`${window?.innerWidth > 834.98 ? 'fs-36' : window?.innerWidth <= 425 ? 'fs-24' : 'fs-32'} fw-700 `}>{balanceData.totalBalance}</p>
                <p className={`${window?.innerWidth > 834.98 ? 'fs-20' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-20'} fw-700 `}>Total Balance</p>
              </div>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} className='py-2'>
              <div className="py-2" style={{ backgroundColor: '#F1F1F3', borderRadius: '16px', textAlign: 'center'}}>
                <p className={`${window?.innerWidth > 834.98 ? 'fs-36' : window?.innerWidth <= 425 ? 'fs-24' : 'fs-32'} fw-700 `}>{balanceData.yourCash}</p>
                <p className={`${window?.innerWidth > 834.98 ? 'fs-20' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-20'} fw-700 `}>Your Cash</p>
              </div>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} className='py-2'>
              <div className="py-2" style={{ backgroundColor: '#F1F1F3', borderRadius: '16px', textAlign: 'center'}}>
                <p className={`${window?.innerWidth > 834.98 ? 'fs-36' : window?.innerWidth <= 425 ? 'fs-24' : 'fs-32'} fw-700 `}>{balanceData.offerCash}</p>
                <p className={`${window?.innerWidth > 834.98 ? 'fs-20' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-20'} fw-700 `}>Offer Cash</p>
              </div>
            </Col>
          </Row>

          <Row className="mt-3 px-3">
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>               
              {transactions.map((transaction, index) => (
                <Row className="mb-3 p-3" key={index} style={{ backgroundColor: '#F1F1F3', borderRadius: '16px' }}>
                  <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6}>
                    <h5 className="fs-14 fw-600 acc-head">DATE:</h5>
                    <p className="fs-14 fw-700">{transaction.date}</p>
                  </Col>
                  <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6}>
                    <h5 className="fs-14 fw-600 acc-head">TICKET ID:</h5> 
                    <p className="fs-14 fw-700">{transaction.ticketId}</p>
                  </Col>
                  <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6}>
                    <h5 className="fs-14 fw-600 acc-head">TRANSACTION:</h5> 
                    <p className="fs-14 fw-700">{transaction.transaction}</p>
                  </Col>
                  <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6}>
                    <h5 className="fs-14 fw-600 acc-head">AMOUNT:</h5> 
                    <p className="fs-14 fw-700">{transaction.amount}</p>
                  </Col>
                </Row>
              ))}              
            </Col>
          </Row>   
        </Col>
      </Row>
    </Container>
  );
};

export default WalletPage;
