import React, { useEffect, useState } from "react";
import "./ParkTime.css"
import { Col, Container, Row } from "react-bootstrap";
import { Table, Button } from 'antd';

const parkTimingsData = {
  seasons: [
    {
      season: 'Summer',
      duration: '(April - June)',
      timings: {
        weekdays: '10:30 AM - 5:30 PM',
        weekends_holidays: '10:00 AM - 6:00 PM',
      },
    },
    {
      season: 'Monsoon',
      duration: '(July - September)',
      timings: {
        weekdays: '10:30 AM - 5:00 PM',
        weekends_holidays: '10:30 AM - 5:30 PM',
      },
    },
    {
      season: 'Winter',
      duration: '(October - March)',
      timings: {
        weekdays: '11:00 AM - 5:30 PM',
        weekends_holidays: '10:30 AM - 5:30 PM',
      },
    },
  ],
};

const ticketPricesData = {
  seasons: [
    {
      level: 'Adults',
      prices: {
        weekdays: 'INR. 899',
        weekends_holidays: 'INR. 899',
      },
    },
    {
      level: 'For Children',
      prices: {
        weekdays: 'INR. 699',
        weekends_holidays: 'INR. 699',
      },
    },
    {
      level: 'Senior Citizen',
      prices: {
        weekdays: 'INR. 699',
        weekends_holidays: 'INR. 699',
      },
    },
  ],
};

const ParkTime = () => {

  const handleGetDirection = () => {
    window.open(
      "https://www.google.com/maps/place/JOY+N+JOYY+WATER+PARK/@14.2720495,74.4605375,17z/data=!3m1!4b1!4m5!3m4!1s0x3bbc3b4fbead1153:0x849a5b8d43639a05!8m2!3d14.2720495!4d74.4627262",
      "_blank"
    );
  };

  // const isMobile = window.innerWidth <= 425;
  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 530);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 530);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = [
    {
      title: 
        <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'} fw-700 text-white`}>
          Timings
        </h4>,
      dataIndex: 'days',
      key: 'days',
      width: 180,
      render: (text) => 
        <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-20' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-20'} fw-700 text-black`}>
          {text}
        </h6>,
    },
    ...parkTimingsData.seasons.map((season) => ({
      title: (
        <div>
          <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'} fw-700 text-white`}>
            {season.season}
          </h4>
          <p className={`${ window?.innerWidth > 834.98 ? 'fs-20' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-20'} fw-400 text-white`}>
            {season.duration}
          </p>
        </div>
      ),
      dataIndex: season.season.toLowerCase(),
      key: season.season.toLowerCase(),
      width: window?.innerWidth <= 834 ? '90px' : '160px',
      render: (text) => 
        <h6 
          className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-600 text-black`}
          style={{ width: window?.innerWidth <= 834 ? '90px' : '190px' }}
        >
          {text}
        </h6>,
    })),
  ];

  const data = [
    {
      key: '1',
      days: 'Weekdays',
      summer: parkTimingsData.seasons[0].timings.weekdays,
      monsoon: parkTimingsData.seasons[1].timings.weekdays,
      winter: parkTimingsData.seasons[2].timings.weekdays,
    },
    {
      key: '2',
      days: 'Weekends & holidays',
      summer: parkTimingsData.seasons[0].timings.weekends_holidays,
      monsoon: parkTimingsData.seasons[1].timings.weekends_holidays,
      winter: parkTimingsData.seasons[2].timings.weekends_holidays,
    },
  ];

  // const ticketColumns = [
  //   {
  //     title: 
  //       <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'} fw-700 text-white`}>
  //         Ticket Prices
  //       </h4>,
  //     dataIndex: 'days',
  //     key: 'days',
  //     width: 140,
  //     render: (text) =>
  //       <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-20' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-20'} fw-700 text-black`}>
  //         {text}
  //       </h6>,
  //   },
  //   ...ticketPricesData.seasons.map((season) => ({
  //     title: (
  //       <div>
  //         <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'} fw-700 text-white`}>
  //           {season.level}
  //         </h4>
  //         {/* <p className={`${ window?.innerWidth > 834.98 ? 'fs-20' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-20'} fw-400 text-white`}>
  //           {season.duration}
  //         </p> */}
  //       </div>
  //     ),
  //     dataIndex: season.level.toLowerCase(),
  //     key: season.level.toLowerCase(),
  //     width: window?.innerWidth <= 834 ? '90px' : '160px',
  //     render: (text) => <h6 
  //       className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-600 text-black`} 
  //       style={{ width: window?.innerWidth <= 834 ? '90px' : '160px' }}
  //     >
  //       {text}
  //     </h6>,
  //   })),
  // ];

  const ticketColumns = [
    {
      title: (
        <h4
          className={`${
            window?.innerWidth > 834.98
              ? 'fs-24'
              : window?.innerWidth <= 425
              ? 'fs-18'
              : 'fs-22'
          } fw-700 text-white`}
        >
          Ticket Prices
        </h4>
      ),
      dataIndex: 'days',
      key: 'days',
      width: 180,
      render: (text) => (
        <h6
          className={`${
            window?.innerWidth > 834.98
              ? 'fs-20'
              : window?.innerWidth <= 425
              ? 'fs-18'
              : 'fs-20'
          } fw-700 text-black`}
        >
          {text}
        </h6>
      ),
    },
    {
      title: (
        <div>
          <h4
            className={`${
              window?.innerWidth > 834.98
                ? 'fs-24'
                : window?.innerWidth <= 425
                ? 'fs-18'
                : 'fs-22'
            } fw-700 text-white`}
          >
            Adults
          </h4>
        </div>
      ),
      dataIndex: 'adults',
      key: 'adults',
      width: window?.innerWidth <= 834 ? '90px' : '160px',
      render: (text) => (
        <h6
          className={`${
            window?.innerWidth > 834.98
              ? 'fs-18'
              : window?.innerWidth <= 425
              ? 'fs-16'
              : 'fs-18'
          } fw-600 text-black`}
          style={{ width: window?.innerWidth <= 834 ? '90px' : '190px' }}
        >
          {text}
        </h6>
      ),
    },
    {
      title: (
        <div>
          <h4
            className={`${
              window?.innerWidth > 834.98
                ? 'fs-24'
                : window?.innerWidth <= 425
                ? 'fs-18'
                : 'fs-22'
            } fw-700 text-white`}
          >
            For Children
          </h4>
        </div>
      ),
      dataIndex: 'for_children',
      key: 'for_children',
      width: window?.innerWidth <= 834 ? '90px' : '160px',
      render: (text) => (
        <h6
          className={`${
            window?.innerWidth > 834.98
              ? 'fs-18'
              : window?.innerWidth <= 425
              ? 'fs-16'
              : 'fs-18'
          } fw-600 text-black`}
          style={{ width: window?.innerWidth <= 834 ? '90px' : '190px' }}
        >
          {text}
        </h6>
      ),
    },
    {
      title: (
        <div>
          <h4
            className={`${
              window?.innerWidth > 834.98
                ? 'fs-24'
                : window?.innerWidth <= 425
                ? 'fs-18'
                : 'fs-22'
            } fw-700 text-white`}
          >
            Senior Citizen
          </h4>
        </div>
      ),
      dataIndex: 'senior_citizen',
      key: 'senior_citizen',
      width: window?.innerWidth <= 834 ? '90px' : '160px',
      render: (text) => (
        <h6
          className={`${
            window?.innerWidth > 834.98
              ? 'fs-18'
              : window?.innerWidth <= 425
              ? 'fs-16'
              : 'fs-18'
          } fw-600 text-black`}
          style={{ width: window?.innerWidth <= 834 ? '90px' : '190px' }}
        >
          {text}
        </h6>
      ),
    },
  ];
  
  const ticketData = [
    {
      key: '1',
      days: 'Weekdays',
      adults: ticketPricesData.seasons[0].prices.weekdays,
      for_children: ticketPricesData.seasons[1].prices.weekdays,
      senior_citizen: ticketPricesData.seasons[2].prices.weekdays,
    },
    {
      key: '2',
      days: 'Weekends & holidays',
      adults: ticketPricesData.seasons[0].prices.weekends_holidays,
      for_children: ticketPricesData.seasons[1].prices.weekends_holidays,
      senior_citizen: ticketPricesData.seasons[2].prices.weekends_holidays,
    },
  ];

  const timingMobileTables = () => (
    <div className="mb-5">
      {parkTimingsData.seasons.map((season, index) => (
        <div key={index} className="mobile-table-section">
          <Table
            className="park-time-mobile-table my-4"            
            columns={[
              {
                title: 
                  <h4 className='fs-18 fw-700 text-white'>
                    Timings
                  </h4>,
                dataIndex: 'days',
                key: 'days',
                width: 140,
                render: (text) => 
                  <h6 className='fs-18 fw-700 text-black'>
                    {text}
                  </h6>,
              },
              {
                title: (
                  <>
                    <h4 className='fs-18 fw-700 text-white'>
                      {season.season}
                    </h4>
                    <p className='fs-18 fw-400 text-white'>
                      {season.duration}
                    </p>
                  </>
                ),
                dataIndex: 'timings',
                key: 'timings',
                render: (text) => 
                <h6 
                  className='fs-16 fw-600 text-black' 
                >
                  {text}
                </h6>,
              },
            ]}
            dataSource={[
              {
                key: '1',
                days: 'Weekdays',
                timings: season.timings.weekdays,
              },
              {
                key: '2',
                days: 'Weekends & holidays',
                timings: season.timings.weekends_holidays,
              },
            ]}
            pagination={false}
            bordered
          />
        </div>
      ))}
    </div>
  );

  const ticketMobileTables = () => (
    <div className="mb-5">
      {ticketPricesData.seasons.map((season, index) => (
        <div key={index} className="mobile-table-section my-4">
          <Table
            className="park-time-mobile-table my-4"
            columns={[
              {
                title: 
                  <h4 className='fs-18 fw-700 text-white'>
                    Ticket Prices
                  </h4>,
                dataIndex: 'days',
                key: 'days',
                width: 140,
                render: (text) => 
                  <h6 className='fs-18 fw-700 text-black'>
                    {text}
                  </h6>,
              },
              {
                title: (
                  <>
                    <h4 className='fs-18 fw-700 text-white'>
                      {season.level}
                    </h4>
                    {/* <p className='fs-18 fw-400 text-white'>
                      {season.duration}
                    </p> */}
                  </>
                ),
                dataIndex: 'timings',
                key: 'timings',
                render: (text) => 
                  <h6 
                    className='fs-16 fw-600 text-black'
                  >
                    {text}
                  </h6>,
              },
            ]}
            dataSource={[
              {
                key: '1',
                days: 'Weekdays',
                timings: season.prices.weekdays,
              },
              {
                key: '2',
                days: 'Weekends & holidays',
                timings: season.prices.weekends_holidays,
              },
            ]}
            pagination={false}
            bordered
          />
        </div>
      ))}
    </div>
  );

  return (
    <Container 
      fluid={true}
      className="py-4 my-5"
      style={{backgroundColor: '#F8F8F9'}}
    >
      <Row className="d-flex justify-content-center align-items-center">
        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Row className="d-flex align-items-center justify-content-center py-5">
            <Col
              xxl={6}
              xl={6}
              lg={8}
              md={10}
              sm={10}
              xs={12}
              className="text-center"
            >
              <div>
                <p className="text-center fs-16 fw-400">Park Map and Tour</p>
                <p
                  className={`${
                    window?.innerWidth > 834.98
                      ? "fs-36"
                      : window?.innerWidth <= 425
                      ? "fs-24"
                      : "fs-36"
                  } fw-800 italic`}
                >
                  Reach us
                </p>
                <p
                  className={`${
                    window?.innerWidth > 834.98
                      ? "fs-18"
                      : window?.innerWidth <= 425
                      ? "fs-16"
                      : "fs-18"
                  } mb-3 fw-400 text-black`}
                >
                  Sign up and be the first to know the latest news, offers,
                  promotions and special events of our Water World.
                </p>
              </div>
              <Row className="d-flex justify-content-center align-items-center">
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Button
                    type="primary"
                    className="fs-16 fw-700 booking-btn m-1 button"
                    size="large"
                    onClick={handleGetDirection}
                  >
                    Get direction
                  </Button>
                
                  <Button
                  type="default"
                    className="fs-16 fw-700 booking-btn m-1 button"
                    size="large"
                    style={{borderColor: 'black'}}
                  >
                    Public transport
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
      
          <Row className="d-flex justify-content-center align-items-center">
            <Col xxl={10} xl={10} lg={10} md={10} sm={12} xs={12} className="mb-4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3526.679292200299!2d74.46144364068041!3d14.272115114525075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbc3b4fbead1153%3A0x849a5b8d43639a05!2sJOY%20N%20JOYY%20WATER%20PARK!5e1!3m2!1sen!2sin!4v1731482657425!5m2!1sen!2sin"
                width="100%"
                className="map-location"
                style={{ borderRadius: '24px' }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Maps Embed"
              ></iframe>
            </Col>
          </Row>
          <Row className='d-flex justify-content-center align-items-center'>
            <Col xxl={8} xl={8} lg={10} md={10} sm={12} xs={12} className="text-start">
              <div>
                <p
                  className={`${
                    window?.innerWidth > 834.98
                      ? "fs-32"
                      : window?.innerWidth <= 425
                      ? "fs-22"
                      : "fs-28"
                  } fw-800`}
                >
                  Park Timing
                </p>
                <p
                  className={`${
                    window?.innerWidth > 834.98
                      ? "fs-18"
                      : window?.innerWidth <= 425
                      ? "fs-16"
                      : "fs-18"
                  } fw-400`}
                >
                  Discover ultimate comfort and exclusivity with our brand-new VIP Cabana Package! 
                  Enjoy a uniquely curated experience designed to maximize relaxation and indulgence, ensuring your visit to Joy n Joyy is truly unforgettable. 
                  Plan your day with ease and make every moment count!
                </p>
              </div>

              {isMobile ? (
                timingMobileTables()
              ) : (
                <Table
                  className="park-time-table mb-5"
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  bordered
                  rowClassName={(record, index) => {
                    if (index === 0) return 'custom-first-row';
                    if (index === 1) return 'custom-second-row';
                    return '';
                  }}
                />
              )}

              {isMobile ? (
                ticketMobileTables()
              ) : (
                <Table 
                  className="park-time-table mb-5"
                  columns={ticketColumns} 
                  dataSource={ticketData} 
                  pagination={false} 
                  bordered
                  rowClassName={(record, index) => {
                    if (index === 0) return 'custom-first-row';
                    if (index === 1) return 'custom-second-row';
                    return '';
                  }}
                />
              )}
            </Col>
          </Row>        
        </Col>
      </Row>
    </Container>
  );
};

export default ParkTime;