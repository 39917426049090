function Water({ size, color }) {
    return (
        <svg 
            width={size}
            height={size} 
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M8.25 5.25H15.75" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.25 8.25H15.75" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.25 11.25H15.75" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.25 3V13.2553" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.75 3V13.4334" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3 15.75C5.25 15.75 5.25 17.25 7.5 17.25C9.75 17.25 9.75 15.75 12 15.75C14.25 15.75 14.25 17.25 16.5 17.25C18.75 17.25 18.75 15.75 21 15.75" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3 19.5C5.25 19.5 5.25 21 7.5 21C9.75 21 9.75 19.5 12 19.5C14.25 19.5 14.25 21 16.5 21C18.75 21 18.75 19.5 21 19.5" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    );
}

export default Water;
