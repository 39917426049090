import { Button, Card, Form, Image, Input, Radio } from 'antd'
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import AboutImage from '../../assets/images/contact-us/img-Aerial-view.webp';
import FormItem from 'antd/es/form/FormItem'

const ContactUs = () => {
    const groupOptions = [
        { id: 1, label: "Company" },
        { id: 2, label: "School/College" },
        { id: 3, label: "Any Group" },
        { id: 4, label: "Agency" }
    ];

    const [groupDetails, setGroupDetails] = useState(null);

    const handleCardClick = (value) => {
        setGroupDetails(value);
    };

    const validateEmailOrPhone = (_, value) => {
        const phoneRegex = /^[0-9]{10}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!value || emailRegex.test(value) || phoneRegex.test(value)) {
            return Promise.resolve();
        }

        return Promise.reject(new Error('Please enter a valid email or phone number.'));
    };

    return (
        <Container className="py-4">
            <Row className="d-flex align-items-center justify-content-center">
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xxl={10} xl={10} lg={10} md={12} sm={12} xs={12} >
                            <Image
                                src={AboutImage}
                                alt={"About"}
                                preview={false}
                                style={{borderRadius: '16px'}}
                            />
                        </Col>

                        <Row className="d-flex justify-content-center py-5">
                            <Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12} >
                                <Row className='mb-4'>
                                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}
                                        style={{
                                            backgroundColor: '#FFF6D1',
                                            borderRadius: '16px',
                                            color: '#BD5B02',
                                        }}
                                    >
                                        <div
                                            className='p-4'
                                        >
                                            <h3 className={`${window?.innerWidth > 834.98 ? 'fs-32' : window?.innerWidth <= 425 ? 'fs-22' : 'fs-28'} fw-800 `}>
                                                Joy n Joyy – Water park
                                            </h3>
                                            <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                                                Door No 75, ABCD, Angadihitlu,<br />
                                                PO: Mavinakurva, Honavar - 581335 N.K.<br />
                                                Phone: +91-9739777566 <br />
                                                <span className='fw-700'>Email:</span> joynjoyy2024@gmail.com<br />
                                                <span>A venture of ATHARVA ADVENTURE AND RESORTS PVT. LTD ,</span>
                                            </p>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='mb-4'>
                                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}
                                        style={{
                                            backgroundColor: '#DBEBFE',
                                            borderRadius: '16px',
                                            color: '#0256B8'
                                        }}
                                    >
                                        <div className='p-4'>
                                            <h3 className={`${window?.innerWidth > 834.98 ? 'fs-32' : window?.innerWidth <= 425 ? 'fs-22' : 'fs-28'} fw-800 `}>
                                                Packages
                                            </h3>
                                            <h5 className={`${window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'} fw-700 `}>
                                                Corporate
                                            </h5>
                                            <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                                                Phone: +91-9739777566<br />
                                                <span className='fw-700'>Email:</span> joynjoyy2024@gmail.com
                                            </p>

                                            <h5 className={`${window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'} fw-700 `}>
                                                School and Educational Institutions
                                            </h5>
                                            <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                                                Phone: +91-9739777566 <br />
                                                <span className='fw-700'>Email:</span> joynjoyy2024@gmail.com
                                            </p>

                                            <h5 className={`${window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'} fw-700 `}>
                                                Time Sharing Deals
                                            </h5>
                                            <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                                                Phone: +91-9739777566 <br />
                                                <span className='fw-700'>Email:</span> joynjoyy2024@gmail.com
                                            </p>
                                            <h5 className={`${window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'} fw-700 `}>
                                                Associates
                                            </h5>
                                            <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                                                Phone: +91-9739777566 <br />
                                                <span className='fw-700'>Email:</span> joynjoyy2024@gmail.com
                                            </p>
                                            <h5 className={`${window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'} fw-700 `}>
                                                Group Booking
                                            </h5>
                                            <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                                                Phone: +91-9739777566 <br />
                                                <span className='fw-700'>Email:</span> joynjoyy2024@gmail.com
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12} >
                                <Card className='p-3' style={{ borderRadius: '16px', borderColor: '#A0A1AB' }}>
                                    <h3 className={`${window?.innerWidth > 834.98 ? 'fs-32' : window?.innerWidth <= 425 ? 'fs-22' : 'fs-28'} fw-800 text-black italic`}>
                                        Bulk Booking
                                    </h3>

                                    <Row className="my-4">
                                        {groupOptions.map(option => (
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} key={option.id} className="mb-3">
                                                <Card
                                                    style={{
                                                        borderRadius: '16px',
                                                        textAlign: 'left',
                                                        cursor: 'pointer',
                                                        border: groupDetails === option.label ? '2px solid #484951' : '1px solid #A0A1AB',
                                                    }}
                                                    onClick={() => handleCardClick(option.label)}
                                                    className={groupDetails === option.label ? 'selected-card' : ''}
                                                >
                                                    <Radio
                                                        value={option.label}
                                                        checked={groupDetails === option.label}
                                                        onChange={() => handleCardClick(option.label)}
                                                    />
                                                    <div className={`${window?.innerWidth > 834.98 ? 'fs-16' : window?.innerWidth <= 425 ? 'fs-12' : 'fs-16'} fw-700 text-black pt-4`}>
                                                        {option.label}
                                                    </div>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>

                                    <Form
                                        name="signup"
                                        layout="vertical"
                                    >
                                        <Row>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <FormItem
                                                    style={{ marginBottom: '2rem' }}
                                                >
                                                    <div>
                                                        <Input
                                                            className="floating-input fs-14 fw-400"
                                                            placeholder="Company Name"
                                                            style={{ border: '1px solid #303136' }}
                                                        />
                                                        <label className="floating-label fs-12">Company Name</label>
                                                    </div>
                                                </FormItem>
                                            </Col>

                                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <FormItem
                                                    style={{ marginBottom: '2rem' }}
                                                >
                                                    <div>
                                                        <Input
                                                            className="floating-input fs-14 fw-400"
                                                            placeholder="Contact Person"
                                                            style={{ border: '1px solid #303136' }}
                                                        />
                                                        <label className="floating-label fs-12">Contact Person</label>
                                                    </div>
                                                </FormItem>

                                            </Col>

                                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <Form.Item
                                                    name="emailOrPhone"
                                                    rules={[{ validator: validateEmailOrPhone }]}
                                                    style={{ marginBottom: '2rem' }}
                                                >
                                                    <div>
                                                        <Input
                                                            className="floating-input fs-14 fw-400"
                                                            placeholder="Email or Phone"
                                                            style={{ border: '1px solid #303136' }}
                                                        />
                                                        <label className="floating-label fs-12">Email or Phone</label>
                                                    </div>
                                                </Form.Item>
                                            </Col>

                                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <Form.Item
                                                    name="phoneNumber"
                                                    rules={[
                                                        {
                                                            // required: true,
                                                            message: 'Please enter a valid phone number',
                                                            pattern: /^[0-9]{10}$/,
                                                        },
                                                    ]}
                                                    style={{ marginBottom: '2rem' }}
                                                >
                                                    <div>
                                                        <Input
                                                            className="floating-input fs-14 fw-400"
                                                            placeholder="Phone Number"
                                                            style={{ border: '1px solid #303136' }}
                                                        />
                                                        <label className="floating-label fs-12">Phone Number</label>
                                                    </div>
                                                </Form.Item>
                                            </Col>

                                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <FormItem
                                                    style={{ marginBottom: '2rem' }}
                                                >
                                                    <div>
                                                        <Input
                                                            className="floating-input fs-14 fw-400"
                                                            placeholder="Location"
                                                            style={{ border: '1px solid #303136' }}
                                                        />
                                                        <label className="floating-label fs-12">Location</label>
                                                    </div>
                                                </FormItem>
                                            </Col>

                                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <FormItem
                                                    style={{ marginBottom: '2rem' }}
                                                >
                                                    <div>
                                                        <Input
                                                            className="floating-input fs-14 fw-400"
                                                            placeholder="Pincode"
                                                            style={{ border: '1px solid #303136' }}
                                                        />
                                                        <label className="floating-label fs-12">Pincode</label>
                                                    </div>
                                                </FormItem>
                                            </Col>
                                        </Row>

                                        <Row className='d-flex align-items-center justify-content-center'>
                                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Button
                                                    type="primary"
                                                    size='large'
                                                    htmlType="submit"
                                                    className='booking-btn fs-16 fw-700'
                                                    style={{
                                                        borderRadius: '28px',
                                                        padding: '24px',
                                                        width: '100%',
                                                    }}
                                                >
                                                    Send Request
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                    </Row>
                </Col >
            </Row >
        </Container >
    )
}

export default ContactUs
