import { Button, Image } from 'antd';
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import PackageImage1 from '../../../assets/images/packages/associatepackage1.png'
import PackageImage2 from '../../../assets/images/packages/associatepackage2.png'
import PackageImage3 from '../../../assets/images/packages/associatepackage3.png'
import './Associates.css';
import { CheckOutlined } from '@ant-design/icons';
import { useBookingRestrictionStore } from '../../../context/BookingRestrictionContext';

const packageData = [
    {
        id: 1,
        title: 'AquaAlliance Partner',
        image: PackageImage1,
        group: 'A collaboration plan designed for promoters/managers in the service industry to offer exclusive water park experiences to their guests.',
        // validity: 'This flexible plan is valid for a period of 4 months from the date of confirmation*.',
        benefits: [
            <>
                {/* <span style={{ fontWeight: 700 }}>Fast Track Entry:</span> */}
                <span style={{ fontWeight: 400 }}> Bulk purchase discounts for ticket packages.</span>
            </>,
            <>
                {/* <span style={{ fontWeight: 700 }}>Special Discounts:</span> */}
                <span style={{ fontWeight: 400 }}> Co-branded marketing opportunities to highlight both resort and water park.</span>
            </>,
            <>
                {/* <span style={{ fontWeight: 700 }}>Entry Wrist Band</span> */}
                <span style={{ fontWeight: 400 }}> Priority booking for group visits.</span>
            </>,
            <>
                {/* <span style={{ fontWeight: 700 }}>Value for Money:</span> */}
                <span style={{ fontWeight: 400 }}> Exclusive access to VIP cabanas and premium areas for resort guests.</span>
            </>
        ],
        buttonStyle: { borderColor: 'black' },
    },
    {
        id: 2,
        title: 'Splash Connect',
        image: PackageImage2,
        group: 'A rewards-based program for the service industry to promote and enhance the experience for their clients.',
        // validity: 'This flexible plan is valid for a period of 4 months from the date of confirmation*.',
        benefits: [
            <>
                {/* <span style={{ fontWeight: 700 }}>Fast Track Entry:</span> */}
                <span style={{ fontWeight: 400 }}> Bulk purchase discounts for ticket packages.</span>
            </>,
            <>
                {/* <span style={{ fontWeight: 700 }}>Special Discounts:</span> */}
                <span style={{ fontWeight: 400 }}> Co-branded marketing opportunities to highlight both resort and water park.</span>
            </>,
            <>
                {/* <span style={{ fontWeight: 700 }}>Entry Wrist Band</span> */}
                <span style={{ fontWeight: 400 }}> Priority booking for group visits.</span>
            </>,
            <>
                {/* <span style={{ fontWeight: 700 }}>Value for Money:</span> */}
                <span style={{ fontWeight: 400 }}> Exclusive access to VIP cabanas and premium areas for resort guests.</span>
            </>
        ],
        buttonStyle: { backgroundColor: '#0056E0', color: 'white', borderColor: '#0056E0' },
    },
    {
        id: 3,
        title: 'Wave Builder',
        image: PackageImage3,
        group: 'A comprehensive plan for tour operators looking to incorporate Joy n Joyy Water Park into their travel itineraries or destination packages.',
        // validity: 'This flexible plan is valid for a period of 4 months from the date of confirmation*.',
        benefits: [
            <>
                {/* <span style={{ fontWeight: 700 }}>Fast Track Entry:</span> */}
                <span style={{ fontWeight: 400 }}> Bulk purchase discounts for ticket packages.</span>
            </>,
            <>
                {/* <span style={{ fontWeight: 700 }}>Special Discounts:</span> */}
                <span style={{ fontWeight: 400 }}> Co-branded marketing opportunities to highlight both resort and water park.</span>
            </>,
            <>
                {/* <span style={{ fontWeight: 700 }}>Entry Wrist Band</span> */}
                <span style={{ fontWeight: 400 }}> Priority booking for group visits.</span>
            </>,
            <>
                {/* <span style={{ fontWeight: 700 }}>Value for Money:</span> */}
                <span style={{ fontWeight: 400 }}> Exclusive access to VIP cabanas and premium areas for resort guests.</span>
            </>
        ],
        buttonStyle: { borderColor: 'black' },
    },
];

const Associates = () => {
    const [activePackage, setactivePackage] = useState(null);
    const { showRestrictionModal } = useBookingRestrictionStore();

    return (
        <Container className="py-5">
            <Row className="d-flex align-items-center justify-content-center">
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='text-center'>
                    <p className="fs-16 fw-400 text-black mb-0 pt-3">Park Map and Tour</p>
                    <p className={`${window?.innerWidth > 834.98 ? 'fs-32' : window?.innerWidth <= 425 ? 'fs-22' : 'fs-28'} fw-800 mb-0 italic`}>Associates Packages</p>

                    <Row className='py-5'>
                        {packageData.map((pkg, index) => (
                            <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div
                                    className='text-start p-3 mb-4'
                                    style={{
                                        background: activePackage === pkg.id
                                            ? 'linear-gradient(180deg, #DBEBFE 41.24%, #FFFFFF 100%)'
                                            : 'linear-gradient(180deg, #F8F8F9 47.51%, #FFFFFF 100%)',
                                        borderRadius: '16px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => setactivePackage(pkg.id)}
                                >
                                    <p
                                        className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 mb-3`}
                                        style={{
                                            display: 'inline-block',
                                            borderRadius: '52px',
                                            padding: '5px 15px', 
                                            background: activePackage === pkg.id ? '#2263E3' : '#FFFFFF',
                                            color: activePackage === pkg.id ? '#ffffff' : '#0256B8',
                                        }}
                                    >{pkg.title}</p>
                                    <div className="custom-package-image">
                                        <Image 
                                            src={pkg.image}
                                            alt={pkg.title}
                                            preview={false}
                                            className="package-img"
                                        />
                                    </div>
                                    <p className="fw-400 fs-14 mb-2 pt-4">{pkg.group}</p>
                                    {/* <p className="fw-400 fs-14 mb-2">{pkg.validity}</p> */}

                                    <div
                                        className='p-3 mt-4'
                                        style={{background: '#ffff', borderRadius: '8px'}}>
                                        <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 mb-1`}>Exclusive benefits:</p>

                                        <ul className="custom-list">
                                            {pkg.benefits.map((benefit, idx) => (
                                                <li key={idx} className="custom-list-item fs-14 mb-2">
                                                    <span
                                                        className='p-2'
                                                        style={{
                                                            color: activePackage === pkg.id ? '#3B82F6' : 'black',
                                                        }}
                                                    >
                                                        <CheckOutlined />
                                                    </span>
                                                    <span>{benefit}</span>
                                                </li>
                                            ))}
                                        </ul>

                                        <Button 
                                            type={activePackage === pkg.id ? 'primary' : 'default'}
                                            size='large'
                                            className="fs-16 fw-700 mt-4 button w-100" 
                                            style={{
                                                borderColor: activePackage === pkg.id ? 'none' : '#272727',
                                                borderRadius: '28px'
                                            }}
                                            onClick={() => {
                                                showRestrictionModal(true)
                                            }}
                                        >
                                            Book Now
                                        </Button>

                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>

                    <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="text-start">
                            <p className="fs-12 fw-400 text-black">
                                For more information, offers, and other releated details visit our website or reach out to our specialists. You can directly initiatethe account creation process to avail exclusive benefits. Out team will get in touch with you to complete the formalities 
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            
        </Container>
    )
}

export default Associates
