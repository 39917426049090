import axios from 'axios';

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_SERVICE,
    // baseURL: 'http://localhost:8100',
    timeout: 100000,
    headers: {
        platform: 'web',
    },
});

export const setItem = (key, data) => {
    localStorage.setItem(key, data);
};

export const getItem = (key) => {
    return JSON.parse(localStorage.getItem(key));
};

export const clearSession = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userDetail');
};

export const clearChatOnTheGo = () => {
    localStorage.removeItem('sessionId');
    localStorage.removeItem('chatHistory');
    localStorage.removeItem('collectionName');
};
