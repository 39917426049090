import React, { useEffect, useState } from 'react';
import { Col, Row } from "react-bootstrap";
import { Button, Image } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import './FoodPreferences.css';
import MenuImage1 from '../../../assets/images/custom-booking/foodpreferences/foodimg1.jpg'
import MenuImage2 from '../../../assets/images/custom-booking/foodpreferences/foodimg2.jpg'
import MenuImage3 from '../../../assets/images/custom-booking/foodpreferences/foodimg3.jpg'


const FoodMenu = [
    {
        id: 1,
        category: "Vegetarian Options",
        items: [
            { id: 1, menuimg: MenuImage1, dish: "South Indian Cuisine" },
            { id: 2, menuimg: MenuImage2, dish: "North Indian Cuisine" },
            { id: 3, menuimg: MenuImage3, dish: "Continental Options" },
        ],
    },
    {
        id: 2,
        category: "Non-Vegetarian Options",
        items: [
            { id: 4, menuimg: MenuImage1, dish: "Seafood Special" },
            { id: 5, menuimg: MenuImage2, dish: "Chicken Specials" },
            { id: 6, menuimg: MenuImage3, dish: "Mutton Dishes" },
        ],
    },
    {
        id: 3,
        category: "Non-Vegetarian Options",
        items: [
            { id: 7, menuimg: MenuImage1, dish: "Meal Per Day" },
            { id: 8, menuimg: MenuImage2, dish: "Full-Day Package" },
        ],
    },
];


const FoodPreferences = () => {

    const [foodPrefer, setFoodPrefer] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 530);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 530);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleClick = (id) => {
        setFoodPrefer((prev) =>
            prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
        );
    };

    return (
        <div className='p-2 custom-food-container'>
            <h4 className='fs-24 fw-700 py-2'>Food Preferences</h4>
            <div>
                {FoodMenu.map((menu) => (
                    <div key={menu.id}>
                        <p className='fs-16 fw-400 py-2'>{menu.category}</p>
                        <Row>
                            {menu.items.map((item) => (
                                <Col key={item.id} xxl={4} xl={4} lg={6} md={6} sm={12} xs={12} className="pb-4" >
                                    <div className="position-relative food-menu-img">
                                        <Image
                                            src={item.menuimg}
                                            className='food-img-zoom'
                                            alt={item.dish}
                                            preview={false}
                                        />
                                        <Button
                                            type="default"
                                            className="fs-16 fw-700 btn-menu d-flex align-items-center justify-content-between"
                                            size="large"
                                            style={{
                                                borderRadius: '0px',
                                                background: foodPrefer.includes(item.id)
                                                    ? "#2263E3"
                                                    : "#E6E6E6",
                                                color: foodPrefer.includes(item.id) ? "#fff" : "",
                                            }}
                                            onClick={() => handleClick(item.id)}
                                        >
                                            {item.dish}
                                            <CheckCircleFilled
                                                className="fs-24"
                                                style={{
                                                    color: foodPrefer.includes(item.id)
                                                        ? "#fff"
                                                        : "#2263E3",
                                                }}
                                            />
                                        </Button>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                ))}
            </div>
            <div className='d-flex justify-content-end pt-4'>
                <Button
                    type="primary"
                    className="fs-14 fw-600 booking-btn"
                    size="large"
                    style={{width: "237px"}}
                >
                    Continue
                </Button>
            </div>
        </div>
    );
    
}

export default FoodPreferences
