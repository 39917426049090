import { Card } from 'antd';
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const List = ({ className, jobs, setJobs }) => {
    const navigate = useNavigate();

    return (
        <Container fluid={window?.innerWidth > 1708 ? false : true} className={`py-3 mb-5 ${className}`}>
            <Row className='justify-content-center align-items-center career-detail-page'>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    {jobs.map(option => (
                        <Card
                            key={option.value}
                            className={`w-100`}
                            hoverable
                            style={{ marginBottom: '15px', borderRadius: '20px', borderColor: '#A0A1AB' }}
                            onClick={() => navigate('/job/' + option.value)}
                        >
                            <div className='p-1'>
                                <h5 className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black job-title`} style={{ paddingLeft: '0rem' }} >
                                    {option.position}
                                </h5>
                                <ul className={`${window?.innerWidth > 834.98 ? 'fs-14' : window?.innerWidth <= 425 ? 'fs-14' : 'fs-14'} fw-400 text-black`} style={{ listStyleType: 'none', padding: '0rem' }}>
                                    <li>Location: {option.location}</li>
                                    <li>Salary: {option.salary}</li>
                                    <li>Employment Type: {option.employment_type}</li>
                                </ul>
                            </div>
                        </Card>
                    ))}
                </Col>
            </Row>
        </Container>
    )
}

export default List
