import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import ExploreOpp from '../../components/Careers/ExploreOpp/ExploreOpp'
import NewsAndUpdates from '../../components/homepage/NewsAndUpdates/NewsAndUpdates'
import CareersBanner from '../../components/Careers/CareersBanner/CareersBanner'

const CareersPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  return (
    <Container fluid={true} className="p-0">
      <CareersBanner />
      <ExploreOpp />
      <NewsAndUpdates />
    </Container>
  )
}

export default CareersPage
