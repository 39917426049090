import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import Banner from '../../components/ActivePage/Banner/Banner'
import OurGallery from '../../components/ActivePage/OurGallery/OurGallery'
import RideCard from '../../components/homepage/Rides/RideCard/RideCard'
import BookingFooter from '../../components/Booking/BookingFooter/BookingFooter'

const Activities = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  return (
    <Container fluid={true} className="p-0">
      <Banner />
      <RideCard />
      <OurGallery />
      <BookingFooter />
    </Container>
  )
}

export default Activities
