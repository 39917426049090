import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const DresscodeRules = () => {
    return (
        // <Container fluid={false} className="pb-5">
        //     <Row className="d-flex align-items-center justify-content-center">
        //         <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
        //             <Row className="d-flex align-items-center justify-content-center">
        //                 <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12} >
        //                     <div className='py-2'>
        //                         <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
        //                             1. General Dress Code Guidelines
        //                         </h4>
        //                         <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
        //                             1.1 Appropriate Attire:
        //                         </h6>
        //                         <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                             <li>
        //                                 All visitors are required to wear appropriate clothing while in the park. This includes wearing 
        //                                 proper swimwear in water areas and casual, modest clothing in other parts of the park.
        //                             </li>
        //                             <li>
        //                                 Clothing with offensive or inappropriate language, symbols, or graphics is not permitted. 
        //                                 JoynJoyy Water Park reserves the right to deny entry to any guest wearing such attire.
        //                             </li>
        //                         </ul>
        //                         <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
        //                             1.2 Modesty and Decency:
        //                         </h6>
        //                         <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                             <li>
        //                                 For the comfort of all guests, we ask that swimwear and attire be modest and appropriate for a family-friendly environment. 
        //                                 Excessively revealing clothing, such as string bikinis or overly transparent attire, is not permitted.
        //                             </li>
        //                             <li>
        //                                 Guests are requested to respect the cultural norms of India by dressing decently.
        //                             </li>
        //                         </ul>
        //                         <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
        //                             1.3 Footwear Requirements:
        //                         </h6>
        //                         <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                             <li>
        //                                 Appropriate footwear must be worn at all times in non-water areas. This includes sandals, flip-flops, or water shoes. 
        //                                 Barefoot walking is allowed only in pool and ride areas.
        //                             </li>
        //                             <li>
        //                                 For safety reasons, shoes must be removed before using certain rides, especially those involving water.
        //                             </li>
        //                         </ul>
        //                     </div>

        //                     <div className='py-2'>
        //                         <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
        //                             2. Swimwear Guidelines
        //                         </h4>
        //                         <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-3`}>
        //                             2.1 Required Swimwear for Water Rides and Pools:
        //                         </h6>
        //                         <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                             <li>
        //                                 Only proper swimwear made from swim-safe material (e.g., polyester, nylon, Lycra) is allowed in the water. 
        //                                 Regular clothing such as cotton t-shirts, jeans, and sarees is prohibited in pools and 
        //                                 water rides as they may affect water quality and pose a safety risk.
        //                             </li>
        //                             <li>
        //                                 Guests are advised to wear swimwear that is secure and comfortable for active participation in rides and water attractions.
        //                             </li>
        //                         </ul>
        //                         <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-3`}>
        //                             2.2 Swimwear for Children:
        //                         </h6>
        //                         <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                             <li>
        //                                 Children must wear suitable swimwear while in the water. Infants and toddlers are required 
        //                                 to wear swim diapers to prevent any contamination of the pools.
        //                             </li>
        //                         </ul>
        //                         <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-3`}>
        //                             2.3 Restrictions:
        //                         </h6>
        //                         <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                             <li>Swimwear with buttons, zippers, or metal accessories is not allowed, as these may damage the rides or cause injury.</li>
        //                             <li>Guests with long hair are encouraged to tie it up securely to avoid accidents on rides or during water activities.</li>
        //                         </ul>
        //                     </div>
                            
        //                     <div className='py-2'>
        //                         <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
        //                             3. Cultural Considerations
        //                         </h4>
        //                         <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-3`}>
        //                             3.1 Ethnic and Religious Attire:
        //                         </h6>
        //                         <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                             <li>
        //                                 Guests who prefer to wear ethnic or religious clothing (e.g., burkinis, headscarves) in the water must 
        //                                 ensure that the attire is made from water-safe material that does not pose any safety or hygiene risks.
        //                             </li>
        //                             <li>
        //                                 Traditional clothing, such as sarees or salwar kameez, is allowed in non-water areas but is not permitted 
        //                                 in pools and water attractions due to safety and cleanliness concerns.
        //                             </li>
        //                         </ul>
        //                     </div>
                            
        //                     <div className='py-2'>
        //                         <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
        //                             4. Prohibited Attire
        //                         </h4>
        //                         <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-3`}>
        //                             4.1 Clothing Restrictions:
        //                         </h6>
        //                         <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                             <li>The following types of clothing are strictly prohibited in the park:</li>
        //                             <li>- Transparent or sheer garments that expose undergarments.</li>
        //                             <li>- Clothing with offensive, inappropriate, or discriminatory messages or symbols.</li>
        //                             <li>- Wet clothing worn outside pool areas, as this may create slip hazards.</li>
        //                             <li>- Jeans, denim shorts, and other heavy clothing are not allowed in water rides or pools.</li>
        //                         </ul>
        //                         <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-3`}>
        //                             4.2 Safety Considerations:
        //                         </h6>
        //                         <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                             <li>
        //                                 Accessories such as belts, jewelry, or sharp objects should be removed before participating 
        //                                 in any rides to prevent accidents or damage to the rides.
        //                             </li>
        //                             <li>
        //                                 Shoes with hard soles, spikes, or high heels are not permitted on water rides or in pool areas for safety reasons.
        //                             </li>
        //                         </ul>
        //                     </div>

        //                     <div className='py-2'>
        //                         <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
        //                             5. Safety and Hygiene Considerations
        //                         </h4>
        //                         <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-3`}>
        //                             5.1 Personal Hygiene:
        //                         </h6>
        //                         <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                             <li>
        //                                 Guests are expected to maintain proper personal hygiene during their visit. 
        //                                 Showering is required before entering pools or water rides.
        //                             </li>
        //                         </ul>
        //                         <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-3`}>
        //                             5.2 Towel and Cover-Up:
        //                         </h6>
        //                         <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                             <li>Visitors are encouraged to use towels or cover-ups when walking between water attractions and non-water areas.</li>
        //                             <li>Wet swimwear should not be worn in dining areas or other non-water zones of the park.</li>
        //                         </ul>
        //                     </div>

        //                     <div className='py-2'>
        //                         <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
        //                             6. Dress Code for Special Events
        //                         </h4>
        //                         <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-3`}>
        //                             6.1 Private Events or Parties:
        //                         </h6>
        //                         <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                             <li>
        //                                 For private events, group bookings, or special themed days, specific dress code requirements may be communicated 
        //                                 in advance to event attendees. However, modesty, safety, and hygiene guidelines must still be adhered to.
        //                             </li>
        //                         </ul>
        //                     </div>
                    
        //                     <div className='py-2' >
        //                         <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
        //                             7. Enforcement of Dress Code
        //                         </h4>
        //                         <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-3`}>
        //                             7.1 Right to Refuse Entry:
        //                         </h6>
        //                         <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                             <li>
        //                                 JoynJoyy Water Park reserves the right to refuse entry or request any guest to change their 
        //                                 attire if it is deemed inappropriate or unsafe. Guests who do not comply with the dress code may be asked to leave the park.
        //                             </li>
        //                         </ul>
        //                         <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-3`}>
        //                             7.2 Rental and Retail Options:
        //                         </h6>
        //                         <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                             <li>
        //                                 For guests who may need appropriate swimwear or clothing, we offer swimwear and footwear 
        //                                 rentals or purchases at the park’s retail shop.
        //                             </li>
        //                         </ul>
        //                     </div>
                            
        //                     <div className='py-2'>
        //                         <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
        //                             8. Amendments to the Policy
        //                         </h4>
        //                         <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black pt-3`}>
        //                             <li>
        //                                 JoynJoyy Water Park reserves the right to amend or modify this Dress Code Policy at any time. 
        //                                 Any changes will be communicated via the park’s official website or posted within the park. 
        //                                 Guests are advised to review the policy periodically to ensure compliance.
        //                             </li>
        //                         </ul>
        //                     </div>

        //                     <div className='py-2'>
        //                         <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
        //                             9. Contact Information
        //                         </h4>
        //                         <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                             For any questions or clarifications regarding our dress code policy, please contact our team:
        //                         </p>
        //                         <div className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black pt-3`}>
        //                             <p className='mb-0 fw-700'>JoynJoyy Water Park </p>        
        //                             <p className='mb-0'>Honnavar, Karnataka, Bengaluru</p>
        //                             <p className='mb-0'>Phone: <a href="tel:+919739777566" className="text-black">+91-9739777566</a></p>
        //                             <p className='mb-0'>Email: <a href="mailto:joynjoyy2024@gmail.com" className="text-black">joynjoyy2024@gmail.com</a></p>
        //                             <p>Website: <a href="https://joynjoyy.com/" target="_blank" rel="noopener noreferrer" className="text-black">www.joynjoyy.com</a></p>
        //                         </div>
        //                         <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black pt-4`}>
        //                             By visiting JoynJoyy Water Park, you acknowledge and agree to the above Terms and Conditions. 
        //                             Have a safe and enjoyable experience at our water park!
        //                         </p>
        //                     </div>
        //                 </Col>
        //             </Row>
        //         </Col>
        //     </Row>
        // </Container>

        <Container fluid={false} className="pb-5">
            <Row className="d-flex align-items-center justify-content-center">
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12} >
                            <div className='py-2'>
                                <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    Dress Code & Code of Conduct Guidelines
                                </h4>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                    1. Dress Code Guidelines
                                </h6>
                                <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    To ensure a safe and enjoyable experience for all, we kindly ask guests to adhere to the following dress code:
                                </p>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li>
                                        Swimwear Requirement: Proper swimwear (nylon or lycra material) is mandatory for water rides and pools. Street clothes, denim, or any non-swimwear fabric is not permitted
                                    </li>
                                    <li>
                                        For Men: Swim trunks, board shorts, or speedos.
                                    </li>
                                    <li>
                                        For Women: Swimsuits, tankinis, or burkinis.
                                    </li>
                                    <li>
                                        Prohibited Items: Loose clothing, jewelry, and accessories that might pose safety risks on rides.
                                    </li>
                                    <li>
                                        Religious and Cultural Sensitivity: Modest swimwear options are available for purchase at our retail shops.
                                    </li>
                                </ul>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                    2. Code of Conduct
                                </h6>
                                <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    To maintain a safe, respectful, and enjoyable environment, all guests must adhere to the following conduct rules:
                                </p>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li>
                                        Safety First: Follow ride guidelines and staff instructions at all times.
                                    </li>
                                    <li>
                                        Respect for Others: Offensive language, inappropriate behavior, or harassment of any kind will not be tolerated.
                                    </li>
                                    <li>
                                        Cleanliness: Dispose of trash in designated bins and maintain the cleanliness of the park.
                                    </li>
                                    <li>
                                        Alcohol and Smoking: Only permitted in designated areas. Excessive alcohol consumption or substance abuse will lead to removal from the park.
                                    </li>
                                    <li>
                                        Lost Property: Guests are responsible for their personal belongings.
                                    </li>
                                </ul>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                    3. Additional Information
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li>
                                        Dress Code Compliance: Non-compliance with the dress code may result in restricted access to certain attractions.
                                    </li>
                                    <li>
                                        Park Decorum: Failure to adhere to the code of conduct may result in expulsion from the park without a refund.
                                    </li>
                                    <li>
                                        Accessibility: Adaptive wear for individuals with special needs is welcome.
                                    </li>
                                </ul>
                                <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    For further clarification or assistance, please contact us at +91-9739777566 or email us at joynjoyy2024@gmail.com.
                                </p>
                            </div>

                            {/* <div className='py-2'>
                                <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    2. Swimwear Guidelines
                                </h4>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-3`}>
                                    2.1 Required Swimwear for Water Rides and Pools:
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li>
                                        Only proper swimwear made from swim-safe material (e.g., polyester, nylon, Lycra) is allowed in the water. 
                                        Regular clothing such as cotton t-shirts, jeans, and sarees is prohibited in pools and 
                                        water rides as they may affect water quality and pose a safety risk.
                                    </li>
                                    <li>
                                        Guests are advised to wear swimwear that is secure and comfortable for active participation in rides and water attractions.
                                    </li>
                                </ul>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-3`}>
                                    2.2 Swimwear for Children:
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li>
                                        Children must wear suitable swimwear while in the water. Infants and toddlers are required 
                                        to wear swim diapers to prevent any contamination of the pools.
                                    </li>
                                </ul>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-3`}>
                                    2.3 Restrictions:
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li>Swimwear with buttons, zippers, or metal accessories is not allowed, as these may damage the rides or cause injury.</li>
                                    <li>Guests with long hair are encouraged to tie it up securely to avoid accidents on rides or during water activities.</li>
                                </ul>
                            </div>
                            
                            <div className='py-2'>
                                <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    3. Cultural Considerations
                                </h4>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-3`}>
                                    3.1 Ethnic and Religious Attire:
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li>
                                        Guests who prefer to wear ethnic or religious clothing (e.g., burkinis, headscarves) in the water must 
                                        ensure that the attire is made from water-safe material that does not pose any safety or hygiene risks.
                                    </li>
                                    <li>
                                        Traditional clothing, such as sarees or salwar kameez, is allowed in non-water areas but is not permitted 
                                        in pools and water attractions due to safety and cleanliness concerns.
                                    </li>
                                </ul>
                            </div>
                            
                            <div className='py-2'>
                                <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    4. Prohibited Attire
                                </h4>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-3`}>
                                    4.1 Clothing Restrictions:
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li>The following types of clothing are strictly prohibited in the park:</li>
                                    <li>- Transparent or sheer garments that expose undergarments.</li>
                                    <li>- Clothing with offensive, inappropriate, or discriminatory messages or symbols.</li>
                                    <li>- Wet clothing worn outside pool areas, as this may create slip hazards.</li>
                                    <li>- Jeans, denim shorts, and other heavy clothing are not allowed in water rides or pools.</li>
                                </ul>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-3`}>
                                    4.2 Safety Considerations:
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li>
                                        Accessories such as belts, jewelry, or sharp objects should be removed before participating 
                                        in any rides to prevent accidents or damage to the rides.
                                    </li>
                                    <li>
                                        Shoes with hard soles, spikes, or high heels are not permitted on water rides or in pool areas for safety reasons.
                                    </li>
                                </ul>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    5. Safety and Hygiene Considerations
                                </h4>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-3`}>
                                    5.1 Personal Hygiene:
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li>
                                        Guests are expected to maintain proper personal hygiene during their visit. 
                                        Showering is required before entering pools or water rides.
                                    </li>
                                </ul>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-3`}>
                                    5.2 Towel and Cover-Up:
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li>Visitors are encouraged to use towels or cover-ups when walking between water attractions and non-water areas.</li>
                                    <li>Wet swimwear should not be worn in dining areas or other non-water zones of the park.</li>
                                </ul>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    6. Dress Code for Special Events
                                </h4>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-3`}>
                                    6.1 Private Events or Parties:
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li>
                                        For private events, group bookings, or special themed days, specific dress code requirements may be communicated 
                                        in advance to event attendees. However, modesty, safety, and hygiene guidelines must still be adhered to.
                                    </li>
                                </ul>
                            </div>
                    
                            <div className='py-2' >
                                <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    7. Enforcement of Dress Code
                                </h4>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-3`}>
                                    7.1 Right to Refuse Entry:
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li>
                                        JoynJoyy Water Park reserves the right to refuse entry or request any guest to change their 
                                        attire if it is deemed inappropriate or unsafe. Guests who do not comply with the dress code may be asked to leave the park.
                                    </li>
                                </ul>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-3`}>
                                    7.2 Rental and Retail Options:
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li>
                                        For guests who may need appropriate swimwear or clothing, we offer swimwear and footwear 
                                        rentals or purchases at the park’s retail shop.
                                    </li>
                                </ul>
                            </div>
                            
                            <div className='py-2'>
                                <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    8. Amendments to the Policy
                                </h4>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black pt-3`}>
                                    <li>
                                        JoynJoyy Water Park reserves the right to amend or modify this Dress Code Policy at any time. 
                                        Any changes will be communicated via the park’s official website or posted within the park. 
                                        Guests are advised to review the policy periodically to ensure compliance.
                                    </li>
                                </ul>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    9. Contact Information
                                </h4>
                                <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    For any questions or clarifications regarding our dress code policy, please contact our team:
                                </p>
                                <div className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black pt-3`}>
                                    <p className='mb-0 fw-700'>JoynJoyy Water Park </p>        
                                    <p className='mb-0'>Honnavar, Karnataka, Bengaluru</p>
                                    <p className='mb-0'>Phone: <a href="tel:+919739777566" className="text-black">+91-9739777566</a></p>
                                    <p className='mb-0'>Email: <a href="mailto:joynjoyy2024@gmail.com" className="text-black">joynjoyy2024@gmail.com</a></p>
                                    <p>Website: <a href="https://joynjoyy.com/" target="_blank" rel="noopener noreferrer" className="text-black">www.joynjoyy.com</a></p>
                                </div>
                                <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black pt-4`}>
                                    By visiting JoynJoyy Water Park, you acknowledge and agree to the above Terms and Conditions. 
                                    Have a safe and enjoyable experience at our water park!
                                </p>
                            </div> */}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default DresscodeRules
