import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { Col, Row, Container } from 'react-bootstrap';
import './ParkTimings.css';

const parkTimingsData = {
  seasons: [
    {
      season: 'Summer',
      duration: '(April - June)',
      timings: {
        weekdays: '10:30 AM - 5:30 PM',
        weekends_holidays: '10:00 AM - 6:00 PM',
      },
    },
    {
      season: 'Monsoon',
      duration: '(July - September)',
      timings: {
        weekdays: '10:30 AM - 5:00 PM',
        weekends_holidays: '10:30 AM - 5:30 PM',
      },
    },
    {
      season: 'Winter',
      duration: '(October - March)',
      timings: {
        weekdays: '11:00 AM - 5:30 PM',
        weekends_holidays: '10:30 AM - 5:30 PM',
      },
    },
  ],
};

const ticketPricesData = {
  seasons: [
    {
      level: 'Adults',
      prices: {
        weekdays: 'INR. 899',
        weekends_holidays: 'INR. 899',
      },
    },
    {
      level: 'For Children',
      prices: {
        weekdays: 'INR. 699',
        weekends_holidays: 'INR. 699',
      },
    },
    {
      level: 'Senior Citizen',
      prices: {
        weekdays: 'INR. 699',
        weekends_holidays: 'INR. 699',
      },
    },
  ],
};

const ParkTimings = () => {

  // const isMobile = window.innerWidth <= 425;

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 530);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 530);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = [
    {
      title: 
        <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'} fw-700 text-white`}>
          Timings
        </h4>,
      dataIndex: 'days',
      key: 'days',
      width: 180,
      render: (text) => 
        <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-20' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-20'} fw-700 text-black`}>
          {text}
        </h6>,
    },
    ...parkTimingsData.seasons.map((season) => ({
      title: (
        <div>
          <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'} fw-700 text-white`}>
            {season.season}
          </h4>
          <p className={`${ window?.innerWidth > 834.98 ? 'fs-20' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-20'} fw-400 text-white`}>
            {season.duration}
          </p>
        </div>
      ),
      dataIndex: season.season.toLowerCase(),
      key: season.season.toLowerCase(),
      width: window?.innerWidth <= 834 ? '90px' : '160px',
      render: (text) => 
        <h6 
          className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-600 text-black`}
          style={{ width: window?.innerWidth <= 834 ? '90px' : '190px' }}
        >
          {text}
        </h6>,
    })),
  ];

  const data = [
    {
      key: '1',
      days: 'Weekdays',
      summer: parkTimingsData.seasons[0].timings.weekdays,
      monsoon: parkTimingsData.seasons[1].timings.weekdays,
      winter: parkTimingsData.seasons[2].timings.weekdays,
    },
    {
      key: '2',
      days: 'Weekends & holidays',
      summer: parkTimingsData.seasons[0].timings.weekends_holidays,
      monsoon: parkTimingsData.seasons[1].timings.weekends_holidays,
      winter: parkTimingsData.seasons[2].timings.weekends_holidays,
    },
  ];

  // const ticketColumns = [
  //   {
  //     title: 
  //       <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'} fw-700 text-white`}>
  //         Ticket Prices
  //       </h4>,
  //     dataIndex: 'days',
  //     key: 'days',
  //     width: 140,
  //     render: (text) => 
  //       <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-20' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-20'} fw-700 text-black`}>
  //         {text}
  //       </h6>,
  //   },
  //   ...ticketPricesData.seasons.map((season) => ({
  //     title: (
  //       <div>
  //         <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'} fw-700 text-white`}>
  //           {season.season}
  //         </h4>
  //         <p className={`${ window?.innerWidth > 834.98 ? 'fs-20' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-20'} fw-400 text-white`}>
  //           {season.duration}
  //         </p>
  //       </div>
  //     ),
  //     dataIndex: season.season.toLowerCase(),
  //     key: season.season.toLowerCase(),
  //     width: window?.innerWidth <= 834 ? '90px' : '160px',
  //     render: (text) => <h6 
  //       className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-600 text-black`} 
  //       style={{ width: window?.innerWidth <= 834 ? '90px' : '160px' }}
  //     >
  //       {text}
  //     </h6>,
  //   })),
  // ];

  const ticketColumns = [
    {
      title: (
        <h4
          className={`${
            window?.innerWidth > 834.98
              ? 'fs-24'
              : window?.innerWidth <= 425
              ? 'fs-18'
              : 'fs-22'
          } fw-700 text-white`}
        >
          Ticket Prices
        </h4>
      ),
      dataIndex: 'days',
      key: 'days',
      width: 180,
      render: (text) => (
        <h6
          className={`${
            window?.innerWidth > 834.98
              ? 'fs-20'
              : window?.innerWidth <= 425
              ? 'fs-18'
              : 'fs-20'
          } fw-700 text-black`}
        >
          {text}
        </h6>
      ),
    },
    {
      title: (
        <div>
          <h4
            className={`${
              window?.innerWidth > 834.98
                ? 'fs-24'
                : window?.innerWidth <= 425
                ? 'fs-18'
                : 'fs-22'
            } fw-700 text-white`}
          >
            Adults
          </h4>
        </div>
      ),
      dataIndex: 'adults',
      key: 'adults',
      width: window?.innerWidth <= 834 ? '90px' : '160px',
      render: (text) => (
        <h6
          className={`${
            window?.innerWidth > 834.98
              ? 'fs-18'
              : window?.innerWidth <= 425
              ? 'fs-16'
              : 'fs-18'
          } fw-600 text-black`}
          style={{ width: window?.innerWidth <= 834 ? '90px' : '190px' }}
        >
          {text}
        </h6>
      ),
    },
    {
      title: (
        <div>
          <h4
            className={`${
              window?.innerWidth > 834.98
                ? 'fs-24'
                : window?.innerWidth <= 425
                ? 'fs-18'
                : 'fs-22'
            } fw-700 text-white`}
          >
            For Children
          </h4>
        </div>
      ),
      dataIndex: 'for_children',
      key: 'for_children',
      width: window?.innerWidth <= 834 ? '90px' : '160px',
      render: (text) => (
        <h6
          className={`${
            window?.innerWidth > 834.98
              ? 'fs-18'
              : window?.innerWidth <= 425
              ? 'fs-16'
              : 'fs-18'
          } fw-600 text-black`}
          style={{ width: window?.innerWidth <= 834 ? '90px' : '190px' }}
        >
          {text}
        </h6>
      ),
    },
    {
      title: (
        <div>
          <h4
            className={`${
              window?.innerWidth > 834.98
                ? 'fs-24'
                : window?.innerWidth <= 425
                ? 'fs-18'
                : 'fs-22'
            } fw-700 text-white`}
          >
            Senior Citizen
          </h4>
        </div>
      ),
      dataIndex: 'senior_citizen',
      key: 'senior_citizen',
      width: window?.innerWidth <= 834 ? '90px' : '160px',
      render: (text) => (
        <h6
          className={`${
            window?.innerWidth > 834.98
              ? 'fs-18'
              : window?.innerWidth <= 425
              ? 'fs-16'
              : 'fs-18'
          } fw-600 text-black`}
          style={{ width: window?.innerWidth <= 834 ? '90px' : '190px' }}
        >
          {text}
        </h6>
      ),
    },
  ];

  const ticketData = [
    {
      key: '1',
      days: 'Weekdays',
      adults: ticketPricesData.seasons[0].prices.weekdays,
      for_children: ticketPricesData.seasons[1].prices.weekdays,
      senior_citizen: ticketPricesData.seasons[2].prices.weekdays,
    },
    {
      key: '2',
      days: 'Weekends & holidays',
      adults: ticketPricesData.seasons[0].prices.weekends_holidays,
      for_children: ticketPricesData.seasons[1].prices.weekends_holidays,
      senior_citizen: ticketPricesData.seasons[2].prices.weekends_holidays,
    },
  ];

  const timingMobileTables = () => (
    <div>
      {parkTimingsData.seasons.map((season, index) => (
        <div key={index} className="mobile-table-section">
          <Table
            className="park-timings-mobile-table my-4"            
            columns={[
              {
                title: 
                  <h4 className='fs-18 fw-700 text-white'>
                    Timings
                  </h4>,
                dataIndex: 'days',
                key: 'days',
                width: 140,
                render: (text) => 
                  <h6 className='fs-18 fw-700 text-black'>
                    {text}
                  </h6>,
              },
              {
                title: (
                  <>
                    <h4 className='fs-18 fw-700 text-white'>
                      {season.season}
                    </h4>
                    <p className='fs-18 fw-400 text-white'>
                      {season.duration}
                    </p>
                  </>
                ),
                dataIndex: 'timings',
                key: 'timings',
                render: (text) => 
                <h6 
                  className='fs-16 fw-600 text-black' 
                >
                  {text}
                </h6>,
              },
            ]}
            dataSource={[
              {
                key: '1',
                days: 'Weekdays',
                timings: season.timings.weekdays,
              },
              {
                key: '2',
                days: 'Weekends & holidays',
                timings: season.timings.weekends_holidays,
              },
            ]}
            pagination={false}
            bordered
          />
        </div>
      ))}
    </div>
  );

  const ticketMobileTables = () => (
    <div>
      {ticketPricesData.seasons.map((season, index) => (
        <div key={index} className="mobile-table-section my-4">
          <Table
            className="park-timings-mobile-table my-4"
            columns={[
              {
                title: 
                  <h4 className='fs-18 fw-700 text-white'>
                    Ticket Prices
                  </h4>,
                dataIndex: 'days',
                key: 'days',
                width: 140,
                render: (text) => 
                  <h6 className='fs-18 fw-700 text-black'>
                    {text}
                  </h6>,
              },
              {
                title: (
                  <>
                    <h4 className='fs-18 fw-700 text-white'>
                      {season.level}
                    </h4>
                    {/* <p className='fs-18 fw-400 text-white'>
                      {season.duration}
                    </p> */}
                  </>
                ),
                dataIndex: 'timings',
                key: 'timings',
                render: (text) => 
                  <h6 
                    className='fs-16 fw-600 text-black'
                  >
                    {text}
                  </h6>,
              },
            ]}
            dataSource={[
              {
                key: '1',
                days: 'Weekdays',
                timings: season.prices.weekdays,
              },
              {
                key: '2',
                days: 'Weekends & holidays',
                timings: season.prices.weekends_holidays,
              },
            ]}
            pagination={false}
            bordered
          />
        </div>
      ))}
    </div>
  );

  return (
    <Container className='py-4'>
      <Row className="align-items-center justify-content-center">
        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Row className="d-flex align-items-center justify-content-center">
            <Col xxl={8} xl={8} lg={10} md={12} sm={12} xs={12}>
              {isMobile ? (
                timingMobileTables()
              ) : (
                <Table
                  className="park-timings-table"
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  bordered
                  rowClassName={(record, index) => {
                    if (index === 0) return 'custom-first-row';
                    if (index === 1) return 'custom-second-row';
                    return '';
                  }}
                />
              )}

              <div className='pt-5'>
                <p 
                  className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400`} 
                  style={{color: '#000000'}}
                >
                  Welcome to JoynJoyy Water Park. By accessing or using any of the services provided by JoynJoyy, 
                  you agree to comply with these Terms and Conditions. Please read the following information carefully before entering the park.
                </p> 
              </div>

              <div className='py-2'>
                <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                  Special Event and Holiday Hours:
                </h4>
                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black pt-2`}>
                  <li>
                    JoynJoyy Water Park offers extended hours for special events, public holidays, and festive occasions. 
                    Check our **Events Page** or follow us on social media for updates on upcoming events and changes in timings.
                  </li>
                </ul>
              </div>

              <div className='py-2'>
                <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                  Last Entry & Ride Closing:
                </h4>
                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black pt-2`}>
                  <li>
                    Last Entry: Monday to Friday: 4:30 PM, Saturday and Sunday: 5:30 PM
                  </li>
                  <li>
                    Ride Closures: Rides and water attractions close 30 minutes before the park closes to allow guests to exit safely.
                  </li>
                </ul>
              </div>

              <div className='py-2'>
                <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                  Important Notes:
                </h4>
              <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black pt-2`}>
                <li>Ticket Counters Close 1 hour before park closing time.</li>
                <li>Park timings are subject to change based on weather conditions or special events.</li>
                  <li>For group bookings or private events, extended hours may be available by prior arrangement.</li>
                  <li>We recommend arriving early to enjoy all the rides and attractions without rushing!</li>
                </ul>
              </div>

              {isMobile ? (
                ticketMobileTables()
              ) : (
                <Table 
                  className="park-timings-table"
                  columns={ticketColumns} 
                  dataSource={ticketData} 
                  pagination={false} 
                  bordered
                  rowClassName={(record, index) => {
                    if (index === 0) return 'custom-first-row';
                    if (index === 1) return 'custom-second-row';
                    return '';
                  }}
                />
              )}

              <div className='py-2'>
                <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>Contact Information</h4>
                <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                  If you have any questions regarding park hours or special timings for your visit, feel free to get in touch:
                </p>
                <div className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black pt-3`}>
                  <p className='mb-0 fw-700'>JoynJoyy Water Park </p>        
                  <p className='mb-0'>Honnavar, Karnataka, Bengaluru</p>
                  <p className='mb-0'>Phone: <a href="tel:+919739777566" className="text-black">+91-9739777566</a></p>
                  <p className='mb-0'>Email: <a href="mailto:joynjoyy2024@gmail.com" className="text-black">joynjoyy2024@gmail.com</a></p>
                  <p>Website: <a href="https://joynjoyy.com/" target="_blank" className="text-black">www.joynjoyy.com</a></p>
                </div>
                <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                  We look forward to welcoming you to JoynJoyy Water Park for a fun-filled day of excitement and relaxation! 
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ParkTimings;
