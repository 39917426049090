import React from 'react';
import './offerpage.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Button, Image } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import OfferOne from '../../assets/images/home/offers/img-bulk-booking-bonanza.webp';
import OfferTwo from '../../assets/images/home/offers/img-school-out.webp';
import OfferThree from '../../assets/images/home/offers/img-college-craze.webp';
import OfferFour from '../../assets/images/home/offers/img-corporate-retreat.webp';
import OfferFive from '../../assets/images/home/offers/img-weekend-family-fun.webp';
import Icons from '../../icons';
import { useNavigate } from 'react-router-dom';
import { useBookingRestrictionStore } from '../../context/BookingRestrictionContext';


const SLIDES = [
    {
        img: OfferOne,
        heading: "Bulk Booking Bonanza:",
        subHeading: "Gather & Save!Perfect for family reunions, friend gatherings, and community events.",
        featureTxt: "Key Features",
        features: [
            { icon: 'Water', size: 24, color: '#0256B8', txt: 'Water Activities' },
            { icon: 'BreakFast', size: 24, color: '#0256B8', txt: 'Breakfast & Lunch' },
            { icon: 'BreakFast', size: 24, color: '#0256B8', txt: 'Breakfast & Lunch' },
            { icon: 'Evening', size: 24, color: '#0256B8', txt: 'Evening Hi Tea' }
        ],
        bgLight: 'bg-light-blue',
        bgDark: 'bg-dark-blue',
        offerTxt: 'First 100 Tickets: 80% OFF',
        offerSubTxt: 'Limited time only. Book now!',
        buttonTxt: 'Get Details',
        category: 'Agents'
    },
    {
        img: OfferTwo,
        heading: "School's Out, Fun's In:",
        subHeading: "Special Student Discounts!Make education fun with our exclusive school trip rates.",
        featureTxt: "Key Features",
        features: [
            { icon: 'Water', size: 24, color: '#AE2100', txt: 'Water Activities' },
            { icon: 'BreakFast', size: 24, color: '#AE2100', txt: 'Breakfast & Lunch' },
            { icon: 'BreakFast', size: 24, color: '#AE2100', txt: 'Breakfast & Lunch' },
            { icon: 'Evening', size: 24, color: '#AE2100', txt: 'Evening Hi Tea' }
        ],
        bgLight: 'bg-light-red',
        bgDark: 'bg-dark-red',
        offerTxt: 'North-Kanara District Residents: 40% OFF',
        offerSubTxt: 'Celebrate with your local community. Valid ID required.',
        buttonTxt: 'Get Details',
        category: 'School / Colleges'
    },
    {
        img: OfferThree,
        heading: "College Craze:",
        subHeading: "Unforgettable Group Rates!Enjoy a day of bonding and adventure with your college crew.",
        featureTxt: "Key Features",
        features: [
            { icon: 'Water', size: 24, color: '#BD5B02', txt: 'Water Activities' },
            { icon: 'BreakFast', size: 24, color: '#BD5B02', txt: 'Breakfast & Lunch' },
            { icon: 'BreakFast', size: 24, color: '#BD5B02', txt: 'Breakfast & Lunch' },
            { icon: 'Evening', size: 24, color: '#BD5B02', txt: 'Evening Hi Tea' }
        ],
        bgLight: 'bg-light-yellow',
        bgDark: 'bg-dark-yellow',
        offerTxt: 'Weekend Campus Adventure: 35% OFF for college students',
        offerSubTxt: 'Make learning fun with group discounts. Minimum 20 students.',
        buttonTxt: 'Get Details',
        category: 'School / Colleges'
    },
    {
        img: OfferFour,
        heading: "Corporate Retreat:",
        subHeading: "Team Building with a Twist!Enhance team spirit with our tailored corporate packages.",
        featureTxt: "Key Features",
        features: [
            { icon: 'Water', size: 24, color: '#006836', txt: 'Water Activities' },
            { icon: 'BreakFast', size: 24, color: '#006836', txt: 'Breakfast & Lunch' },
            { icon: 'BreakFast', size: 24, color: '#006836', txt: 'Breakfast & Lunch' },
            { icon: 'Evening', size: 24, color: '#006836', txt: 'Evening Hi Tea' }
        ],
        bgLight: 'bg-light-green',
        bgDark: 'bg-dark-green',
        offerTxt: 'Groups of 20+: 25% OFF',
        offerSubTxt: 'Share the joy with your friends and family. Book together and save!',
        buttonTxt: 'Get Details',
        category: 'Corporates'
    },
    {
        img: OfferFive,
        heading: "Weekend Family Fun:",
        subHeading: "Create Cherished Memories!Make weekends special with our family-friendly deals.",
        featureTxt: "Key Features",
        features: [
            { icon: 'Water', size: 24, color: '#453597', txt: 'Water Activities' },
            { icon: 'BreakFast', size: 24, color: '#453597', txt: 'Breakfast & Lunch' },
            { icon: 'BreakFast', size: 24, color: '#453597', txt: 'Breakfast & Lunch' },
            { icon: 'Evening', size: 24, color: '#453597', txt: 'Evening Hi Tea' }
        ],
        bgLight: 'bg-light-purple',
        bgDark: 'bg-dark-purple',
        offerTxt: 'Corporate Packages: 40% OFF',
        offerSubTxt: 'Plan your next team-building event with us. Valid ID proof required.',
        buttonTxt: 'Get Details',
        category: 'Retail'
    }
];

const OfferPage = ({ selectedCategory }) => {
    const navigate = useNavigate();
    const { showRestrictionModal } = useBookingRestrictionStore();

    const filteredSlides = selectedCategory === 'All'
        ? SLIDES
        : SLIDES.filter(slide => slide.category === selectedCategory);

    return (
        <Container className=''>
            
            <Row className='d-flex justify-content-center align-items-center'>
                <Col xxl={10} xl={10} lg={12} md={10} sm={12} xs={12}>
                    {filteredSlides.map((item, index) => (
                        <div key={index} className={`off-box my-5 ${item.bgLight}`}>
                            <div className='w-100 h-100 p-4'>
                                <Row className="p-0">
                                    <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} className={`off-lft p-5 ${window?.innerWidth <= 834 ? 'order-last' : 'order-first'}`}>
                                        <div className='off-content py-4'>
                                            <p className={`${window?.innerWidth > 834.98 ? 'fs-36' : window?.innerWidth <= 425 ? 'fs-24' : 'fs-32'} fw-800 text-black italic`}>{item.heading}</p>
                                            <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-16'} fw-400 text-black mb-4`}>{item.subHeading}</p>
                                            <Button 
                                                type="primary" 
                                                className="fs-16 fw-700 booking-btn" 
                                                size="large"
                                                onClick={() => {
                                                    // navigate('/booking');
                                                    showRestrictionModal(true)
                                                }}
                                            >
                                                Buy Now
                                                <ArrowRightOutlined />
                                            </Button>
                                        </div>
                                        <div className='key-features'>
                                            <p className='text-black fs-24 fw-700 mb-4'>
                                                {item.featureTxt}
                                            </p>
                                                <Row className=''>
                                                    {item.features.map((feature, index) => {
                                                        const Icon = Icons[feature.icon];
                                                            return (
                                                                <Col key={index} xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} className='pb-3'>
                                                                    <div className='d-flex align-items-center justify-content-start'>
                                                                        <div className='icon-box' style={feature.icon === 'Evening' ? { paddingLeft: '7px' } : null}>
                                                                            <Icon size={feature.size} color={feature.color} />
                                                                        </div>
                                                                        <p className='mb-0 fs-14 fw-600 text-black px-2'>{feature.txt}</p>
                                                                    </div>
                                                                </Col>
                                                            );
                                                    })}
                                                </Row>
                                        </div>
                                    </Col>
                                    {window?.innerWidth > 425 && (
                                        <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} className={`off-rgt ${window?.innerWidth <= 834 ? 'order-first' : 'order-last'}`}>
                                            <div className='off-img position-relative' >
                                                <Image
                                                    src={item.img}
                                                    alt={item.heading}
                                                    className="content-img"
                                                    preview={false}
                                                    style={{borderRadius: '32px'}}
                                                />
                                                <div className={`off-info ${item.bgDark} position-absolute p-4`}>
                                                    <Row className="p-0">
                                                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                            <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-22' : 'fs-22'} fw-700 text-white`}>{item.offerTxt}</p>
                                                        </Col>
                                                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                            <Button 
                                                                ghost 
                                                                className='fs-16 fw-700 booking-btn' 
                                                                size='large'
                                                                onClick={() => {
                                                                    navigate('/offerbooking');
                                                                }}
                                                            >
                                                                {item.buttonTxt}
                                                                <ArrowRightOutlined />
                                                            </Button>
                                                        </Col>
                                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                            <p className={`${window?.innerWidth > 834.98 ? 'fs-16' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-16'} fw-400 text-white`}>{item.offerSubTxt}</p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </div>
                    ))}
                </Col>
            </Row>
        </Container>
    )
}

export default OfferPage;
