import React from 'react'
import { Container, Row, Col } from "react-bootstrap";

const ServicesBanner = () => {
    return (
        <div>
            <video
                className="banner-wrapper"
                autoPlay
                loop
                muted
                playsInline
                controls={false}
                disablePictureInPicture
                controlsList="nodownload noplaybackrate nofullscreen"
                style={{width: '100%', height: '265px', objectFit: 'cover', pointerEvents: "none"}}
            >
                <source 
                    src={require('../../../assets/videos/headervideo/header-video.mp4')} 
                    type="video/mp4"
                />
            </video>
      
            <div className="gradient-overlay">
                <Container className="py-5" >
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xxl={8} xl={8} lg={8} md={10} sm={10} xs={10} className="text-center">
                            <p className="fs-16 fw-400 text-black">
                                Policy
                            </p>
                            <p className='heading-text fw-800 italic'>
                                Services  
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default ServicesBanner
