import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Image } from 'antd';
import { Row, Col, Container } from 'react-bootstrap';
import userIcon from '../../../assets/images/bookingimages/ticketicon.png';
import './AddTickets.css';
import FormItem from 'antd/es/form/FormItem';
import useRetail from '../../../hooks/useRetail';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useBookingRestrictionStore } from '../../../context/BookingRestrictionContext';

const AddTickets = ({ setUpdatedBookingDetails }) => {
    const { priceMeta } = useRetail();
    const { showRestrictionModal } = useBookingRestrictionStore();

    const ticketData = [
        {
            id: 1,
            type: "Adults",
            description: "Height above 140 cm or 4 feet 7 inches.",
            count: 0,
            price: 0,
        },
        {
            id: 2,
            type: "Children",
            description: "Height between 90 cm and 140 cm or 3 feet and 4 feet 7 inches.",
            count: 0,
            price: 0,
        },
        {
            id: 3,
            type: "Senior Citizen",
            description: "Aged above 60 years. Seniors required to show proof of age.",
            count: 0,
            price: 0,
        },
        {
            id: 4,
            type: "Disabled",
            description: "For individuals with a permanent disability.",
            count: 0,
            price: 0,
        }
    ];

    const [tickets, setTickets] = useState(ticketData);

    useEffect(() => {
        if (priceMeta) {
            setTickets((prevTickets) =>
                prevTickets.map((ticket) => {
                    let price = 0;
                    switch (ticket.type) {
                        case 'Adults':
                            price = priceMeta.adult;
                            break;
                        case 'Children':
                            price = priceMeta.child;
                            break;
                        case 'Senior Citizen':
                            price = priceMeta.senior;
                            break;
                        case 'Disabled':
                            price = priceMeta.disabled;
                            break;
                        default:
                            break;
                    }
                    return { ...ticket, price: price || 0 };
                })
            );
        }
    }, [priceMeta]);
    

    useEffect(() => {
        const bookingDetails = {
            adult: tickets.find(ticket => ticket.type === 'Adults')?.count || 0,
            child: tickets.find(ticket => ticket.type === 'Children')?.count || 0,
            senior: tickets.find(ticket => ticket.type === 'Senior Citizen')?.count || 0,
            disabled: tickets.find(ticket => ticket.type === 'Disabled')?.count || 0,
        };

        setUpdatedBookingDetails(bookingDetails);
    }, [tickets]);

    const handleIncrement = (id) => {
        setTickets((prevTickets) => {
            const updatedTickets = prevTickets.map((ticket) =>
                ticket.id === id ? { ...ticket, count: ticket.count + 1 } : ticket
            );           
            return updatedTickets;
        });
    };

    const handleDecrement = (id) => {
        setTickets((prevTickets) => {
            const updatedTickets = prevTickets.map((ticket) =>
                ticket.id === id && ticket.count > 0 ? { ...ticket, count: ticket.count - 1 } : ticket
            );
            return updatedTickets;
        });
    };

    const totalAmount = tickets.reduce((total, ticket) => total + (ticket.price * ticket.count), 0);

    return (
        <Container 
            className='my-5 add-ticket-container'
            style={{ borderRadius: '24px', backgroundColor: '#F8F8F9' }}
        >
            
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="section-container p-4">
                        <p className={`${window?.innerWidth > 834.98 ? 'fs-32' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-32'} fw-700 text-white section-number`}>2</p>
                        <div className='w-100'>
                            <h3 className={`${window?.innerWidth > 834.98 ? 'fs-32' : window?.innerWidth <= 425 ? 'fs-22' : 'fs-32'} fw-800 text-black italic`}>Add Tickets</h3>
                            <div className="d-flex justify-content-between align-items-center">
                                <span className='fs-18 fw-700 d-flex align-items-center'>
                                    Added Tikets: {tickets.filter(ticket => ticket.count > 0).map(ticket => `${ticket.count} ${ticket.type}`).join(', ')}
                                </span>
                                <span className='fs-28 fw-700 d-flex align-items-center'>
                                    ₹{totalAmount}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="border-line m-4"></div>
                    
                    <div
                        className='m-4 ticket-content'
                        style={{backgroundColor: '#ffff', borderRadius: '16px'}}
                    >
                        <Row>
                            <Col xxl={6} xl={6} lg={12} md={12} sm={12} xs={12}>
                                {tickets.map((ticket) => (
                                    <Row key={ticket.id} className="align-items-center p-4 ticket-row">
                                        <Col xxl={2} xl={2} lg={2} md={2} className='ticket-avatar'>
                                            <Image 
                                                src={userIcon} 
                                                alt={`${ticket.type} icon`}
                                                preview={false}
                                                
                                                style={{ width: '80px', height: '80px', objectFit: 'cover', borderRadius: '24px' }}
                                            />
                                        </Col>
                                        <Col xxl={7} xl={7} lg={7} md={7} sm={8} xs={8}>
                                            <h5 className={`${window?.innerWidth > 834.98 ? 'fs-20' : window?.innerWidth <= 425 ? 'fs-14' : 'fs-16'} fw-700 text-black italic`}>{ticket.type}</h5>
                                            <p className="fw-700 fs-24 text-black italic mb-0">₹{ticket.price} x {ticket.count}</p>
                                            <p className={`${window?.innerWidth > 834.98 ? 'fs-16' : window?.innerWidth <= 425 ? 'fs-12' : 'fs-14'} fw-400 text-black`}>{ticket.description}</p>                                            
                                        </Col>
                                        <Col xxl={3} xl={3} lg={3} md={3} sm={4} xs={4}>
                                            <div 
                                                className='d-flex align-items-center justify-content-center ticket-button' 
                                                style={{ border: '1px solid black', borderRadius: '28px', padding: '8px' }}
                                            >
                                                <span 
                                                    onClick={() => handleDecrement(ticket.id)} 
                                                    className="fw-700 fs-12 p-1 text-black"
                                                    style={{ cursor: 'pointer'}}
                                                >
                                                    <MinusOutlined />
                                                </span>
                                                
                                                <span className="fw-700 fs-18 p-1 text-black">
                                                    {ticket.count}
                                                </span>
                                                
                                                <span 
                                                    onClick={() => handleIncrement(ticket.id)} 
                                                    className="fw-700 fs-12 p-1 text-black"
                                                    style={{ cursor: 'pointer'}}
                                                >
                                                    <PlusOutlined />
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                            </Col>

                            <Col xxl={6} xl={6} lg={12} md={12} sm={12} xs={12}>
                                <div
                                    style={{borderRadius: '24px', backgroundColor: '#DBEBFE'}}
                                    className='p-5 m-4 ticket-offer'
                                >
                                    <h3 className={`${window?.innerWidth > 834.98 ? 'fs-32' : window?.innerWidth <= 425 ? 'fs-24' : 'fs-28'} fw-800 text-primary`}>Checkout the offers page for more offers</h3>
                                    <p className='fs-18 fw-400'>Limited Time Offer: Book Now & Get 50% OFF on All Tickets!</p>
                                    <Button
                                        type='primary'
                                        size='large'
                                        className='booking-btn fs-16 fw-700'
                                        onClick={() => showRestrictionModal(true)}
                                    >
                                        Grab Your Tickets Now!
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                                               
                        <Form layout="vertical">
                            <Row className='m-4 ticket-form'>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <FormItem style={{ marginBottom: '2rem' }} >
                                        <div>
                                            <Input
                                                className="floating-input fs-14 fw-400"
                                                placeholder="Company Name"
                                                style={{ border: '1px solid #303136' }}
                                            />
                                            <label className="floating-label fs-12">Apply voucher code</label>
                                        </div>
                                    </FormItem>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <FormItem 
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div>
                                            <Input
                                                className="floating-input fs-14 fw-400"
                                                placeholder="Company Name"
                                                style={{ border: '1px solid #303136' }}
                                            />
                                            <label className="floating-label fs-12">Enter GST Bill</label>
                                        </div>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default AddTickets;
