function BreakFast({ size, color }) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M6.25 17.4344C4.68304 16.7162 3.35529 15.5631 2.4246 14.1122C1.49392 12.6613 0.999456 10.9737 1 9.25H19C19.0005 10.9737 18.5061 12.6613 17.5754 14.1122C16.6447 15.5631 15.317 16.7162 13.75 17.4344V18.25C13.75 18.4489 13.671 18.6397 13.5303 18.7803C13.3897 18.921 13.1989 19 13 19H7C6.80109 19 6.61032 18.921 6.46967 18.7803C6.32902 18.6397 6.25 18.4489 6.25 18.25V17.4344Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.75 6.25C13.75 6.25 12.0625 5.725 13.75 3.625C15.4375 1.525 13.75 1 13.75 1" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10 6.25C10 6.25 8.3125 5.725 10 3.625C11.6875 1.525 10 1 10 1" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.25 6.25C6.25 6.25 4.5625 5.725 6.25 3.625C7.9375 1.525 6.25 1 6.25 1" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
}

export default BreakFast;
