import React, { useEffect } from "react";
import DiningOrder from "../../components/DiningSetPage/DiningOrder/DiningOrder";
import NewsAndUpdates from "../../components/homepage/NewsAndUpdates/NewsAndUpdates";
import QuickBites from "../../components/DiningSetPage/QuickBites/QuickBites";
import VegetarianFood from "../../components/DiningSetPage/VegetarianFood/VegetarianFood";
import NonVegetarianFood from "../../components/DiningSetPage/NonVegetarianFood/NonVegetarianFood";
import { Container } from "react-bootstrap";

const DiningPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  return (
    <div>
      <Container fluid={true} className="p-0">
        <DiningOrder />
        <VegetarianFood />
        <NonVegetarianFood />
        <QuickBites />
        <NewsAndUpdates />
      </Container>
    </div>
  );
};

export default DiningPage;
