import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Image } from "antd";
import './RoomFacilities.css';
import Ticketing from "../../../assets/images/guestservice/img-ticketing.webp";
import WristBand from "../../../assets/images/guestservice/img-wrist-band.webp";
import ChangingRooms from "../../../assets/images/guestservice/img-changing-rooms.webp";
import FoodBeverage from "../../../assets/images/guestservice/img-Food-beverage-services.webp";
import FirstAid from "../../../assets/images/guestservice/img-first-aid.webp";
import GuestAssistance from "../../../assets/images/guestservice/img-guest-assist.webp";
import PhotoVideo from "../../../assets/images/guestservice/img-photo-services.webp";
import RetailSouvenir from "../../../assets/images/guestservice/img-retail-souvenir-shops.webp";
import VipGroup from "../../../assets/images/guestservice/img-vip.webp";
import Entertainment from "../../../assets/images/guestservice/img-events.webp";
import Parking from "../../../assets/images/guestservice/img-transportation.webp";
import Wifi from "../../../assets/images/guestservice/img-wifi.webp";
import Accessibility from "../../../assets/images/guestservice/img-accessibility.webp";
import Booking from "../../../assets/images/guestservice/img-booking.webp";
import HelpSupport from "../../../assets/images/guestservice/img-help.webp";
import { ArrowRightOutlined } from "@ant-design/icons";

const ROOMS = [
  {
    img: Ticketing,
    title: "Ticketing & Information",
    subTitle:
      "Guests can book tickets online via the official website or mobile app for hassle-free entry. Walk-in tickets available at the park entrance, including options for day passes and family packages.A dedicated desk at the entrance and main areas to assist with general inquiries, park maps, and event schedules.",
  },
  {
    img: WristBand,
    title: "Wrist Band facility",
    subTitle:
      "Upon reaching the park by booking the tickets online or on-site, you are supposed to obtain a prepaid wrist band with a nominal refundable security deposit before entering the park. The wrist band may be used to add money for any purchases inside the park. Balance amount, if any, shall be refunded upon returning the wrist band.  The wrist band can be used to avail the following benefits; Lockers are available for rent to securely store personal belongings like clothes, bags, and valuables. You may purchase various clothing materials useful at the park using the wrist band. Artifacts, and other items are also available for purchase at the shops inside the park. Wrist band can be used to buy juices and snacks, food at the restaurants. We have dedicated kitchen and independent food courts for pure veg and non-veg serving.",
  },
  {
    img: ChangingRooms,
    title: "Changing Rooms & Showers",
    subTitle:
      "Clean and well-maintained changing areas separate for men and women, with dedicated sections for families. Hot and cold shower options available for guests to freshen up after water activities. Fresh, branded towels available for rent or purchase at the guest services desk.",
  },
  {
    img: FoodBeverage,
    title: "Food & Beverage Services",
    subTitle:
      "A multi-cuisine food court offering vegetarian and non-vegetarian options, fast food, beverages, and desserts. Conveniently located snack kiosks throughout the park serving quick bites like popcorn, ice cream, and drinks. Free water refilling stations throughout the park to stay hydrated.",
  },
  {
    img: FirstAid,
    title: "Medical & First Aid Services",
    subTitle:
      "Equipped with medical supplies and trained personnel to handle minor injuries and provide basic first aid. Qualified medical staff available for emergencies; ambulance service available if needed.",
  },
  {
    img: GuestAssistance,
    title: "Guest Assistance",
    subTitle:
      "A centrally located counter where lost items are catalogued and stored for retrieval. Play areas for toddlers with certified caretakers, allowing parents to enjoy more adventurous activities. Available for rent to accommodate families with young children and guests with limited mobility.",
  },
  {
    img: PhotoVideo,
    title: "Photo & Video Services",
    subTitle:
      "Professional photographers available to capture your moments at the park. Photos can be purchased as prints at the park or downloaded digitally in due course of time… ",
  },
  {
    img: RetailSouvenir,
    title: "Retail & Souvenir Shops",
    subTitle:
      "Souvenir stores offering branded merchandise, swimwear, waterproof accessories, and themed items like keychains, mugs, and hats. Purchase essentials like sunscreen, flip-flops, sunglasses, and water-proof pouches for phones and wallets.",
  },
  {
    img: VipGroup,
    title: "VIP & Group Services",
    subTitle:
      "Special VIP areas for relaxation, offering reserved seating, complimentary refreshments, and fast-track passes for water rides. Customized group packages for birthdays, corporate events, and family reunions, including private spaces, dedicated hosts, and meal services. Private cabanas available for rent, offering shade, privacy, and personal butler services.",
  },
  {
    img: Entertainment,
    title: "Entertainment & Events",
    subTitle:
      "Regular live shows including dance performances, music bands, and DJ nights at the park’s central stage. Seasonal events like Holi celebrations, beach parties, and water festivals with special activities and performances. Dedicated play areas for kids with water slides, splash zones, and interactive games.",
  },
  {
    img: Parking,
    title: "Transportation & Parking",
    subTitle:
      "Spacious parking areas with attendants, including separate spaces for cars, bikes, and buses. Complimentary and/or paid shuttle services from nearby resorts or parking lots to the park entrance. Available for VIP guests and premium ticket holders.",
  },
  {
    img: Wifi,
    title: "Wi-Fi & Connectivity",
    subTitle:
      "Complimentary Wi-Fi available in select areas of the park for guests to stay connected. Charging stations for mobile devices available in lounge areas and locker zones.",
  },
  {
    img: Accessibility,
    title: "Accessibility Services",
    subTitle:
      "All major areas, rides, and restaurants are wheelchair accessible with ramps and dedicated paths. Specially trained staff to assist differently-abled guests throughout the park.",
  },
  {
    img: Booking,
    title: "Booking & Reservations",
    subTitle:
      "Guests can book tickets, cabanas, lockers, and group packages online in advance to avoid long wait times. Full refunds available on cancellations made 24 hours before the scheduled visit. Special offers for frequent visitors with discounted entry rates, exclusive benefits, and perks.",
  },
  {
    img: HelpSupport,
    title: "Help & Support",
    subTitle:
      "A dedicated helpline for any inquiries, complaints, or emergencies before or during the visit. Staff trained to communicate in multiple languages to assist international guests and visitors from different regions. Guests can provide feedback on services and experiences at kiosks located throughout the park or via the mobile app.",
  },
];

const RoomFacilities = () => {
  return (
    <Container>
      <Row className="py-5 d-flex">
        {ROOMS?.map((item) => {
          return (
            <Col xxl={4} xl={4} lg={6} md={6} sm={12} xs={12}>
              <div className="room-block">
                  <Image 
                    src={item?.img} 
                    alt="Image" 
                    preview={false} 
                    style={{borderRadius: '24px'}}                   
                  />
                    
                    <div className=" text-start my-4 pb-3">
                      <p
                        className={`${
                          window?.innerWidth > 835
                            ? "fs-28"
                            : window?.innerWidth <= 425
                            ? "fs-24"
                            : "fs-24"
                        } fw-800 text-black italic`}
                      >
                        {item?.title}
                      </p>
                      {/* <p className="fs-16 fw-400 text-black mb-5 text-clamp" style={{textAlign: 'justify'}}>
                        {item.subTitle}
                      </p> */}
                      <Button
                        type="default"
                        size="large"
                        className="fs-16 fw-700"
                        style={{
                          borderRadius: '28px',
                          zIndex: 1,
                          borderColor: '#272727'
                        }}
                        // onClick={() => showModal(item)}
                      >
                        learn more
                        <ArrowRightOutlined />
                      </Button>
                    </div>

                </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default RoomFacilities;
