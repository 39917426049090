function Evening({ size, color }) {
    return (
        <svg 
            width={size}
            height={size}
            viewBox="0 0 20 18" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M6.00391 1.00781V3.67188" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.66797 1.00781V3.67188" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.332 1.00781V3.67188" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1.3418 16.9922H15.9941" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.61262 16.9922C4.33793 16.4073 3.2579 15.4688 2.50085 14.2882C1.74379 13.1076 1.34151 11.7345 1.3418 10.332V6.33594H15.9941V10.332C15.9944 11.7345 15.5922 13.1076 14.8351 14.2882C14.078 15.4688 12.998 16.4073 11.7233 16.9922" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.994 6.33594C16.7006 6.33594 17.3782 6.61661 17.8778 7.11622C18.3774 7.61583 18.6581 8.29345 18.6581 9V9.66602C18.6581 10.3726 18.3774 11.0502 17.8778 11.5498C17.3782 12.0494 16.7006 12.3301 15.994 12.3301H15.7126" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
}

export default Evening;