import React from 'react';
import Masonry from 'react-masonry-css';
import { Card, Image } from 'antd';
import './testimonialsection.css';
import ClientImage1 from '../../../assets/images/testimonial/testimonial1.svg';
import ClientImage2 from '../../../assets/images/testimonial/testimonial2.svg';
import ClientImage3 from '../../../assets/images/testimonial/testimonial3.svg';
import ClientImage4 from '../../../assets/images/testimonial/testimonial4.svg';
import ClientImage5 from '../../../assets/images/testimonial/testimonial1.svg';
import ClientImage6 from '../../../assets/images/testimonial/testimonial6.svg';
import ClientImage7 from '../../../assets/images/testimonial/testimonial7.svg';
import ClientImage8 from '../../../assets/images/testimonial/testimonial9.svg';
import ClientImage9 from '../../../assets/images/testimonial/testimonial8.svg';
import ClientImage10 from '../../../assets/images/testimonial/testimonial1.svg';

import { Col, Container, Row } from 'react-bootstrap';

const testimonials = [
  {
    img: ClientImage1,
    name: 'Sneha Raikar',
    location: 'Homemaker',
    testimonial: '"Our kids are counting down the days to the launch! This park promises to be a family haven with something for everyone."',
    bgLight: 'bg-light-red'
  },
  {
    img: ClientImage2,
    name: 'Sachin Achari',
    location: 'carpenter',
    testimonial: '"The idea of thrilling rides and adventure sports in phase two is super exciting. Can’t wait to dive in!"',
    bgLight: 'bg-light-yellow'
  },
  {
    img: ClientImage3,
    name: 'Vinayak',
    location: 'Tour operator',
    testimonial: '"A perfect spot for our team outings! The facilities look promising, and the exclusive packages are a big plus."',
    bgLight: 'bg-light-grey'
  },
  {
    img: ClientImage4,
    name: 'Sadanand',
    location: 'Business Owner',
    testimonial: '"It’s great to see a world-class attraction coming to our area. The job opportunities are a bonus for the community."',
    bgLight: 'bg-light-blue'
  },
  {
    img: ClientImage5,
    name: 'Fatima',
    location: 'High School Student',
    testimonial: '"This park looks like it will be THE place to hang out with friends. Love the cool vibe it’s going for!"',
    bgLight: 'bg-light-green'
  },
  {
    img: ClientImage6,
    name: 'Vikas Hegde',
    location: 'Travel Blogger',
    testimonial: '"We travel for unique experiences, and Joy n Joyy is already on our itinerary for next year!"',
    bgLight: 'bg-light-yellow'
  },
  {
    img: ClientImage7,
    name: 'Akash M.',
    location: 'IT Professional',
    testimonial: '"The promise of safe rides and thoughtful guest services is exactly what we need for a fun day out with our toddlers."',
    bgLight: 'bg-light-grey'
  },
  {
    img: ClientImage8,
    name: 'Anjali B.',
    location: 'Fitness Coach',
    testimonial: '"The water activities sound like a refreshing way to stay active and unwind at the same time. Counting days!"',
    bgLight: 'bg-light-purple'
  },
  {
    img: ClientImage9,
    name: 'Suresh R.',
    location: 'Retiree',
    testimonial: '"I’m looking forward to the clean, serene spaces for relaxation. A perfect getaway for people of all ages."',
    bgLight: 'bg-light-green'
  },
  {
    img: ClientImage10,
    name: 'Shweta G.',
    location: 'Lifestyle Influencer',
    testimonial: '"The aesthetics of this park will definitely make for some amazing vlogs and posts. Get ready to see a lot of buzz!"',
    bgLight: 'bg-light-green'
  }
];

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  834.98: 2,
  500: 1
};

const TestimonialSection = () => {
  return (
  <Container fluid={false} className='py-4'>    
    <Row className="d-flex justify-content-center align-items-center">
      <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {testimonials.map((testimonial, index) => (
              <Card
                key={index}
                className={testimonial.bgLight}
                style={{
                  borderRadius: '16px',
                  padding: '20px',
                  marginBottom: '20px'
                }}
              >
                <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 mb-4`}>{testimonial.testimonial}</p>
                
                <div className="d-flex align-items-center">
                  <div>
                    <Image
                      src={testimonial.img}
                      alt={testimonial.name}
                      style={{
                        width: '56px',
                        height: '56px',
                      }}
                    />
                  </div>
                  <div className='px-3'>
                    <h6 className="mb-0 fs-20 fw-700 text-black">{testimonial.name}</h6>
                    <p className='fs-16 mb-0 fw-400 text-black'>{testimonial.location}</p>
                  </div>
                </div>
              </Card>
            ))}
          </Masonry>
      </Col>
    </Row>
    </Container>
  );
};

export default TestimonialSection;
