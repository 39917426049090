import { Button, Card, Radio } from 'antd';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './Transportation.css';

const TransportOptions = [
    { id: 1, recommend: 'Recommended', label: "Small Groups", people: "2-4 People", vehicle1: 'Car Rental', vehicle2: 'SUV Rental' },
    { id: 2, label: "Medium Groups", people: "5-10 People" },
    { id: 3, label: "Large Groups", people: "10-20 People" }
];

const Transportation = () => {
    const [transportGroup, setTransportGroup] = useState(null);
    const [selectedVehicle, setSelectedVehicle] = useState(null);

    const handleCardClick = (value) => {
        setTransportGroup(value);
    };

    const handleVehicleSelect = (vehicle) => {
        setSelectedVehicle(vehicle);
    };

    return (
        <div className='p-2 custom-transport-container'>
            <h4 className='fs-24 fw-700 py-2'>Transportation Needs</h4>
            <p className='fs-16 fw-400 py-2'>Choose the Vehicle Based on Group Size</p>

            <Row>
                {TransportOptions.map((option, index) => (
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} key={option.id} className="mb-3">
                        <Card
                            style={{
                                borderRadius: '16px',
                                textAlign: 'left',
                                cursor: 'pointer',
                                border: transportGroup === option.label ? '2px solid #484951' : '1px solid #A0A1AB',
                            }}
                            onClick={() => handleCardClick(option.label)}
                            className={transportGroup === option.label ? 'selected-card' : ''}
                        >
                            <div className="d-flex position-relative gap-1 py-2">
                                <Radio
                                    value={option.label}
                                    checked={transportGroup === option.label}
                                    onChange={() => handleCardClick(option.label)}
                                />
                                <div className="d-flex flex-column w-100">
                                    <p className='fs-14 fw-400 mb-1'>
                                        {option.recommend}
                                    </p>
                                    <p className='fs-16 fw-700 mb-1 text-black'>
                                        {option.label}
                                    </p>
                                    <p className='fs-14 fw-400 mb-1 text-black'>
                                        {option.people}
                                    </p>
                                </div>

                                {index === 0 && (
                                    <div className="d-flex align-items-center gap-2 vehicle-btn">
                                        <Button
                                            type="default"
                                            className="fs-16 fw-600"
                                            size="large"
                                            style={{
                                                padding: '0.75rem 1.5rem',
                                                borderRadius: '1.5rem',
                                                border: selectedVehicle === option.vehicle1 ? '2px solid #484951' : '1px solid #A0A1AB',
                                                background: 'transparent',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleVehicleSelect(option.vehicle1)}
                                        >
                                            {option.vehicle1}
                                        </Button>
                                        <Button
                                            type="default"
                                            className="fs-16 fw-600"
                                            size="large"
                                            style={{
                                                padding: '0.75rem 1.5rem',
                                                borderRadius: '1.5rem',
                                                border: selectedVehicle === option.vehicle2 ? '2px solid #484951' : '1px solid #A0A1AB',
                                                background: 'transparent',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleVehicleSelect(option.vehicle2)}
                                        >
                                            {option.vehicle2}
                                        </Button>
                                    </div>
                                )}
                                
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>

            <div className='d-flex justify-content-end pt-4'>
                <Button
                    type="primary"
                    className="fs-14 fw-600 booking-btn"
                    size="large"
                    style={{width: "237px"}}
                >
                    Continue
                </Button>
            </div>
        </div>
    )
}

export default Transportation














// import { Button, Card, Radio } from 'antd';
// import React, { useState } from 'react';
// import { Col, Row } from 'react-bootstrap';
// import './Transportation.css';

// const TransportOptions = [
//     { id: 1, recommend: 'Recommended', label: "Small Groups", people: "2-4 People", vehicle1: 'Car Rental', vehicle2: 'SUV Rental' },
//     { id: 2, label: "Meidum Groups", people: "5-10 People", vehicle1: 'Tempo Traveller'},
//     { id: 3, label: "Large Groups", people: "10-20 People", vehicle1: 'Mini Bus', vehicle2: 'Full Bus' }
// ];

// const Transportation = () => {
//     const [transportGroup, setTransportGroup] = useState(null);
//     const [selectedVehicle, setSelectedVehicle] = useState(null);

//     const handleCardClick = (value) => {
//         setTransportGroup(value);
//     };

//     const handleVehicleSelect = (vehicle) => {
//         setSelectedVehicle(selectedVehicle === vehicle ? null : vehicle);
//     };

//     return (
//         <div className='p-2 custom-transport-container'>
//             <h4 className='fs-24 fw-700 py-2'>Transportation Needs</h4>
//             <p className='fs-16 fw-400 py-2'>Choose the Vehicle Based on Group Size</p>

//             <Row>
//                 {TransportOptions.map((option) => (
//                     <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} key={option.id} className="mb-3">
//                         <Card
//                             style={{
//                                 borderRadius: '16px',
//                                 textAlign: 'left',
//                                 cursor: 'pointer',
//                                 border: transportGroup === option.label ? '2px solid #484951' : '1px solid #A0A1AB',
//                             }}
//                             onClick={() => handleCardClick(option.label)}
//                             className={transportGroup === option.label ? 'selected-card' : ''}
//                         >
//                             <div className="d-flex position-relative gap-1 py-2">
//                                 <Radio
//                                     value={option.label}
//                                     checked={transportGroup === option.label}
//                                     onChange={() => handleCardClick(option.label)}
//                                 />
//                                 <div className="d-flex flex-column w-100">
//                                     <p className='fs-14 fw-400 mb-1'>
//                                         {option.recommend}
//                                     </p>
//                                     <p className='fs-16 fw-700 mb-1 text-black'>
//                                         {option.label}
//                                     </p>
//                                     <p className='fs-14 fw-400 mb-1 text-black'>
//                                         {option.people}
//                                     </p>
//                                 </div>

//                                 {/* <div className="d-flex align-items-center gap-2 vehicle-btn">
//                                     <Button
//                                         type="default"
//                                         className="fs-16 fw-600"
//                                         size="large"
//                                         style={{
//                                             padding: '0.75rem 1.5rem',
//                                             borderRadius: '1.5rem',
//                                             border: selectedVehicle === option.vehicle1 ? '2px solid #484951' : '1px solid #A0A1AB',
//                                             background: 'transparent',
//                                             cursor: 'pointer',
//                                         }}
//                                         onClick={() => handleVehicleSelect(option.vehicle1)}
//                                     >
//                                         {option.vehicle1}
//                                     </Button>
//                                     <Button
//                                         type="default"
//                                         className="fs-16 fw-600"
//                                         size="large"
//                                         style={{
//                                             padding: '0.75rem 1.5rem',
//                                             borderRadius: '1.5rem',
//                                             border: selectedVehicle === option.vehicle2 ? '2px solid #484951' : '1px solid #A0A1AB',
//                                             background: 'transparent',
//                                             cursor: 'pointer',
//                                         }}
//                                         onClick={() => handleVehicleSelect(option.vehicle2)}
//                                     >
//                                         {option.vehicle2}
//                                     </Button>
//                                 </div> */}

//                                 <div className="d-flex flex-column align-items-end gap-2 vehicle-btn">
//                                     {option.vehicle1 && (
//                                         <div style={{ position: 'relative' }}>
//                                             {/* {selectedVehicle === option.vehicle1 && (
//                                                 <span
//                                                     className="fs-14 fw-500"
//                                                     style={{
//                                                         position: 'absolute',
//                                                         right: 'calc(100% + 10px)',
//                                                         borderRadius: '16px',
//                                                         padding: '0.2rem 0.5rem',
//                                                         border: '1px solid #484951',
//                                                         background: '#f5f5f5',
//                                                     }}
//                                                 >
//                                                     2500 per day
//                                                 </span>
//                                             )} */}
//                                             <Button
//                                                 type="default"
//                                                 className="fs-16 fw-600"
//                                                 size="large"
//                                                 style={{
//                                                     // width: '100%',
//                                                     borderRadius: '1.5rem',
//                                                     border: selectedVehicle === option.vehicle1 ? '2px solid #484951' : '1px solid #A0A1AB',
//                                                     background: 'transparent',
//                                                     cursor: 'pointer',
//                                                 }}
//                                                 onClick={() => handleVehicleSelect(option.vehicle1)}
//                                             >
//                                                 {option.vehicle1}
//                                             </Button>
//                                         </div>
//                                     )}
//                                     {option.vehicle2 && (
//                                         <div style={{ position: 'relative', width: '100%' }}>
//                                             {/* {selectedVehicle === option.vehicle2 && (
//                                                 <span
//                                                     className="fs-14 fw-500 price-tag"
//                                                     style={{
//                                                         position: 'absolute',
//                                                         right: 'calc(100% + 10px)',
//                                                         borderRadius: '16px',
//                                                         padding: '0.2rem 0.5rem',
//                                                         border: '1px solid #484951',
//                                                         background: '#f5f5f5',
//                                                     }}
//                                                 >
//                                                     2500 per day
//                                                 </span>
//                                             )} */}
//                                             <Button
//                                                 type="default"
//                                                 className="fs-16 fw-600"
//                                                 size="large"
//                                                 style={{
//                                                     width: '100%',
//                                                     borderRadius: '1.5rem',
//                                                     border: selectedVehicle === option.vehicle2 ? '2px solid #484951' : '1px solid #A0A1AB',
//                                                     background: 'transparent',
//                                                     cursor: 'pointer',
//                                                 }}
//                                                 onClick={() => handleVehicleSelect(option.vehicle2)}
//                                             >
//                                                 {option.vehicle2}
//                                             </Button>
//                                         </div>
//                                     )}
//                                 </div>
                                
//                             </div>
//                         </Card>
//                     </Col>
//                 ))}
//             </Row>

//             <div className='d-flex justify-content-end pt-4'>
//                 <Button
//                     type="primary"
//                     className="fs-14 fw-600 booking-btn"
//                     size="large"
//                     style={{width: "237px"}}
//                 >
//                     Continue
//                 </Button>
//             </div>
//         </div>
//     )
// }

// export default Transportation












// import { Button, Card, Radio } from 'antd';
// import React, { useState } from 'react';
// import { Col, Row } from 'react-bootstrap';
// import './Transportation.css';

// const TransportOptions = [
//     { id: 1, recommend: 'Recommended', label: "Small Groups", people: "2-4 People", vehicle1: 'Car Rental', vehicle2: 'SUV Rental' },
//     { id: 2, label: "Meidum Groups", people: "5-10 People" },
//     { id: 3, label: "Large Groups", people: "10-20 People" }
// ];

// const Transportation = () => {
//     const [transportGroup, setTransportGroup] = useState(null);
//     const [selectedVehicle, setSelectedVehicle] = useState(null);

//     const handleCardClick = (value) => {
//         setTransportGroup(value);
//     };

//     const handleVehicleSelect = (vehicle) => {
//         setSelectedVehicle(vehicle);
//     };

//     return (
//         <div className='p-2 custom-transport-container'>
//             <h4 className='fs-24 fw-700 py-2'>Transportation Needs</h4>
//             <p className='fs-16 fw-400 py-2'>Choose the Vehicle Based on Group Size</p>

//             <Row>
//                 {TransportOptions.map((option, index) => (
//                     <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} key={option.id} className="mb-3">
//                         <Card
//                             style={{
//                                 borderRadius: '16px',
//                                 textAlign: 'left',
//                                 cursor: 'pointer',
//                                 border: transportGroup === option.label ? '2px solid #484951' : '1px solid #A0A1AB',
//                             }}
//                             onClick={() => handleCardClick(option.label)}
//                             className={transportGroup === option.label ? 'selected-card' : ''}
//                         >
//                             <div className="d-flex position-relative gap-1 py-2">
//                                 <Radio
//                                     value={option.label}
//                                     checked={transportGroup === option.label}
//                                     onChange={() => handleCardClick(option.label)}
//                                 />
//                                 <div className="d-flex flex-column w-100">
//                                     <p className='fs-14 fw-400 mb-1'>
//                                         {option.recommend}
//                                     </p>
//                                     <p className='fs-16 fw-700 mb-1 text-black'>
//                                         {option.label}
//                                     </p>
//                                     <p className='fs-14 fw-400 mb-1 text-black'>
//                                         {option.people}
//                                     </p>
//                                 </div>

//                                 {index === 0 && (
//                                     <div className="d-flex align-items-center gap-2 vehicle-btn">
//                                         <Button
//                                             type="default"
//                                             className="fs-16 fw-600"
//                                             size="large"
//                                             style={{
//                                                 padding: '0.75rem 1.5rem',
//                                                 borderRadius: '1.5rem',
//                                                 border: selectedVehicle === option.vehicle1 ? '2px solid #484951' : '1px solid #A0A1AB',
//                                                 background: 'transparent',
//                                                 cursor: 'pointer',
//                                             }}
//                                             onClick={() => handleVehicleSelect(option.vehicle1)}
//                                         >
//                                             {option.vehicle1}
//                                         </Button>
//                                         <Button
//                                             type="default"
//                                             className="fs-16 fw-600"
//                                             size="large"
//                                             style={{
//                                                 padding: '0.75rem 1.5rem',
//                                                 borderRadius: '1.5rem',
//                                                 border: selectedVehicle === option.vehicle2 ? '2px solid #484951' : '1px solid #A0A1AB',
//                                                 background: 'transparent',
//                                                 cursor: 'pointer',
//                                             }}
//                                             onClick={() => handleVehicleSelect(option.vehicle2)}
//                                         >
//                                             {option.vehicle2}
//                                         </Button>
//                                     </div>
//                                 )}
                                
//                             </div>
//                         </Card>
//                     </Col>
//                 ))}
//             </Row>

//             <div className='d-flex justify-content-end pt-4'>
//                 <Button
//                     type="primary"
//                     className="fs-14 fw-600 booking-btn"
//                     size="large"
//                     style={{width: "237px"}}
//                 >
//                     Continue
//                 </Button>
//             </div>
//         </div>
//     )
// }

// export default Transportation
