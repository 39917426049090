import React from 'react';
import { Container, Row } from 'react-bootstrap';
import './FooterImage.css';

const FooterImage = () => {
  return (
    <Container fluid={true} className='p-0'>
      <Row>
          <div className="footer-background"></div>
      </Row>
    </Container>
  );
}

export default FooterImage;
