import React, { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { DatePicker, Image, Button, Card, InputNumber } from 'antd';
import { UserOutlined, CalendarOutlined } from '@ant-design/icons';
import './booknow.css';
import durationIcon from '../../../assets/images/offerbooking/icons/offericon1.svg'
import checkAvailabilityIcon from '../../../assets/images/offerbooking/icons/offericon2.svg'; 
import ticketIcon from '../../../assets/images/offerbooking/icons/offericon3.svg'; 
import liveGuideIcon from '../../../assets/images/offerbooking/icons/offericon4.svg';

import { Collapse } from 'antd';
import { useBookingRestrictionStore } from '../../../context/BookingRestrictionContext';
const { Panel } = Collapse;

const iconData = [
    
    { icon: durationIcon, title: 'TIMING', description: '11AM-5PM' },
    { icon: checkAvailabilityIcon, title: 'ACTIVITY', description: 'Water Sports' },
    { icon: ticketIcon, title: 'VALID FOR', description: 'Online booking only' },
    { icon: liveGuideIcon, title: 'NO OF ENTRIES', description: 'Up to 20' },
];

const offers = [
    {
      id: 1,
      title: "First 100 Tickets: 80% OFF",
      text: "Limited time only. Book now!",
      code: "JOY80",
    },
    {
      id: 2,
      title: "Tickets 101-200: 70% OFF",
      text: "Secure your tickets today and save big!",
      code: "JOY80",
    },
    {
        id: 3,
        title: "Tickets 201-500: 60% OFF",
        text: "Enjoy fantastic savings. Reserve now!",
        code: "JOY80",
    },
    {
        id: 4,
        title: "Tickets 501-1000: 50% OFF",
        text: "Dive into fun with incredible discounts!",
        code: "JOY80",
    },
];

const BookNow = () => {
    const { showRestrictionModal } = useBookingRestrictionStore();

    const [selectedOffer, setSelectedOffer] = useState(offers[0].id);
    const dateFormat = 'ddd, DD MMM';

    return (
        <Container
            fluid={true}
            className='p-0'
        >
            <div
                className='py-3'
                style={{background: '#DBEBFE'}}
            >
            <Container>
                <Row className=" book-icons-row">
                    {iconData.map((item, index) => (
                        <Col key={index} xxl={3} xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Row className="d-flex align-items-center justify-content-center py-3">
                                <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
                                <Image
                                    src={item.icon}
                                    alt={item.title}
                                    style={{ width: 64, height: 64 }}
                                    preview={false}
                                />
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
                                <div className="text-inner">
                                    <p className="fs-14 fw-600 mb-1">{item.title}</p>
                                    <p className="fs-16 fw-700 text-primary m-0">{item.description}</p>
                                </div>
                                </Col>
                            </Row>
                        </Col>
                    ))}
                </Row>
            </Container>
            </div>

            <Container>
                <Row className='py-5'>
                    <Col xxl={7} xl={7} lg={6} md={12} sm={12} xs={12} className="content-section">
                        <Collapse expandIconPosition="right">
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">About</h5>} key="1" showArrow>
                                <p className="text-black fs-16 fw-400 about-content" onclick="toggleContent(this)">
                                    Dive into Joy n Joyy's launch celebration with exclusive discounts on the first 2,000 tickets. 
                                    Make unforgettable memories with unbeatable savings while enjoying thrilling water adventures.
                                </p>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">What’s Included</h5>} key="2" showArrow>
                            <div>
                                <ul className="text-black fs-16 fw-400">
                                    <li>Entry to Joy n Joyy Waterpark.</li>
                                    <li>Access to all rides and activities.</li>
                                    <li>Launch-day perks like express check-ins for early bookers.</li>
                                </ul>
                            </div>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">What’s not Included</h5>} key="3" showArrow>
                            <div>
                                <ul className="text-black fs-16 fw-400">
                                    <li>Food and beverages (available at discounted prices).</li>
                                    <li>Locker rentals.</li>
                                    <li>Merchandise and souvenirs.</li>
                                </ul>
                            </div>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">What to Expect</h5>} key="4" showArrow>
                                <p className="text-black fs-16 fw-400 about-content" onclick="toggleContent(this)">
                                    Prepare for a day of fun-filled excitement with exhilarating rides, scenic views, and joyous vibes. 
                                    Expect seamless entry and exclusive launch surprises.
                                </p>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Arrival & Departure</h5>} key="5" showArrow>
                                <p className="text-black fs-16 fw-400 about-content" onclick="toggleContent(this)">
                                    Arrive at least 30 minutes before your booked slot to ensure a smooth check-in. 
                                    Plan to leave within park hours to enjoy all activities.
                                </p>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Accessibility</h5>} key="6" showArrow>
                                <p className="text-black fs-16 fw-400 about-content" onclick="toggleContent(this)">
                                    The park is wheelchair-friendly and provides assistance for visitors with special needs. 
                                    Contact us in advance for specific arrangements.
                                </p>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Additional information</h5>} key="7" showArrow>
                            <div>
                                <ul className="text-black fs-16 fw-400">
                                    <li>Online bookings are mandatory for this offer.</li>
                                    <li>Tickets are valid only on designated dates during the launch period.</li>
                                </ul>
                            </div>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Cancellation policy</h5>} key="8" showArrow>
                                <p className="text-black fs-16 fw-400 about-content" onclick="toggleContent(this)">
                                    Launch offers are non-refundable. However, rescheduling may be possible with prior notice.
                                </p>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Help</h5>} key="9" showArrow>
                                <p className="text-black fs-16 fw-400 about-content" onclick="toggleContent(this)">
                                    Need assistance? Reach out at joynjoyy2024@gmail.com or call us at +91-9739777566. 
                                    We’re here to make your visit seamless and enjoyable!
                                </p>
                            </Panel>
                        </Collapse>
                    </Col>

                    <Col xxl={1} xl={1} lg={1}></Col>

                    <Col xxl={4} xl={4} lg={5} md={12} sm={12} xs={12} className="booking-sidebar">
                        <Card
                            className='p-2'
                            style={{backgroundColor: '#F8F8F9', borderRadius: '24px'}}
                        >
                            <h5 className="text-black fs-20 fw-700 mb-4">Reserve your spot</h5>
                                <Row>
                                    <Col xxl={8} xl={8} lg={8} md={10} sm={12} xs={12} className='pb-3'>
                                        <DatePicker 
                                            className='p-2'
                                            style={{ borderRadius: '50px', width:'100%', borderColor: '#A0A1AB' }}
                                            suffixIcon={<CalendarOutlined />}
                                            format={dateFormat}
                                        />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={2} sm={12} xs={12}>
                                        <InputNumber 
                                            className='p-1'
                                            min={1} 
                                            style={{ borderRadius: '50px', width:'100%', borderColor: '#A0A1AB' }} 
                                            prefix={<UserOutlined style={{ color: 'grey' }} />}
                                        />
                                    </Col>
                                </Row>
                                <p className='text-black fs-16 fw-400'>5 Offers available for 19/7/2024</p> 
                                <div
                                    value={selectedOffer}
                                    onChange={e => setSelectedOffer(e.target.value)}
                                    className="offers-radio"
                                    style={{ width: '100%' }}
                                >
                                    {offers.map((offer) => (
                                        <Card
                                            key={offer.id}
                                            className={`offer-card ${selectedOffer === offer.id ? 'selected' : ''} p-2`}
                                            hoverable
                                            style={{ borderRadius: '16px' }}
                                            onClick={() => setSelectedOffer(offer.id)}
                                        >
                                            {/* <div className="radio-wrapper">
                                                <Radio value={offer.id} className="offer-radio" />
                                            </div> */}
                                            <div className="booking-content">
                                                <h5 className="text-black fs-18 fw-700 mb-2">{offer.title}</h5>
                                                <p className="text-black fs-14 fw-400 mb-2">{offer.text}</p>
                                                <Button 
                                                    // type="dashed" 
                                                    size="small" 
                                                    className='fs-14 fw-700 p-3'
                                                    style={{color: '#0256B8', background: '#DBEBFE', width: 119, borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                                >
                                                    {offer.code}
                                                </Button>
                                                <Button
                                                    size='small'
                                                    className='fs-14 fw-700 p-3'
                                                    style={{color: '#FFFFFF', background: '#0675F6', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                                >
                                                    Copy
                                                </Button>
                                            </div>
                                        </Card>
                                    ))}
                                </div>
                                <Button 
                                    type="primary"
                                    size='large' 
                                    className="button book-btn w-100" 
                                    style={{ borderRadius: '25px', marginTop: '1.5rem' }}
                                    onClick={() => showRestrictionModal(true)}
                                >
                                    Book Now
                                </Button>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default BookNow;
