import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import TestimonialSection from '../../components/TestimonialPage/TestimonialSection/TestimonialSection'
import MoreVlogs from '../../components/homepage/MoreVlogs/MoreVlogs'
import NewsAndUpdates from '../../components/homepage/NewsAndUpdates/NewsAndUpdates'
import TestimonialBanner from '../../components/TestimonialPage/TestimonialBanner/TestimonialBanner'

const TestimonialPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  return (
    <Container fluid={true} className="p-0">
        <TestimonialBanner />
        <TestimonialSection/>
        <MoreVlogs/>
        <NewsAndUpdates/>
    </Container>
  )
}

export default TestimonialPage
