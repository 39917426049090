import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Image } from 'antd'
import AboutImage from '../../assets/images/aboutus/location/img-aboutus-hero.webp';
import './AboutUs.css';

const AboutUs = () => {

  return (
    <Container fluid={true} className=" about-container">
      <Row className="d-flex align-items-center justify-content-center">
        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} >
          <Row className="d-flex align-items-center justify-content-center">
            <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12} className='pb-5' style={{paddingLeft: "0px", paddingRight: "0px"}}>
                <Image
                    src={AboutImage}
                    alt={"About"}
                    preview={false}
                    // className='py-4'
                    style={{ borderRadius: '16px'}}
                />
            </Col>
          </Row>

          <Container className=''>
            <Row className="d-flex align-items-center justify-content-center">
              <Col xxl={9} xl={9} lg={9} md={12} sm={12} xs={12}>
                <Row>
                  {/* <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
                    <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                      Our Story
                    </h4>
                  </Col> */}
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    {/* <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                      Joy n Joyy – the first ever Water Park of North-Kanara district is our flagship project, 
                      located on the serene island of Mavinakurva, nestled in the tranquil waters of the river Sharavati. 
                      Just 2.5 kilometres from the Honavar – the taluk headquarters, Joy n Joyy offers a wide range of exhilarating water rides, 
                      wave pools, and various other attractions that cater to visitors of all ages. The park promises a perfect blend of fun and relaxation, 
                      making it an ideal getaway for families, friends, and thrill-seekers alike. From splashing through water slides to basking in the lush surroundings, 
                      Joy n Joyy is set to redefine outdoor entertainment in the region.
                    </p> */}
                    <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                      Atharva Adventures and Resorts Pvt. Ltd. is a dynamic organization dedicated to creating world-class entertainment, adventure sports and, hospitality experiences. 
                      With a passion for adventure and a commitment to enhancing regional tourism, Atharva Adventures is proud to be the parent company behind Joy n Joyy, 
                      a one-of-a-kind water park that is poised to become a leading recreational destination. As we continue to expand, our goal is to bring joy, 
                      adventure, and economic empowerment to the picturesque region of Honavar.
                    </p>
                  </Col>

                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='text-center py-3'>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3526.679292200299!2d74.46144364068041!3d14.272115114525075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbc3b4fbead1153%3A0x849a5b8d43639a05!2sJOY%20N%20JOYY%20WATER%20PARK!5e1!3m2!1sen!2sin!4v1731482657425!5m2!1sen!2sin"
                      width="100%"
                      className="map-large-location"
                      style={{ borderRadius: '24px' }}
                      allowFullScreen
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                      title="Google Maps Embed"
                    ></iframe>
                  </Col>                  
                </Row>
              
                <div className='py-2'>
                  {/* <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                    About Atharva Foundation
                  </h4> */}
                  {/* <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                    Atharva Adventures and Resorts Pvt. Ltd. is a dynamic organization dedicated to creating world-class entertainment, adventure sports and, hospitality experiences. 
                    With a passion for adventure and a commitment to enhancing regional tourism, Atharva Adventures is proud to be the parent company behind Joy n Joyy, 
                    a one-of-a-kind water park that is poised to become a leading recreational destination. As we continue to expand, our goal is to bring joy, 
                    adventure, and economic empowerment to the picturesque region of Honavar.
                  </p> */}
                    <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                      Joy n Joyy – the first ever Water Park of North-Kanara district is our flagship project, 
                      located on the serene island of Mavinakurva, nestled in the tranquil waters of the river Sharavati. 
                      Just 2.5 kilometres from the Honavar – the taluk headquarters, Joy n Joyy offers a wide range of exhilarating water rides, 
                      wave pools, and various other attractions that cater to visitors of all ages. The park promises a perfect blend of fun and relaxation, 
                      making it an ideal getaway for families, friends, and thrill-seekers alike. From splashing through water slides to basking in the lush surroundings, 
                      Joy n Joyy is set to redefine outdoor entertainment in the region.
                    </p>
                    <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                      Looking ahead, Atharva Adventures and Resorts Pvt. Ltd. is excited to unveil its upcoming initiatives, which will include dry amusement games, adventure sports, 
                      and a premium resort on the scenic banks of the Sharavati River. The resort, located adjacent to the water park, will offer luxury accommodations, rejuvenating spa experiences, 
                      and immersive nature escapes, making it a perfect complement to the vibrant Joy n Joyy water park. With thrilling adventure sports and diverse dry games, we aim to establish 
                      this island as a comprehensive entertainment and hospitality hub, attracting visitors from all over the country.
                    </p>
                </div>

                <div className='py-2'>
                  {/* <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                    What JoynJoyy Offers
                  </h4> */}
                  <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                    Under the leadership of our directors, and a dynamic team of our experts, Atharva Adventures and Resorts is committed to 
                    fostering sustainable growth and community development. By focusing on the untapped potential of tier-three towns like Honavar, 
                    we aim to drive economic growth in the region through tourism and entertainment. Our initiatives will create significant 
                    employment opportunities for local residents, enabling them to be part of a thriving industry. From hospitality staff to adventure guides, 
                    we are dedicated to building a locally powered workforce, ensuring that the region's economy is uplifted through skill development and job creation.
                  </p>
                  <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                    At Atharva Adventures and Resorts Pvt. Ltd., we believe in the transformative power of tourism to empower local economies, 
                    and we are excited to be at the forefront of this change, starting right here in the beautiful island of Mavinakurva. 
                    Together, we are building a brighter, more adventurous future for Honavar and beyond.
                  </p>
                </div>

              </Col>
            </Row>
          </Container>      
        </Col>
      </Row>
    </Container>
  )
}

export default AboutUs
