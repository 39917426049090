import { Form, message } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeRequest } from "../../services/api";

const useLogin = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [customerData, setCustomerData] = useState("");
  const customer = localStorage.getItem("email");

  const onFinish = async (values) => {
    console.log(values, "Values");
    console.log("Backend Service URL:", process.env.REACT_APP_BACKEND_SERVICE);

    const loginData = {
      email: values.email,
      password: values.password,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_SERVICE}/customer/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data) {
          console.log("Login successful:", data);
          setCustomerData(data?.data);
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("customerData", JSON.stringify(data.data));
          message.success("Login successful!");
          navigate("/");
        }
      } else {
        const errorData = await response.json();
        console.log("Login failed:", errorData);
        message.error(errorData.message || "Login failed.");
      }
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleForgotPassword = async (values) => {
    const payload = {
      email: values?.email,
    };

    try {
      const response = await makeRequest(
        "post",
        "customer/forgot-password",
        payload
      );

      if (response) {
        message.success("Mail has been sent to your email");
        localStorage.setItem("email", payload.email);
        navigate("/");
      }
    } catch (err) {
      console.log(err, "check error");
      message.error("Something went wrong. Please try again.");
    }
  };

  const handleResetPassword = async (values) => {
    const payload = {
      email: customer,
      password: values.password,
      confirmPassword: values.confirmPassword,
      isPassword: true,
    };

    try {
      const response = await makeRequest(
        "post",
        "customer/reset-password",
        payload
      );

      if (response) {
        message.success("Password Changed Successfully");
        localStorage.removeItem("email", payload.email);
        navigate("/login");
      }
    } catch (err) {
      console.log(err, "check error");
      message.error("Something went wrong. Please try again.");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("customerData");
    message.success("Logout successful!");
    navigate("/login");
  };

  return {
    form,
    onFinish,
    onFinishFailed,
    handleLogout,
    customerData,
    handleForgotPassword,
    handleResetPassword,
  };
};

export default useLogin;
