import React, { useState } from 'react';
import { Calendar } from 'antd';
import dayjs from 'dayjs';
import { Col, Container, Row } from 'react-bootstrap';
import './DateSelection.css';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const DateSelection = ({ onDateSelect }) => {
  const [selectDate, setSelectDate] = useState(dayjs());

  // const onDateChange = (value) => {
  //   if (value.isBefore(dayjs(), 'date')) return;
  //   setSelectDate(value);
  // };

  const handleClick = (date) => {
    if (!date.isBefore(dayjs(), 'date')) {
      const formattedDate = date.format('MM/DD/YY');
      setSelectDate(date);
      onDateSelect(formattedDate)
    }
  };

  const cellRender = (date, targetMonth) => {
    const isSelected = date.isSame(selectDate, 'date');
    const isPastDate = date.isBefore(dayjs(), 'date');
    const isSameMonth = date.isSame(targetMonth, 'month');

    if (!isSameMonth) return <div></div>;

    return (
      <div
        className={`${window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-24'} fw-700 italic m-4 d-flex justify-content-center align-items-center date-select`}
        style={{
          width: '60px',
          height: '60px',
          backgroundColor: isSelected ? '#1890ff' : 'transparent',
          color: isPastDate ? '#d9d9d9' : '#000',
          borderRadius: '50px',
          textAlign: 'center',
          cursor: isPastDate ? 'not-allowed' : 'pointer',
        }}
        onClick={() => handleClick(date)}
      >
        <p style={{ margin: 0 }}>{date.date()}</p>
      </div>
    );
  };

  const onPanelChange = (value) => {
    setSelectDate(value);
  };

  const goToPreviousMonth = () => {
    setSelectDate(selectDate.subtract(1, 'month'));
  };

  const goToNextMonth = () => {
    setSelectDate(selectDate.add(1, 'month'));
  };

  const renderMonth = (month) => (
    <Calendar
      className="custom-calendar"
      value={month}
      fullscreen={false}
      onPanelChange={onPanelChange}
      headerRender={({ value }) => {
        const formattedMonthYear = value.format('MMMM YYYY');

        return (
          <Row justify="center">
            <Col>
              <p className={`${window?.innerWidth > 834.98 ? 'fs-32' : window?.innerWidth <= 425 ? 'fs-22' : 'fs-28'} fw-800 text-primary italic p-4 calendar-month`}>
                {formattedMonthYear}
              </p>
            </Col>
          </Row>
        );
      }}
      dateFullCellRender={(date) => cellRender(date, month)}
    />
  );

  return (
    <Container 
      className='my-4 add-date-container'
      style={{ borderRadius: '24px', backgroundColor: '#F8F8F9' }}
    >
      <Row className='d-flex align-items-center justify-content-center'>
        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>             
          <div className="section-container p-3">
              <p className={`${window?.innerWidth > 834.98 ? 'fs-32' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-32'} fw-700 text-white section-number`}>1</p>
              <div className='w-100'>
                  <h3 className={`${window?.innerWidth > 834.98 ? 'fs-32' : window?.innerWidth <= 425 ? 'fs-22' : 'fs-32'} fw-800 text-black italic`}>Select Date</h3>
                  <div className="d-flex justify-content-between align-items-center">
                      <span className='fs-14 fw-700'>Selected Date: {selectDate.format('DD MMM YYYY')}</span>
                  </div>
              </div>
          </div>

          <div className="border-line mx-4"></div>
              
          <Row className='m-4 calendar-date' style={{backgroundColor: '#ffff', borderRadius: '16px'}}>
            <Col xxl={6} xl={6} lg={12} md={12} sm={12} xs={12}>{renderMonth(selectDate)}</Col>

            <Col xxl={6} xl={6} lg={12} md={12} sm={12} xs={12}>{renderMonth(selectDate.add(1, 'month'))}</Col>       
          
            <Row className="d-flex justify-content-between p-3">
              <Col xs="auto">
                <LeftOutlined onClick={goToPreviousMonth} style={{ fontSize: '24px', cursor: 'pointer' }} />
              </Col>
              <Col xs="auto">
                <RightOutlined onClick={goToNextMonth} style={{ fontSize: '24px', cursor: 'pointer' }} />
              </Col>
            </Row>

          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default DateSelection;
