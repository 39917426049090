import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { DatePicker, Image, Button, Card, InputNumber, Select } from 'antd';
import { UserOutlined, CalendarOutlined } from '@ant-design/icons';
import './destibooking.css';
import agesIcon from '../../../assets/images/topdestination/destibooking-icon/destiicon1.svg'; 
import peopleIcon from '../../../assets/images/topdestination/destibooking-icon/destiicon2.svg'; 
import durationIcon from '../../../assets/images/topdestination/destibooking-icon/destiicon3.svg'; 
import checkAvailabilityIcon from '../../../assets/images/topdestination/destibooking-icon/destiicon4.svg'; 
import liveGuideIcon from '../../../assets/images/topdestination/destibooking-icon/destiicon5.svg';
import destinations from "../../../common/Destination.json";
import { Collapse } from 'antd';
import { Option } from 'antd/es/mentions';
import { useParams } from 'react-router-dom';
import { useBookingRestrictionStore } from '../../../context/BookingRestrictionContext';

const { Panel } = Collapse;

const iconData = [
    { icon: agesIcon, title: 'AGES', description: 'All Age Groups' },
    { icon: peopleIcon, title: 'NO OF PEOPLE', description: 'max of 15 per group' },
    { icon: durationIcon, title: 'DURATION', description: 'NA' },
    { icon: checkAvailabilityIcon, title: 'START TIME', description: 'Check availability' },
    { icon: liveGuideIcon, title: 'LIVE GUIDE', description: 'TOUR GUIDE: Kan / Eng / Hin' },
];

const offers = [
    {
        id: 1,
        title: "Sightseeing Tour by Sedan Car",
        text: "City Sightseeing Budget",
        pricetext1: "Per group (up to 3) x ₹6,759",
        price: "Total ₹6,759",
        pricetext2: "(Per includes taxes and booking fees)",
        times: [
            { id: 1, time: "9:00 am", available: true },
            { id: 2, time: "10:00 am", available: true },
            { id: 3, time: "11:00 am", available: true },
            { id: 4, time: "10:00 am", available: true },
            { id: 5, time: "11:00 am", available: true }
        ]
    },
    {
        id: 2,
        title: "Sightseeing Tour by SUV Car",
        text: "City Sightseeing Premium",
        pricetext1: "Per group (up to 3) x ₹9,759",
        price: "Total ₹9,759",
        pricetext2: "(Per includes taxes and booking fees)",
        times: [
            { id: 1, time: "9:00 am", available: true },
            { id: 2, time: "10:00 am", available: true },
            { id: 3, time: "11:00 am", available: true }
        ]
    }
];

const DestiBooking = () => {
    const [selectedOffer, setSelectedOffer] = useState(offers[0].id);
    const [selectedTime, setSelectedTime] = useState(null);
    const dateFormat = 'ddd, DD MMM';
    const { showRestrictionModal } = useBookingRestrictionStore();

    const handleTimeSelection = (time) => {
        setSelectedTime(time);
    };

    const [destination, setDestination] = useState(null);
    const { id } = useParams();
    
    useEffect(() => {
        const selectedDestination = destinations.find((dest) => dest.id === parseInt(id));
        setDestination(selectedDestination);
    }, [id]);

    if (!destination) return <p>Loading...</p>;

    return (
        <Container 
            fluid={true}
            className='p-0'
        >
            <div
                className='py-3 mt-4'
                style={{background: '#DBEBFE'}}
            >
            <Container>
                <Row className="icons-row">
                    {iconData.map((item, index) => (
                        <Col key={index} xxl={2} xl={2} lg={4} md={4} sm={12} xs={12}>
                            <Row className="d-flex align-items-center justify-content-center py-3">
                                <Col xxl={4} xl={4} lg={12} md={12} sm={3} xs={3} className='pe-3'>
                                    <Image 
                                        src={item.icon} 
                                        alt={item.title}
                                        className='booking-icons'
                                        style={{ width: 64, height: 64 }}
                                        preview={false}
                                    />
                                </Col>
                                <Col xxl={8} xl={8} lg={12} md={12} sm={9} xs={9}>
                                    <div className="text-icon">
                                        <p className="fs-14 fw-600 mb-1">{item.title}</p>
                                        <p className="fs-16 fw-700 text-primary m-0">{item.description}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    ))}
                </Row>
            </Container>
            </div>

            <Container>
                <Row className='py-5'>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="content-section">
                        <Collapse expandIconPosition="right">
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">About the Location</h5>} key="1" showArrow>
                                <p className="text-black fs-16 fw-400 destbook-about-content" onclick="toggleContent(this)">
                                    {destination.about}
                                </p>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Distance from WaterPark</h5>} key="2" showArrow>
                                <p className="text-black fs-16 fw-400 destbook-about-content" onclick="toggleContent(this)">
                                    {destination.distance}
                                </p>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Attractions</h5>} key="3" showArrow>
                            <div>
                                <ul className="text-black fs-16 fw-400 include-content">
                                    {destination.attractions.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Do's and Dont's</h5>} key="4" showArrow>
                            <div>
                                <ul className="text-black fs-16 fw-400 include-content">
                                    {destination.dosdont.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0 ">What’s Included</h5>} key="5" showArrow>
                            <div>
                                <ul className="text-black fs-16 fw-400 include-content">
                                    {destination.whatsIncluded.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">What’s not Included</h5>} key="6" showArrow>
                            <div>
                                <ul className="text-black fs-16 fw-400">
                                    {destination.notIncluded.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Customizing Your Visit</h5>} key="7" showArrow>
                                <p className="text-black fs-16 fw-400 destbook-about-content" onclick="toggleContent(this)">
                                    {destination.customizing}
                                </p>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Additional Information</h5>} key="8" showArrow>
                            <div>
                                <ul className="text-black fs-16 fw-400">
                                    {destination.additional.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Cancellation Policy</h5>} key="9" showArrow>
                                <div>
                                    <p className="text-black fs-16 fw-400">
                                        {destination.cancellationPolicy}
                                    </p>
                                </div>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Dedicated helpline</h5>} key="10" showArrow>
                                <div>
                                    <p className="text-black fs-16 fw-400">
                                        {destination.helpline}
                                    </p>
                                </div>
                            </Panel>
                        </Collapse>
                    </Col>

                    {/* <Col xxl={1} xl={1} lg={1}></Col>

                    <Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12}>
                        <Card 
                            className="p-2" 
                            style={{ borderRadius: '24px', backgroundColor: '#F8F8F9' }}
                        >
                            <h5 className="text-black fs-20 fw-700 mb-4">Reserve your spot</h5>
                            <Row className=''>
                                <Col xxl={9} xl={9} lg={9} md={10} sm={12} xs={12} className='pb-3'>
                                    <DatePicker 
                                        className='p-2'
                                        style={{ borderRadius: '50px', width:'100%', borderColor: '#A0A1AB' }}
                                        suffixIcon={<CalendarOutlined />}
                                        format={dateFormat}                                
                                    />
                                </Col>
                                <Col xxl={3} xl={3} lg={3} md={2} sm={12} xs={12} className='pb-3'>
                                    <InputNumber 
                                        className='p-1'
                                        min={1} 
                                        style={{ borderRadius: '50px', width:'100%', borderColor: '#A0A1AB' }} 
                                        prefix={<UserOutlined style={{ color: 'grey' }} />}
                                    />
                                </Col>

                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Select
                                        placeholder="Group type"
                                        className=''
                                        style={{ borderRadius: '50px', width:'100%', borderColor: '#A0A1AB' }}
                                    >
                                        <Option value="group1">Group Type 1</Option>
                                        <Option value="group2">Group Type 2</Option>
                                    </Select>
                                </Col>
                            </Row>

                            <Row>
                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <p className='text-black fs-16 fw-400' style={{ marginTop: 16 }}>2 Options available for 19/7/2024</p> 
                                    <div 
                                        defaultValue={selectedOffer} 
                                        onChange={e => setSelectedOffer(e.target.value)} 
                                        className="offers-radio" 
                                        style={{ width: '100%' }}
                                    >
                                        {offers.map((offer) => (
                                            <Card 
                                                key={offer.id} 
                                                className={`offer-card ${selectedOffer === offer.id ? 'selected' : ''} p-2`} 
                                                hoverable
                                                style={{ borderRadius: '16px' }}
                                                onClick={() => setSelectedOffer(offer.id)}
                                            >
                                            
                                            <div className="booking-content">
                                                <h5 className="text-black fs-18 fw-700 mb-2">{offer.title}</h5>
                                                <p className="text-black fs-14 fw-400 mb-2">{offer.text}</p>
                                            </div>
                                            <div className='py-4'>
                                                <p className="text-black fs-14 fw-400 mb-2">{offer.pricetext1}</p>
                                                <p className="text-black fs-16 fw-700 mb-2">{offer.price}</p>
                                                <p className="text-black fs-14 fw-400 mb-2">{offer.pricetext2}</p>
                                            </div>
                                            <div className="offer-times">
                                                {offer.times.map((timeSlot) => (
                                                    <Button 
                                                        key={timeSlot.id} 
                                                        className={`desti-booking-btn time-slot-btn ${selectedTime === timeSlot.id ? 'selected' : ''}`}
                                                        onClick={() => handleTimeSelection(timeSlot.id)}
                                                        style={{ borderRadius: '25px' }}
                                                    >
                                                        {timeSlot.time}
                                                    </Button>
                                                ))}
                                            </div>
                                        </Card>                            
                                        ))}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xxl={12} xl={12} lg={12} md={4} sm={12} xs={12}>
                                <Button type="primary" block className="book-now-btn p-4 w-100" style={{ borderRadius: '25px', marginTop: '1.5rem' }} onClick={() => showRestrictionModal(true)}>
                                    Book Now
                                </Button>
                            </Col>
                            </Row>
                        </Card>
                    </Col> */}
                </Row>
            </Container>
        </Container>
    );
};

export default DestiBooking;
