import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import './DressCodeBanner.css'

const DressCodeBanner = () => {
    return (
        <div>
            <video
                className="banner-wrapper-dresscode"
                autoPlay
                loop
                muted
                playsInline
                controls={false}
                disablePictureInPicture
                controlsList="nodownload noplaybackrate nofullscreen"
                style={{
                    pointerEvents: "none"
                }}
            >
                <source 
                    src={require('../../../assets/videos/headervideo/header-video.mp4')} 
                    type="video/mp4"
                />
            </video>
      
            <div className="gradient-overlay-dresscode">
                <Container className="py-5" >
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12} className="text-center">
                            <p className="fs-16 fw-400 text-black">
                                Stay Comfortable, Stay Safe
                            </p>
                            <p className='heading-text fw-800 italic'>
                                Dress Code Guidelines for Your Fun Day  
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default DressCodeBanner
