import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Tabs } from 'antd';
import PersonalPage from '../../components/AccountSection/PersonalPage/PersonalPage';
import WalletPage from '../../components/AccountSection/WalletPage/WalletPage';
import PurchasePage from '../../components/AccountSection/PurchasePage/PurchasePage';
import ReferandEarnPage from '../../components/AccountSection/ReferandEarnPage/ReferandEarnPage';
import RoutingCard from '../../components/homepage/RoutingCard/RoutingCard';
import NewsAndUpdates from '../../components/homepage/NewsAndUpdates/NewsAndUpdates';
import './AccountFlow.css';
import useAccountFlow from '../../hooks/common/useAccountFlow';

const AccountFlow = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);
  
  const [tabPosition, setTabPosition] = useState('left');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 835) {
        setTabPosition('top');
      } else {
        setTabPosition('left');
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  
  const tabContents = [
    {
      label: 'Personal Info',
      key: '1',
      children: <PersonalPage />
    },
    {
      label: 'Purchases',
      key: '2',
      children: <PurchasePage />
    },
    // {
    //   label: 'Wallet',
    //   key: '3',
    //   children: <WalletPage />
    // },
    // {
    //   label: 'Refer and Earn',
    //   key: '4',
    //   children: <ReferandEarnPage />
    // },
    {
      label: 'Logout',
      key: '5',
      content: (
        <div>
          <h3>Logout</h3>
          <p>This is the content for Logout.</p>
        </div>
      ),
    },
  ];

  return (    
    <>
      <RoutingCard/>
      <Container fluid={false} className="my-5">     
      <Row className="d-flex align-items-center justify-content-center">
        <Col xxl={10} xl={10} lg={10} md={12} sm={12} xs={12}>
        <Tabs
            tabPosition={tabPosition}
            items={tabContents}
            className="custom-tabs"
        />
        </Col>
      </Row>
      <Row className="align-items-center justify-content-center pt-5" style={{ minHeight: '100vh' }}>
        <Col xxl={10} xl={10} lg={10} md={10} sm={12} xs={12} className="d-flex justify-content-center">
          <div
            style={{
              width: '95%',
              height: '350px',
              borderRadius: '10px',
              backgroundColor: '#F1F1F3',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <p className="fs-20 fw-700">Ad Banner</p>
          </div>
        </Col>
      </Row>
    </Container>
    <NewsAndUpdates/>
    </>
    
  );
};

export default AccountFlow;
