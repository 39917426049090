import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const Cancellation = () => {
    return (
        // <Container className="py-4">
        //     <Row className="d-flex align-items-center justify-content-center">
        //         <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
        //             <Row className="d-flex align-items-center justify-content-center">
        //                 <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
        //                     <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                         At Joy n Joyy Water Park we strive to provide flexibility and convenience to our guests. Our cancellation and reschedule policies are designed to offer you the best possible experience while ensuring smooth operations.
        //                     </p>
        //                     <div className='py-2'>
        //                         <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
        //                             Reschedule Policy:
        //                         </h4>
        //                         <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                             You can reschedule your park bookings by following these steps:
        //                         </p>
        //                         <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                             <li className='py-2'>
        //                                 Visit www.joynjoyy.com and navigate to "My Bookings".
        //                             </li>
        //                             <li className='py-2'>
        //                                 Enter your registered mobile number to receive an OTP.
        //                             </li>
        //                             <li className='py-2'>
        //                                 Use the OTP to access your upcoming bookings.
        //                             </li>
        //                             <li className='py-2'>
        //                                 Select the booking you wish to modify.
        //                             </li>
        //                         </ul>
        //                     </div>

        //                     <div className='py-2'>
        //                         <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
        //                             Reschedule Guidelines:
        //                         </h4>
        //                         <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                             <li className='py-2'>
        //                                 <span className='fw-700'>No Modification Charges:</span> Rescheduling your booking is free of charge. If there is a change in the ticket price due to moving from a weekday to a weekend or changing the ticket category, the difference in the ticket amount will need to be paid. Conversely, no refund will be provided if the price difference is lower.
        //                             </li>
        //                             <li className='py-2'>
        //                                 <span className='fw-700'>Timeframe for Rescheduling:</span> Modifications can be made up to 12 AM the day before the original booking date. Once rescheduled, the new visit must be within 90 days from the original booking date.
        //                             </li>
        //                             <li className='py-2'>
        //                                 <span className='fw-700'>One Free Modification:</span> Each booking is eligible for one free modification. Any further changes will not be allowed, and cancellation will not be permitted after rescheduling.
        //                             </li>
        //                         </ul>
        //                     </div>

        //                     <div className='py-2'>
        //                         <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
        //                             Cancellation Policy
        //                         </h4>
        //                         <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                             To cancel your park bookings, please visit www.joynjoyy.com and follow the same process outlined in the rescheduling steps.
        //                         </p>
        //                         <div className='py-2'>
        //                             <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
        //                                 Partial and Full Cancellation Guidelines
        //                             </h4>
        //                             <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                                 <li className='py-2'>
        //                                     <span className='fw-700'>48 Hours Before the Visit:</span> If you cancel your booking 48 hours or more before the date of your visit, you will receive a 95% refund of the original amount.
        //                                 </li>
        //                                 <li className='py-2'>
        //                                     <span className='fw-700'>Within 48 Hours of the Visit:</span> If you cancel within 48 hours of the visit date, you will receive an 85% refund.
        //                                 </li>
        //                                 <li className='py-2'>
        //                                     <span className='fw-700'>Day of Visit:</span> Cancellations on the day of the visit are not allowed.
        //                                 </li>
        //                                 <li className='py-2'>
        //                                     <span className='fw-700'>No-Shows:</span> There will be no refund if you do not show up on the day of your visit or after your scheduled date.
        //                                 </li>
        //                             </ul>
        //                         </div>

        //                         <div className='py-2'>
        //                             <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
        //                                 Refund Process
        //                             </h4>
        //                             <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                                 <li className='py-2'>
        //                                     Refunds will be processed within <span className='fw-700'>4 to 7 working days </span>through the same method of payment used for the original booking.
        //                                 </li>
        //                             </ul>
        //                         </div>

        //                     </div>

        //                     <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                         Please note: Once a booking has been modified, it cannot be cancelled. For further assistance, feel free to contact us at +91-9739777566 or email us at joynjoyy2024@gmail.com.
        //                     </p>
        //                     <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
        //                         For further assistance, feel free to contact us at
        //                         <a href="tel:+919739777566" className="text-black fw-700"> +91-9739777566 </a>
        //                         or email us at 
        //                         <a href="mailto:joynjoyy2024@gmail.com" className="text-black fw-700"> joynjoyy2024@gmail.com.</a>
        //                     </p>
        //                 </Col>
        //             </Row>
        //         </Col>
        //     </Row>
        // </Container>

        <Container className="pb-4">
            <Row className="d-flex align-items-center justify-content-center">
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
                            <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black mt-0`}>
                                We understand that plans can change. Our cancellation and rescheduling policy ensure you have the flexibility to adjust your visit. Whether you need to reschedule your tickets or cancel your booking, we’ve got you covered with easy-to-follow guidelines. Please review the policy details below for all the information you need about timelines, charges, and how to manage your booking. We're here to help make your experience stress-free!
                            </p>
                            <p className='sub-heading-text fw-400 text-black'>
                                Below are our policies to ensure a seamless experience
                            </p>
                            <div className='py-2'>
                                <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    Reschedule Policy:
                                </h4>
                                <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    You can reschedule your park bookings by following these steps:
                                </p>
                                <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li className='py-2'>
                                        {"How to Reschedule: Go to www.joynjoyy.com > My Bookings > Enter your mobile number > Use OTP to access bookings > Select booking to modify."}
                                    </li>
                                    <li className='py-2'>
                                        Fees & Timeframe: No modification charge, but pay any price difference. Changes allowed up to 12 AM the day before your visit. The new date must be within 90 days. Only one free modification per booking.
                                    </li>
                                </ul>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    Reschedule Guidelines:
                                </h4>
                                <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li className='py-2'>
                                        <span className='fw-700'>No Modification Charges:</span> Rescheduling your booking is free of charge. However, if there is a change in the ticket price due to park location, moving from a weekday to a weekend, or changing the ticket category, the difference in the ticket amount will need to be paid. Conversely, no refund will be provided if the price difference is lower.
                                    </li>
                                    <li className='py-2'>
                                        <span className='fw-700'>Timeframe for Rescheduling:</span>  Modifications can be made up to 12 AM the day before the original booking date. Once rescheduled, the new visit must be within 90 days from the original booking date.
                                    </li>
                                    <li className='py-2'>
                                        <span className='fw-700'>One Free Modification:</span> Each booking is eligible for one free modification. Any further changes will not be allowed, and cancellation will not be permitted after rescheduling.
                                    </li>
                                </ul>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    Cancellation Policy
                                </h4>
                                <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    To cancel your park bookings, please visit www.joynjoyy.com and follow the same process outlined in the rescheduling steps.
                                </p>
                                <div className='py-2'>
                                    <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                        Partial and Full Cancellation Guidelines
                                    </h4>
                                    <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                        <li className='py-2'>
                                            <span className='fw-700'>48 Hours Before the Visit:</span>  If you cancel your booking 48 hours or more before the date of your visit, you will receive a 95% refund of the original amount.
                                        </li>
                                        <li className='py-2'>
                                            <span className='fw-700'>Within 48 Hours of the Visit:</span> If you cancel within 48 hours of the visit date, you will receive an 85% refund.
                                        </li>
                                        <li className='py-2'>
                                            <span className='fw-700'>Day of Visit:</span> Cancellations on the day of the visit are not allowed.
                                        </li>
                                        <li className='py-2'>
                                            <span className='fw-700'>No-Shows:</span> There will be no refund if you do not show up on the day of your visit or after your scheduled date.
                                        </li>
                                    </ul>
                                </div>

                                <div className='py-2'>
                                    <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                        Refund Process
                                    </h4>
                                    <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                        <li className='py-2'>
                                            Refunds will be processed within <span className='fw-700'>4 to 7 working days </span>through the same method of payment used for the original booking.
                                        </li>
                                    </ul>
                                </div>

                            </div>

                            <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                Please note: Once a booking has been modified, it cannot be cancelled.
                            </p>
                            <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                For any inquiries, feel free to contact us at
                                <a href="tel:+919739777566" className="text-black fw-700"> +91-9739777566 </a>
                                or email us at 
                                <a href="mailto:joynjoyy2024@gmail.com" className="text-black fw-700"> joynjoyy2024@gmail.com.</a>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default Cancellation
