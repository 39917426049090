import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Member1 from '../../../assets/images/aboutus/persons/img-male-1.webp';
import Member2 from '../../../assets/images/aboutus/persons/img-male-2.webp';
import Member3 from '../../../assets/images/aboutus/persons/img-male-4.webp';
import Member4 from '../../../assets/images/aboutus/persons/img-male-3.webp';
import Member5 from '../../../assets/images/aboutus/persons/img-male-4.webp';
import Member6 from '../../../assets/images/aboutus/persons/img-male-1.webp';
import Member7 from '../../../assets/images/aboutus/persons/img-female-3.webp';
import Member8 from '../../../assets/images/aboutus/persons/img-male-4.webp';
import { Image } from 'antd';
import './OurTeam.css';

const teamData = [
    {
        name: "Sudarshan P",
        role: "Managing Director & CEO ",
        image: Member1
    },
    {
        name: "Anup F",
        role: "Director & CTO",
        image: Member2
    },
    {
        name: "Anthony F",
        role: "Independent Director ",
        image: Member3
    },
    {
        name: "Shashank H",
        role: "Finance Head",
        image: Member4
    },
    {
        name: "Prashant H",
        role: "Accounts",
        image: Member5
    },
    {
        name: "Dinesh P",
        role: "Sales Head",
        image: Member6
    },
    // {
    //     name: "Anjali Gupta",
    //     role: "Founder & CEO",
    //     image: Member7
    // },
    // {
    //     name: "Sanjay Rangan",
    //     role: "Founder & CEO",
    //     image: Member8
    // }
];

const OurTeam = () => {
  return (
    <Container fluid={true} style={{background: '#F8EDF4'}}>
        <Row className="d-flex align-items-center justify-content-center team-person p-5">
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <Row className="d-flex align-items-center justify-content-center py-4">
                    <Col xxl={8} xl={8} lg={10} md={12} sm={12} xs={12} className="text-center">
                        <p className="fs-16 mb-0 fw-400 text-black">Meet the Minds Behind the JOY</p>
                        <p className={`${ window?.innerWidth > 834.98 ? "fs-40" : window?.innerWidth <= 425 ? "fs-28" : "fs-36" } fw-800 italic`} >
                            The Visionaries and the dynamic team Turning Dreams into Joy
                        </p>
                        <p className={`${ window?.innerWidth > 834.98 ? "fs-18" : window?.innerWidth <= 425 ? "fs-16" : "fs-18" } fw-400 text-black`} >
                            From creative planners to safety experts, our talented professionals work tirelessly to craft a world of adventure and fun. 
                            Together, we bring passion and precision to every corner of the park, ensuring your visit is nothing short of extraordinary.
                        </p>
                    </Col>
                </Row>

                <Container>
                <Row className="d-flex align-items-center justify-content-center">
                    {teamData.map((member, index) => (
                        <Col key={index} xxl={4} xl={4} lg={4} md={6} sm={6} xs={12} className="mb-4">
                            <Image
                                src={member.image}
                                alt={member.name}
                                className="mb-3"
                                preview={false}
                                style={{borderRadius: '20px'}}
                            />
                                <h5 className="fw-700 fs-28">{member.name}</h5>
                                <p className="fw-400 fs-16">{member.role}</p>
                        </Col>
                    ))}
                </Row>
                </Container>
            </Col>
        </Row>
        
    </Container>
  )
}

export default OurTeam
