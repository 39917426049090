import { Button, Image } from 'antd'
import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import SuccessIcon from '../../../assets/images/bookingimages/SuccessIcon.png';
import { useLocation, useNavigate } from 'react-router-dom';

const AccountSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation(); 

  // const handleContinueBooking = () => {
  //   if (location.pathname === '/addcartinfo' || location.pathname === '/accountsuccess') {
  //     navigate('/reviewbooking'); 
  //   } else if (location.pathname === '/offerpackage' || location.pathname === '/accountsuccess') {
  //     navigate('/groupdetails');
  //   }
  // };

  const handleContinueBooking = () => {
    if (location.pathname.includes('/addcartinfo/login/accountsuccess')) {
      navigate('/reviewbooking'); 
    } else if (location.pathname.includes('/offerpackage/login/accountsuccess')) {
      navigate('/groupdetails');
    }
  };
  
  
  
  return (
    <Container fluid className='py-5'>
      <Row className='d-flex align-items-center justify-content-center py-5'>
        <Col xxl={4} xl={4} lg={6} md={8} sm={12} xs={12}>
          <Card className='p-4' style={{borderRadius: '16px'}}>
            <Row className='d-flex align-items-center justify-content-center'>
              <Col xxl={12} xl={12} lg={12} md={12} sm={10} xs={10} className='text-center'>
                <Image
                  src={SuccessIcon}
                  alt="Login"
                  preview={false}
                  style={{ width: '88px', height: '88px' }}
                />
                <h2 className='fs-24 fw-700 text-black'>Account Created Successfull</h2>
                <p className='fs-16 fw-400' style={{ color: '#60616C' }}>
                Please Check Your Email</p>

                <Button
                  type="primary"
                  size='large'
                  htmlType="submit"
                  className='booking-btn fs-16 fw-700'
                  style={{
                    borderRadius: '28px',
                    padding: '24px',
                    width: '100%'
                  }}
                  onClick={handleContinueBooking}
                >
                  Continue Booking
                </Button>                 
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default AccountSuccess
