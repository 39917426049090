import { axiosInstance } from "./utilities";

export const makeRequest = async (methodType, url, payload, additionalHeaders = {}, status = false) => { 
    const headers = {
        ...additionalHeaders,
        "Authorization": getToken() ? getToken() : ''
    };

    if (payload instanceof FormData) {
        delete headers['Content-Type'];
    }

    try {
        if (methodType.toLowerCase() === 'get' || methodType.toLowerCase() === 'delete') {
            // Handle GET and DELETE requests
            const response = await axiosInstance[methodType](url, {
                headers,
                crossdomain: true,
                ...(methodType.toLowerCase() === 'delete' && payload ? { data: payload } : {})
            });
            return statusHelper(status, response);
        } else {  
            // Handle POST, PUT, PATCH requests
            const response = await axiosInstance[methodType](url, payload, { headers, crossdomain: true });
            return statusHelper(status, response);
        }
    } catch (error) {
        console.error("API Error:", error);
        if (error.response) {
            throw statusHelper(status, error.response);
        } else {
            throw error;
        }
    }
};

var statusHelper = (status, data) => {
    if (data.status === 401) {
        localStorage.removeItem("token")
        window.location.href = process.env.REACT_APP_FRONTEND_SERVICE + '/login';
    }

    return status ? {
        status: data.status,
        response: data.data
    } : data.data;
};

export const getToken = () => {
    return localStorage.getItem('token');
};
