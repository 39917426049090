import React, { useState, useEffect } from 'react';
import './PaymentSuccessSec.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Button, message, QRCode } from 'antd';
import Lottie from 'lottie-react';
import Confetti from 'react-confetti';
import tickAnimation from '../../../common/tick.json';
import useRetail from '../../../hooks/useRetail';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


const PaymentSuccessSec = () => {
  const [confettiVisible, setConfettiVisible] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 330, height: 170 });
  const [isMobileView, setIsMobileView] = useState(false);
  const [bookingData, setBookingData] = useState(null);

  const { getBookingDetails } = useRetail();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const fetchBookingData = async () => {
        const data = await getBookingDetails(id);
        if (data) {
          setBookingData(data);
        } else {
          message.error('Failed to load booking details');
        }
      };
      fetchBookingData();
    }
  }, [id]);

  useEffect(() => {
    setConfettiVisible(true);
    const handleResize = () => {
      setDimensions({ width: 330, height: 170 });
      setIsMobileView(window.innerWidth <= 426);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    const timer = setTimeout(() => {
      setConfettiVisible(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let total = bookingData?.booking?.ticketMeta?.adult + bookingData?.booking?.ticketMeta?.child + bookingData?.booking?.ticketMeta?.senior + bookingData?.booking?.ticketMeta?.disabled;

  const handleDownloadTickets = async () => {
    const element = document.getElementById('ticket-info');
    
    if (!element) {
      message.error("Unable to generate the ticket PDF. Please try again.");
      return;
    }
  
    try {
      const canvas = await html2canvas(element);
      const imageData = canvas.toDataURL('image/png');
  
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: 'a4'
      });
  
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
  
      pdf.addImage(imageData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('Booking_Ticket.pdf');
    } catch (error) {
      console.error("Error generating PDF:", error);
      message.error("An error occurred while generating the PDF.");
    }
  };

  return (
    <Container fluid={true} className="py-5">
      <Row className='justify-content-center position-relative'>
        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='text-center'>
        
          {confettiVisible && (
            <div className="confetti-container">
              <Confetti
                width={dimensions.width}
                height={dimensions.height}
                recycle={false}
              />
            </div>
          )}

          <div className="text-center" style={{ zIndex: '1', position: 'relative' }}>
            <div className="tick-animation">
              <Lottie animationData={tickAnimation} loop={false} />
            </div>

            <p className={`${window?.innerWidth > 834.98 ? 'fs-36' : window?.innerWidth <= 425 ? 'fs-24' : 'fs-32'} fw-700 text-black mb-0`}>Booking Confirmed!</p>
            <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black mb-0`}>Thank you for your purchase!</p>
          </div>
        </Col>
      </Row>

      <Row className='align-items-center justify-content-center py-5' >
        <Col xxl={8} xl={8} lg={10} md={10} sm={12} xs={12} className='ticket-info-card mt-3' style={{borderTopRightRadius:"8px", borderTopLeftRadius:"8px"}}>
          <div id="ticket-info">
          {isMobileView ? (
            <>
              <Row>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='text-center py-4'>
                  <QRCode 
                    value={
                      `Booking ID: ${bookingData?.booking?.bookingId},
                       Booking Date: ${bookingData?.booking?.bookingDate},
                       Visitor Name: ${bookingData?.booking?.fullName},
                       Phone Number: ${bookingData?.booking?.phoneNumber},
                       Total Amount Paid: ₹${bookingData?.booking?.amount}`
                    }
                    size={150}
                    style={{ border: '3px solid #0066cc', borderRadius: '10px' }} 
                  />
                </Col>
              </Row>

              <Row>
                <Col  sm={12} xs={12} className='text-center'>
                  <h5 className='fs-16 fw-400'>Ticket ID:</h5>
                  <p className='fs-16 fw-700'>#{bookingData?.booking?.bookingId || "N/A"}</p>
                </Col>
              </Row>

              <Row className='p-3'>
                <Col sm={6} xs={6} className='pb-3'>
                  <h5 className='fs-16 fw-400'>Date:</h5>
                  <p className='fs-16 fw-700'>{bookingData?.booking?.bookingDate || "N/A"}</p>
                </Col>
                <Col sm={6} xs={6} className='pb-3'>
                  <h5 className='fs-16 fw-400'>No of Tickets:</h5>
                  <p className='fs-16 fw-700'>{total || "N/A"}</p>
                </Col>
                <Col sm={6} xs={6} className='pb-3'>
                  <h5 className='fs-16 fw-400'>Total Amount Paid:</h5>
                  <p className='fs-16 fw-700'>{bookingData?.booking?.amount || "N/A"}</p>
                </Col>
                <Col sm={6} xs={6} className='pb-3'>
                  <h5 className='fs-16 fw-400'>Food:</h5>
                  <p className='fs-16 fw-700'>{bookingData?.booking?.ticketMeta?.foodCount || "N/A"}</p>
                </Col>
                <Col sm={6} xs={6} className='pb-3'>
                  <h5 className='fs-16 fw-400'>Merchandise:</h5>
                  <p className='fs-16 fw-700'>-</p>
                </Col>
                <Col sm={6} xs={6} className='pb-3'>
                  <h5 className='fs-16 fw-400'>Visitor name:</h5>
                  <p className='fs-16 fw-700'>{bookingData?.booking?.fullName || "N/A"}</p>
                </Col>
                <Col sm={6} xs={6} className='pb-3'>
                  <h5 className='fs-16 fw-400'>Contact Info:</h5>
                  <p className='fs-16 fw-700'>{bookingData?.booking?.phoneNumber}</p>
                </Col>          
                <Col sm={6} xs={6} className='pb-3'>
                  <h5 className='fs-16 fw-400'>Ticket Type:</h5>
                  <p className='fs-16 fw-700'>{bookingData?.booking?.bookingType || "N/A"}</p>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={12} className='p-4'>
                  <Row>
                    <Col xxl={4} xl={4} lg={4} md={4}>
                      <h5 className='fs-16 fw-400'>Ticket ID:</h5>
                      <p className='fs-16 fw-700'>#{bookingData?.booking?.bookingId || "N/A"}</p>
                    </Col>

                    <Col xxl={4} xl={4} lg={4} md={4}>
                      <h5 className='fs-16 fw-400'>Date:</h5>
                      <p className='fs-16 fw-700'>{bookingData?.booking?.bookingDate || "N/A"}</p> 
                    </Col>
                  
                    <Col xxl={4} xl={4} lg={4} md={4}>
                      <h5 className='fs-16 fw-400'>No of Tickets:</h5>
                      <p className='fs-16 fw-700'>{total || "N/A"}</p>
                    </Col>

                    <Col xxl={4} xl={4} lg={4} md={4}>
                      <h5 className='fs-16 fw-400'>Total Amount Paid:</h5>
                      <p className='fs-16 fw-700'>{bookingData?.booking?.amount || "N/A"}</p>
                    </Col>

                    <Col xxl={4} xl={4} lg={4} md={4}>
                      <h5 className='fs-16 fw-400'>Food:</h5>
                      <p className='fs-16 fw-700'>{bookingData?.booking?.ticketMeta?.foodCount || "N/A"}</p>
                    </Col>

                    <Col xxl={4} xl={4} lg={4} md={4}>
                      <h5 className='fs-16 fw-400'>Merchandise:</h5>
                      <p className='fs-16 fw-700'>-</p>
                    </Col>

                    <Col xxl={4} xl={4} lg={4} md={4}>
                      <h5 className='fs-16 fw-400'>Visitor name:</h5>
                      <p className='fs-16 fw-700'>{bookingData?.booking?.fullName || "N/A"}</p>
                    </Col>
                  
                    <Col xxl={4} xl={4} lg={4} md={4}>
                      <h5 className='fs-16 fw-400'>Contact Info:</h5>
                      <p className='fs-16 fw-700'>{bookingData?.booking?.phoneNumber || "N/A"}</p>
                    </Col>

                    <Col xxl={4} xl={4} lg={4} md={4}>
                      <h5 className='fs-16 fw-400'>Ticket Type:</h5>
                      <p className='fs-16 fw-700'>{bookingData?.booking?.bookingType || "N/A"}</p>
                    </Col>                  
                  </Row>
                </Col>

                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} className=' py-4'>
                  <QRCode 
                    value={
                      `Booking ID: ${bookingData?.booking?.bookingId},
                       Booking Date: ${bookingData?.booking?.bookingDate},
                       Visitor Name: ${bookingData?.booking?.fullName},
                       Phone Number: ${bookingData?.booking?.phoneNumber},
                       Total Amount Paid: ₹${bookingData?.booking?.amount}`
                    }
                    size={150}
                    className='qr-code'
                    style={{ border: '3px solid #0066cc', borderRadius: '8px' }} 
                  />
                  
                </Col>
              </Row>
            </>
          )}
          </div>
          <Row>
            <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} className="text-center mb-3">
              <Button
                className="fs-16 fw-600 booking-btn"
                size="large"
                style={{ background: 'black', color: 'white', borderColor: 'black', width: '100%' }}
                onClick={handleDownloadTickets}
              >
                Download E-Tickets
              </Button>
            </Col>

            <Col xxl={3} xl={3} lg={3} md={4} sm={12} xs={12} className="text-center mb-3">
              <Button
                className="fs-16 fw-600 booking-btn"
                size="large"
                style={{ borderColor: 'black', width: '100%' }}
              >
                Print Tickets
              </Button>
            </Col>
          </Row>
        
          <Row className='justify-content-center align-item-center mt-5'>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='text-center' style={{ background: '#0675F6', color: '#fff'}}>
              <Row className='p-3'>
                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={12} className='text-start success-offer mt-3'>
                  <h3 className={`${window?.innerWidth > 834.98 ? 'fs-48' : window?.innerWidth <= 425 ? 'fs-32' : 'fs-44'} fw-800 mb-0`}>10% OFF</h3>
                  <h3 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 mb-0`}> on next booking</h3>
                  <p className='fs-14 fw-400'>Enjoy your holidays with us!</p>
                </Col>
                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                  <div className="code-container">
                    <div className="fs-14 fw-600 code-label">YOUR CODE</div>
                    <div className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 mb-0 code-content`}>DRW256816</div>
                  </div>
                  <p className='fs-14 fw-400 mt-2'>Enter this code on the site to get FREE Rides</p>
                </Col>
              </Row>
            </Col>
          </Row>
                          
        </Col>
      </Row>      
    </Container>
  );
};

export default PaymentSuccessSec;
