import React from 'react'
import { Container, Row } from 'react-bootstrap'
import './BookingFooter.css'

const BookingFooter = () => {
    return (
        <Container fluid={true}>
            <Row>        
                <div className="booking-footer"></div>
            </Row>
        </Container>
    )
}

export default BookingFooter
