import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BookingFooter from '../../components/Booking/BookingFooter/BookingFooter';
import { Tabs } from 'antd';
import './CustomBookingPage.css';
import ChooseExperience from '../../components/CustomBooking/ChooseExperience/ChooseExperience';
import SelectDestinations from '../../components/CustomBooking/SelectDestinations/SelectDestinations';
import StayOptions from '../../components/CustomBooking/StayOptions/StayOptions';
import FoodPreferences from '../../components/CustomBooking/FoodPreferences/FoodPreferences';
import Transportation from '../../components/CustomBooking/Transportation/Transportation';
import PlanYourItinerary from '../../components/CustomBooking/PlanYourItinerary/PlanYourItinerary';
import AdditionalServices from '../../components/CustomBooking/AdditionalServices/AdditionalServices';

const CustomBookingPage = () => {
    const [activeKey, setActiveKey] = useState('1');
    const [tabPosition, setTabPosition] = useState('left');

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'instant' });
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 992) {
                setTabPosition('top');
            } else {
                setTabPosition('left');
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const tabContents = [
        { key: '1', label: 'Choose Your Experience', component: <ChooseExperience /> },
        { key: '2', label: 'Select Destinations', component: <SelectDestinations /> },
        { key: '3', label: 'Stay Options', component: <StayOptions /> },
        { key: '4', label: 'Food Preferences', component: <FoodPreferences /> },
        { key: '5', label: 'Transportation', component: <Transportation /> },
        { key: '6', label: 'Plan Your Itinerary', component: <PlanYourItinerary /> },
        { key: '7', label: 'Additional Services', component: <AdditionalServices /> },
    ];

    return (
        <>
        <Container className='py-5'>
            <Row className="d-flex align-items-center justify-content-center">
                <Col xxl={8} xl={8} lg={10} md={10} sm={12} xs={12} className='text-center'>
                    <p className="fs-16 fw-400 text-black">Customize</p>
                    <p className={`${window?.innerWidth > 834.98 ? 'fs-48' : window?.innerWidth <= 425 ? 'fs-32' : 'fs-44' } fw-800 gradient-text italic`}>
                        Custom experience booking
                    </p>
                    <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18' } fw-400 text-black`}>
                        Create Your Perfect Adventure at Joy n Joyy – Custom Day Plans, Destinations & More!
                    </p>
                </Col>
            </Row>

            <div 
                className='my-5 custom-content-bg'
            >
                <Row className="d-flex justify-content-center m-2">
                    <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
                        {/* <div 
                            className="tab-labels-container"
                            style={{
                                background: '#FFFFFF',
                                borderRadius: '24px',
                                padding: '16px',
                                display: 'flex',
                                flexDirection: tabPosition === 'left' ? 'column' : 'row',
                                gap: '16px'
                            }}
                        >
                            {tabContents.map(tab => (
                                <div 
                                    key={tab.key} 
                                    onClick={() => setActiveKey(tab.key)}
                                    className={`custom-tab-label ${activeKey === tab.key ? 'active' : ''} my-3`}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <span className="tab-key-circle">{tab.key}</span> {tab.label}
                                </div>
                            ))}
                        </div> */}
                        <div 
                            className="tab-labels-scroll-container"
                            style={{
                                maxWidth: '100%',
                                height: '100%',
                                overflowX: 'auto',
                                padding: '5px',
                                borderRadius: '24px',
                                background: '#FFFFFF',
                                // scrollbarWidth: 'thin',
                                display: 'flex',
                                flexDirection: tabPosition === 'left' ? 'column' : 'row',
                            }}
                        >
                            <div 
                                className=""
                                style={{
                                    display: 'flex',
                                    flexDirection: tabPosition === 'left' ? 'column' : 'row',
                                    gap: '16px',
                                    flexShrink: 0,
                                }}
                            >
                                {tabContents.map(tab => (
                                    <div 
                                        key={tab.key} 
                                        onClick={() => setActiveKey(tab.key)}
                                        className={`custom-tab-label ${activeKey === tab.key ? 'active' : ''} my-3`}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <span className="tab-key-circle">{tab.key}</span> {tab.label}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Col>

                    <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
                        <div 
                            className="tab-content-container"
                            style={{
                                background: '#FFFFFF',
                                // border: '1px solid #E4E4E7',
                                borderRadius: '24px',
                                // padding: '16px',
                                minHeight: '500px'
                            }}
                        >
                            {tabContents.find(tab => tab.key === activeKey)?.component}
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>

        <BookingFooter />
        
        </>
    )
}

export default CustomBookingPage;












// import React, { useEffect, useState } from 'react';
// import { Col, Container, Row } from 'react-bootstrap';
// import BookingFooter from '../../components/Booking/BookingFooter/BookingFooter';
// import { Tabs } from 'antd';
// import './CustomBookingPage.css';
// import ChooseExperience from '../../components/CustomBooking/ChooseExperience/ChooseExperience';
// import SelectDestinations from '../../components/CustomBooking/SelectDestinations/SelectDestinations';
// import StayOptions from '../../components/CustomBooking/StayOptions/StayOptions';
// import FoodPreferences from '../../components/CustomBooking/FoodPreferences/FoodPreferences';
// import Transportation from '../../components/CustomBooking/Transportation/Transportation';
// import PlanYourItinerary from '../../components/CustomBooking/PlanYourItinerary/PlanYourItinerary';
// import AdditionalServices from '../../components/CustomBooking/AdditionalServices/AdditionalServices';

// const CustomBookingPage = () => {
//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, []);

//     const [tabPosition, setTabPosition] = useState('left');

//     useEffect(() => {
//         const handleResize = () => {
//             if (window.innerWidth < 835) {
//             setTabPosition('top');
//             } else {
//             setTabPosition('left');
//             }
//         };

//         handleResize();
//         window.addEventListener('resize', handleResize);

//         return () => window.removeEventListener('resize', handleResize);
//     }, []);

//     const tabContents = [
//         {
//             label: (
//                 <div className="custom-tab-label">
//                     <span className="tab-key-circle">1</span> Choose Your Experience
//                 </div>
//             ),
//             key: '1',
//             children: <ChooseExperience />
//         },
//         {
//             label: (
//                 <div className="custom-tab-label">
//                     <span className="tab-key-circle">2</span> Select Destinations
//                 </div>
//             ),
//             key: '2',
//             children: <SelectDestinations />
//         },
//         {
//             label: (
//                 <div className="custom-tab-label">
//                     <span className="tab-key-circle">3</span> Stay Options
//                 </div>
//             ),
//             key: '3',
//             children: <StayOptions />
//         },
//         {
//             label: (
//                 <div className="custom-tab-label">
//                     <span className="tab-key-circle">4</span> Food Preferences
//                 </div>
//             ),
//             key: '4',
//             children: <FoodPreferences />
//         },
//         {
//             label: (
//                 <div className="custom-tab-label">
//                     <span className="tab-key-circle">5</span> Transportation
//                 </div>
//             ),
//             key: '5',
//             children: <Transportation />
//         },
//         {
//             label: (
//                 <div className="custom-tab-label">
//                     <span className="tab-key-circle">6</span> Plan Your Itinerary
//                 </div>
//             ),
//             key: '6',
//             children: <PlanYourItinerary />
//         },
//         {
//             label: (
//                 <div className="custom-tab-label">
//                     <span className="tab-key-circle">7</span> Additional Services
//                 </div>
//             ),
//             key: '7',
//             children: <AdditionalServices />
//         },
//     ];

//     return (
//         <>
//         <Container className='py-5'>
//             <Row className="d-flex align-items-center justify-content-center">
//                 <Col xxl={8} xl={8} lg={10} md={10} sm={12} xs={12} className='text-center'>
//                     <p className="fs-16 fw-400 text-black">Customize</p>
//                     <p className={`${window?.innerWidth > 834.98 ? 'fs-48' : window?.innerWidth <= 425 ? 'fs-32' : 'fs-44' } fw-800 gradient-text italic`}>
//                         Custom experience booking
//                     </p>
//                     <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18' } fw-400 text-black`}>
//                         These items can be purchased as memorabilia or for personal use after returning home                    
//                     </p>
//                 </Col>
//             </Row>

//             <div 
//                 className='p-1 my-5'
//                 style={{ 
//                     background: 'linear-gradient(180deg, #FFF9FF 0%, #E8EEFF 100%)',
//                     borderRadius: '48px' }}
//             >
//             <Row 
//                 className="d-flex align-items-center justify-content-center m-5 py-5"
//                 style={{background: '#ffff', borderRadius: '24px'}}
//             >
//                 <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
//                     <Tabs
//                         tabPosition={tabPosition}
//                         items={tabContents}
//                         className="customTabs"
//                     />
//                 </Col>
//             </Row>
//             </div>

//         </Container>

//         <BookingFooter />

//         </>
//     )
// }

// export default CustomBookingPage
