import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "antd";
import "./AdditionalServices.css"
import TextArea from 'antd/es/input/TextArea';

const AdditionalServices = () => {
    return (
        <div className='additional-services ps-2'>
            <h4 className='fs-24 fw-700 py-2'>Additional Services</h4>

            <div className='py-3 pb-3'>
                <Button
                    className="fs-16 fw-700 additional-btn me-3 mb-2"
                    size="middle"
                    style={{borderColor: '#272727'}}
                    //   onClick={() => {
                    //     // navigate('/booking');
                    //     showRestrictionModal(true)
                    //   }}
                >
                    Photographer
                </Button>
                <Button
                    className="fs-16 fw-700 additional-btn"
                    size="middle"
                    style={{borderColor: '#272727'}}
                    //   onClick={() => {
                    //     // navigate('/booking');
                    //     showRestrictionModal(true)
                    //   }}
                >
                    Equipment Rentals
                </Button>
            </div>

            <h4 className='fs-24 fw-700 py-3'>Special Requests</h4>

            <TextArea 
                style={{resize: "none", height: 225}}
                maxLength={300}
                className='py-3'
                placeholder='Please specify any special requests such as birthday surprises, picnic setups, etc.'
            />

            <div className={`d-flex justify-content-end pt-5`}>
                <Button
                    type="primary"
                    className="fs-14 fw-600 booking-btn"
                    size="large"
                    style={{width: "237px"}}
                >
                    Book Now
                </Button>
            </div>
        </div>
    )
}

export default AdditionalServices
