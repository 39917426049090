import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Button } from 'antd'
import './OfferPageBanner.css'

const OfferPageBanner = ({ selectedCategory, setSelectedCategory }) => {
    
    return (        
        <div>
            <video
                className="banner-wrapper-offerpage"
                autoPlay
                loop
                muted
                playsInline
                controls={false}
                disablePictureInPicture
                controlsList="nodownload noplaybackrate nofullscreen"
                style={{
                    pointerEvents: "none"
                }}
            >
                <source 
                    src={require('../../../assets/videos/headervideo/header-video.mp4')} 
                    type="video/mp4"
                />
            </video>

            <div className="gradient-overlay-offerpage">
                <Container fluid={false} className='py-5'>
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xxl={6} xl={6} lg={8} md={10} sm={12} xs={12} className="text-center">
                            <p className="fs-16 fw-400 text-black">
                                Unlock Joy: Exclusive Offers Await!
                            </p>
                            <p className='heading-text fw-800 italic'>
                                Incredible discounts tailored for everyone!
                            </p>
                            <p className='sub-heading-text fw-400 text-black'>
                                Individuals, groups, families, schools, organizations, and early birds. 
                                Don’t miss the chance to dive into the fun with unbeatable deals. 
                                Explore, book, and save today!
                            </p>
                        </Col>
                    </Row>

                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='text-center'>
                            {['All','School / Colleges', 'Corporates', 'Agents', 'Retail'].map((category) => (
                                <Button
                                    key={category}
                                    className="fs-16 fw-700 m-1 booking-btn"
                                    size="large"
                                    style={
                                        selectedCategory === category
                                            ? { background: 'black', color: 'white', borderColor: '#272727' }
                                            : { borderColor: '#272727' }
                                    }
                                    onClick={() => setSelectedCategory(category)}
                                >
                                    {category}
                                </Button>
                            ))}
                        </Col>
                    </Row>           
                </Container>
            </div>
        </div> 
    )
}

export default OfferPageBanner










// import React from 'react'
// import { Col, Container, Row } from 'react-bootstrap'
// import { Button } from 'antd'

// const OfferPageBanner = ({ selectedCategory, setSelectedCategory }) => {
    
//     return (
//         <div>
//             <video
//                 className="banner-wrapper"
//                 autoPlay loop muted
//                 style={{width: '100%', height: '403px', objectFit: 'cover'}}
//             >
//                 <source 
//                     src={require('../../../assets/videos/headervideo/header-video.mp4')} 
//                     type="video/mp4"
//                 />
//             </video>
      
//             <div className="gradient-overlay">
//                 <Container className="py-5" >
//                     <Row className="d-flex align-items-center justify-content-center">
//                         <Col xxl={6} xl={6} lg={8} md={10} sm={10} xs={10} className="text-center">
//                             <p className="fs-16 fw-400 text-black">
//                                 Choose your package
//                             </p>
//                             <p className='heading-text fw-800 italic'>
//                                 Offers & packages
//                             </p>
//                             <p className='sub-heading-text fw-400 text-black'>
//                                 Sign up and be the first to know the latest news, offers, promotions and special events of our Water World.
//                             </p>
//                         </Col>
//                     </Row>

//                     <Row className='d-flex align-items-center justify-content-center'>
//                         <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='text-center'>
//                             {['School / Colleges', 'Corporates', 'Agents', 'Retail'].map((category) => (
//                                 <Button
//                                     key={category}
//                                     className="fs-16 fw-700 m-1 booking-btn"
//                                     size="large"
//                                     style={
//                                         selectedCategory === category
//                                             ? { background: 'black', color: 'white', borderColor: '#272727' }
//                                             : { borderColor: '#272727' }
//                                     }
//                                     onClick={() => setSelectedCategory(category)}
//                                 >
//                                     {category}
//                                 </Button>
//                             ))}
//                         </Col>
//                     </Row>
//                 </Container>
//             </div>
//         </div>
//     )
// }

// export default OfferPageBanner
