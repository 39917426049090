import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import { Button } from 'antd'
import './FaqBanner.css'

const FaqBanner = ({ categories, selectedCategory, setSelectedCategory }) => {

    return (
        <div>
            <video
                className="banner-wrapper-faq"
                autoPlay
                loop
                muted
                playsInline
                controls={false}
                disablePictureInPicture
                controlsList="nodownload noplaybackrate nofullscreen"
                style={{width: '100%', height: '495px', objectFit: 'cover', pointerEvents: "none"}}
            >
                <source 
                    src={require('../../../assets/videos/headervideo/header-video.mp4')} 
                    type="video/mp4"
                />
            </video>
      
            <div className="gradient-overlay-faq">
                <Container className="py-5" >
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xxl={6} xl={6} lg={8} md={10} sm={10} xs={10} className="text-center">
                            <p className="fs-16 fw-400 text-black">
                                Got Questions? We’ve Got Answers!
                            </p>
                            <p className='heading-text fw-800 italic'>
                                Everything You Need to Know About Your Visit
                            </p>
                            <p className='sub-heading-text fw-400 text-black'>
                                From ticketing details to park rules, our FAQ section is here to guide you. 
                                Whether you're planning your trip, curious about amenities, or need help with bookings, 
                                find all the information you need to make your experience smooth and enjoyable. 
                                If you can’t find what you’re looking for, feel free to reach out to our team—we’re happy to assist!
                            </p>
                        </Col>
                    </Row>

                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='text-center'>
                            {['Ticket related queries', 'Visit with kids', 'Locker'].map((category) => (
                                <Button
                                    key={category}
                                    className="fs-16 fw-700 m-1 booking-btn"
                                    size="large"
                                    style={
                                        selectedCategory === category
                                            ? { background: 'black', color: 'white', borderColor: '#272727' }
                                            : { borderColor: '#272727' }
                                    }
                                    onClick={() => setSelectedCategory(category)}
                                >
                                    {category}
                                </Button>
                            ))}
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default FaqBanner
