import AWS from 'aws-sdk';

AWS.config.region = process.env.REACT_APP_S3_REGION;
AWS.config.accessKeyId = process.env.REACT_APP_S3_ACCESS_KEY;
AWS.config.secretAccessKey = process.env.REACT_APP_S3_SECRET_KEY;
AWS.config.signatureVersion = "v4"

export const getSignedUrl = (key) => {
    const s3Bucket = new AWS.S3({
        params: {
            Bucket: process.env.REACT_APP_S3_BUCKET,
        },
    });

    return s3Bucket.getSignedUrl("getObject", {
        Bucket: process.env.AWS_S3_BUCKET,
        Key: key,
        Expires: 14400,
    });
};

export const getBufferData = async (key) => {
    const s3Bucket = new AWS.S3({
        params: {
            Bucket: process.env.REACT_APP_S3_BUCKET,
        },
    });
    let response = await s3Bucket.getObject({ Bucket: process.env.REACT_APP_S3_BUCKET, Key: key }).promise();
    const base64String = btoa(
        new Uint8Array(response.Body).reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
    return `data:image/png;base64,${base64String}`;
};

export const getUrl = (key) => {
    return process.env.REACT_APP_S3_BASE_URL + key;
};