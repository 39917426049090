import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import RefundReturnComp from '../../components/RefundReturnComp/RefundReturnComp'
import NewsAndUpdates from '../../components/homepage/NewsAndUpdates/NewsAndUpdates'
import RefundBanner from '../../components/RefundReturnComp/RefundBanner/RefundBanner'

const RefundReturn = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

    return (
        <Container fluid={true} className="p-0">
          <RefundBanner />
          <RefundReturnComp/>
          <NewsAndUpdates />
        </Container>
      )
}

export default RefundReturn