import React from "react";
import "./VisiterPage.css";
import destinations from "../../../common/Destination.json";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Image } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useBookingRestrictionStore } from "../../../context/BookingRestrictionContext";


const VisiterPage = () => {
  const navigate = useNavigate();
  const { showRestrictionModal } = useBookingRestrictionStore();

  const handleBookingClick = (id) => {
    navigate(`/destinationbooking/${id}`);
  };

  return (
    <Container fluid={false} className="py-5 top-destination">
      <Row>
        {destinations.map((destination) => (
          <Col 
            key={destination.id}
            xxl={destination.id === 1 || destination.id === 8 ? 6 : 3}
            xl={destination.id === 1 || destination.id === 8 ? 6 : 3}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="pb-4"
          >
            <div className="position-relative visit-img">
              <Image
                src={require(`../../../assets/images/topdestination/destinationimg/${destination.image}`)}
                className='visit-zoom'
                alt={"SharavatiRiver"}
                preview={false}
              />

              <Button
                type="default"
                className="fs-16 fw-700  btn-sharavati"
                size="large"
                style={{borderRadius: '28px'}}
                onClick={() => handleBookingClick(destination.id)}
              >
                {destination.name}
                <ArrowRightOutlined />
              </Button>
            </div>
          </Col>
        ))}

        <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={12} className="pb-4">
            <div className="position-relative">
                <div className="position-relative desti-custom">
                    <p className={`${window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'} fw-700 italic mx-4 pt-4`}>
                      "Tailor Your Joy: Custom Experience Awaits!"
                    </p>
                    <p className="mx-4 fs-16 fw-400">
                      Looking for a personalized adventure? Reach out today to craft a plan that matches your vibe. Call us now to start personalizing your unforgettable fun!
                    </p>
                    <Button
                        type="default"
                        className="fs-16 fw-700 custom-btn"
                        size="large"
                        style={{background: '#ffff'}}
                        onClick={() => {
                          navigate('/custom-booking');
                        }}
                    >
                        Custom
                        <ArrowRightOutlined />
                    </Button>
                </div>
            </div>
        </Col>
      </Row>
    </Container>
  );
};

export default VisiterPage;
