import React from 'react'

import { Col, Container, Row } from 'react-bootstrap'

const ServicesComp = () => {
    return (
        <Container className="py-4">
            <Row className="align-items-center justify-content-center">
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
                            <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                At Joy n Joyy Water Park, we offer a wide range of thrilling water rides, fun activities, and family-friendly services to ensure every guest enjoys a day full of excitement and adventure. Located on the scenic banks of the Sharavati River, our park combines breathtaking coastal beauty with adrenaline-packed experiences. From water slides to wave pools, we have something for everyone.
                            </p>

                            <div className='py-2'>
                                <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    Water Rides & Attractions
                                </h4>
                                <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li className='py-2'>
                                        <span className='fw-700'>Water Slides:</span> Experience the rush of speeding down our exhilarating water slides, designed to thrill guests of all ages.
                                    </li>
                                    <li className='py-2'>
                                        <span className='fw-700'>Wave Pool:</span> Dive into our wave pool, where you can ride the waves or relax by the shore. It’s the perfect spot to feel the rhythm of the ocean without leaving the park.
                                    </li>
                                    <li className='py-2'>
                                        <span className='fw-700'>Lazy Crazy River:</span> Drift lazily along the Lazy Crazy River, or let the twists and turns surprise you. This ride offers a mix of relaxation and fun!
                                    </li>
                                    <li className='py-2'>
                                        <span className='fw-700'>Rain Dance Arena:</span> Dance to upbeat music in our Rain Dance Arena, showered by refreshing water. Enjoy the coastal vibe while moving to the rhythm!
                                    </li>
                                    <li className='py-2'>
                                        <span className='fw-700'>Swimming Pools:</span> Whether you're looking to swim some laps or cool off with a casual dip, our swimming pools offer a refreshing break from the excitement.
                                    </li>
                                    <li className='py-2'>
                                        <span className='fw-700'>Children’s Play Area:</span> A special, safe zone filled with mini water slides, splash areas, and gentle water games designed specifically for children to enjoy.
                                    </li>
                                </ul>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    Coming Soon
                                </h4>
                                <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    We’re constantly expanding to bring even more fun and excitement to Joy n Joyy Water Park! Here’s a sneak peek of what’s coming soon:                            </p>
                                <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li className='py-2'>
                                        <span className='fw-700'>Virtual Reality Theatre:</span> Get ready to immerse yourself in a completely virtual environment with our upcoming Virtual Reality Theatre. Experience adventures and sights like never before!
                                    </li>
                                    <li className='py-2'>
                                        <span className='fw-700'>Dry Games Arena:</span> Perfect for all seasons, our soon-to-open Dry Games Arena will feature a variety of engaging games and activities that do not involve water. Ideal for days when you want to stay dry but still have loads of fun!
                                    </li>
                                </ul>
                            </div>


                            <div className='py-2'>
                                <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    Dining and Food Courts
                                </h4>
                                <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    No trip to Joy n Joyy Water Park is complete without trying our delicious food offerings:
                                </p>
                                <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li className='py-2'>
                                        <span className='fw-700'>Food Court:</span> Offering a range of meals from local delicacies to international favourites, our food court has something for everyone.
                                    </li>
                                    <li className='py-2'>
                                        <span className='fw-700'>Pre-Ordered Meals:</span> Save time by pre-ordering your meals while booking your tickets online. Collect your meal from the designated counter and enjoy a hassle-free dining experience.
                                    </li>
                                    <li className='py-2'>
                                        <span className='fw-700'>Snacks and Beverages:</span> Keep your energy levels high with light snacks, refreshing drinks, and ice creams available at various snack stations throughout the park.
                                    </li>
                                </ul>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    Merchandise & Souvenir Store
                                </h4>
                                <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    Remember your fun-filled day at Joy n Joyy Water Park with our exclusive park-themed merchandise:
                                </p>
                                <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li className='py-2'>
                                        <span className='fw-700'>Clothing:</span> Branded T-shirts, caps, and swimsuits.
                                    </li>
                                    <li className='py-2'>
                                        <span className='fw-700'>Toys and Gifts:</span> Water toys, floats, and other fun accessories for kids.
                                    </li>
                                    <li className='py-2'>
                                        <span className='fw-700'>Collectibles:</span> Souvenirs like mugs, keychains, and memorabilia.
                                        Pre-purchase merchandise online and pick it up on your visit to the park.
                                    </li>
                                </ul>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    Locker Rentals
                                </h4>
                                <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    Secure your belongings with our convenient locker rental services. Lockers are available for rent throughout the park, ensuring your valuables stay safe while you enjoy the rides and attractions.
                                </p>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    Event Hosting & Group Packages
                                </h4>
                                <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    Joy n Joyy Water Park is the perfect venue for hosting private events and celebrations. We offer customizable packages for various occasions:
                                </p>
                                <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li className='py-2'>
                                        <span className='fw-700'>Birthday Parties:</span> Celebrate with exclusive packages that include cake, decorations, and access to all park attractions.
                                    </li>
                                    <li className='py-2'>
                                        <span className='fw-700'>Corporate Events:</span> Plan your next corporate outing or team-building event with us for a fun-filled day by the river.
                                    </li>
                                    <li className='py-2'>
                                        <span className='fw-700'>School & College Trips:</span> Special group rates and packages are available for educational and fun trips for students.
                                    </li>
                                </ul>
                                <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    For inquiries, contact us via email at 
                                    <a href="mailto:joynjoyy2024@gmail.com" className="text-black fw-700"> joynjoyy2024@gmail.com. </a>
                                    or call 
                                    <a href="tel:+919739777566" className="text-black fw-700"> +91-9739777566. </a>
                                </p>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    Safety and First Aid
                                </h4>
                                <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    Your safety is our top priority. Our park is equipped with the following to ensure a safe environment for all our guests:
                                </p>
                                <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li className='py-2'>
                                        <span className='fw-700'>Trained Lifeguards:</span> Lifeguards are present at all water rides and pools to ensure guest safety.
                                    </li>
                                    <li className='py-2'>
                                        <span className='fw-700'>First Aid:</span> We have a fully-equipped first aid station, and our staff are trained to assist with medical needs or emergencies.
                                    </li>
                                </ul>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    Photography and Videography
                                </h4>
                                <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    Capture the memories of your visit to Joy n Joyy Water Park with our in-park photography and videography services:
                                </p>
                                <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li className='py-2'>
                                        <span className='fw-700'>Professional Photographers:</span> Available to capture special moments at various spots in the park.
                                    </li>
                                    <li className='py-2'>
                                        <span className='fw-700'>Video Recordings:</span> Personalized videos of your exciting rides and fun-filled moments with family and friends.
                                    </li>
                                </ul>
                                <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    Photographs and videos can be purchased at the park's photo booth on your way out.
                                </p>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    Parking Facilities
                                </h4>
                                <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    Our spacious parking lot accommodates all types of vehicles, including cars, motorcycles, and buses, ensuring a hassle-free experience for our visitors.
                                </p>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black text-center`}>
                                    Experience Endless Fun at Joy n Joyy Water Park
                                </h4>
                                <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    Whether you're a thrill-seeker looking for high-speed water slides or a family in search of a fun-filled day with kids, Joy n Joyy Water Park promises an unforgettable adventure. With our new attractions coming soon, there's always something exciting to look forward to. Come explore the beauty of the Sharavati River and make memories with your loved ones.
                                </p>
                                <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    For more information, bookings, or assistance, visit 
                                    <a href='http://joynjoyy.com' target='_blank' className='text-primary'> www.joynjoyy.com </a>, 
                                    call 
                                    <span className='fw-700'><a href="tel:+919739777566" className="text-black"> +91-9739777566 </a></span>, 
                                    or email 
                                    <a href='mailto:joynjoyy2024@gmail.com' className='text-primary'> joynjoyy2024@gmail.com. </a>
                                </p>
                            </div>


                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default ServicesComp