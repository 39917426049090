import React, { useState } from 'react';
import { Card, Radio, Button, Form, Input, Modal, Image } from 'antd';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import FormItem from 'antd/es/form/FormItem';
import './BookingGroup.css';
import SignupIcon from '../../../assets/images/bookingimages/SignupIcon.png';

const BookingGroup = () => {
  const groupOptions = [
    { id: 1, label: "Company" },
    { id: 2, label: "School/College" },
    { id: 3, label: "Any Group" },
    { id: 4, label: "Agency" }
  ];

  const [groupDetails, setGroupDetails] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  const handleCardClick = (value) => {
    setGroupDetails(value);
  };

  const onFinish = (values) => {
    setIsModalVisible(true);

    setTimeout(() => {
      navigate('/reviewbooking'); 
    }, 3000);
  };

  const validateEmailOrPhone = (_, value) => {
    const phoneRegex = /^[0-9]{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!value || emailRegex.test(value) || phoneRegex.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject(new Error('Please enter a valid email or phone number.'));
  };

  return (
    <Container className='py-5'>
      <Row className='d-flex align-items-center justify-content-center'>
        <Col xxl={8} xl={8} lg={10} md={12} sm={12} xs={12}>
          <Card className='p-3' style={{ borderRadius: '16px'}}>
            <h3 className={`${window?.innerWidth > 834.98 ? 'fs-32' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-800 text-black italic`}>Add your group details</h3>

            <Row className="my-4">
              {groupOptions.map(option => (
                <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6} key={option.id} className="mb-3">
                  <Card
                    style={{
                      borderRadius: '16px',
                      textAlign: 'left',
                      cursor: 'pointer',
                      border: groupDetails === option.label ? '1px solid black' : '1px solid #E4E4E7',
                    }}
                    onClick={() => handleCardClick(option.label)}
                    className={groupDetails === option.label ? 'selected-card' : ''}
                  >
                    <Radio
                      value={option.label}
                      checked={groupDetails === option.label}
                      onChange={() => handleCardClick(option.label)}
                    />
                    <div className={`${window?.innerWidth > 834.98 ? 'fs-16' : window?.innerWidth <= 425 ? 'fs-12' : 'fs-16'} fw-700 text-black pt-4`}>
                      {option.label}
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
           
            <Form
              name="signup"
              layout="vertical"
              onFinish={onFinish}                          
            >
              <Row>
                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FormItem
                    style={{ marginBottom: '2rem' }}
                  >
                    <div>
                      <Input
                          className="floating-input fs-14 fw-400"
                          placeholder="Company Name"
                          style={{ border: '1px solid #303136' }}
                      />
                      <label className="floating-label fs-12">Company Name</label>
                    </div>
                  </FormItem>
                </Col>
              
                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FormItem
                    style={{ marginBottom: '2rem' }}
                  >
                    <div>
                      <Input
                        className="floating-input fs-14 fw-400"
                        placeholder="Contact Person"
                        style={{ border: '1px solid #303136' }}
                      />
                      <label className="floating-label fs-12">Contact Person</label>
                    </div>
                  </FormItem>

                </Col>
              
                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="emailOrPhone"
                    rules={[{ validator: validateEmailOrPhone }]}
                    style={{ marginBottom: '2rem' }}
                  >
                    <div>
                      <Input
                        className="floating-input fs-14 fw-400"
                        placeholder="Email or Phone"
                        style={{ border: '1px solid #303136' }}
                      />
                      <label className="floating-label fs-12">Email or Phone</label>
                    </div>
                  </Form.Item>
                </Col>
              
                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      {
                        // required: true,
                        message: 'Please enter a valid phone number',
                        pattern: /^[0-9]{10}$/,
                      },
                    ]}
                    style={{ marginBottom: '2rem' }}
                  >
                    <div>
                      <Input
                        className="floating-input fs-14 fw-400"
                        placeholder="Phone Number"
                        style={{ border: '1px solid #303136' }}
                      />
                      <label className="floating-label fs-12">Phone Number</label>
                    </div>
                  </Form.Item>
                </Col>

                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FormItem
                    style={{ marginBottom: '2rem' }}
                  >
                    <div>
                      <Input
                        className="floating-input fs-14 fw-400"
                        placeholder="Location"
                        style={{ border: '1px solid #303136' }}
                      />
                      <label className="floating-label fs-12">Location</label>
                    </div>
                </FormItem>
                </Col>

                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FormItem
                    style={{ marginBottom: '2rem' }}
                  >
                    <div>
                      <Input
                        className="floating-input fs-14 fw-400"
                        placeholder="Pincode"
                        style={{ border: '1px solid #303136' }}
                      />
                      <label className="floating-label fs-12">Pincode</label>
                    </div>
                  </FormItem>
                </Col>
              </Row>

              <Row className='d-flex align-items-center justify-content-center'>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Button
                    type="primary"
                    size='large'
                    htmlType="submit"
                    className='booking-btn fs-16 fw-700'
                    style={{
                      borderRadius: '28px',
                      padding: '24px',
                      width: '100%',                  
                    }}
                  >
                    Send Request                           
                  </Button>
                </Col>
              </Row>
            </Form> 

            <Modal
              visible={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
              footer={null}
            >
              <Row className='d-flex align-items-center justify-content-center py-3'>
                <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10} className='text-center'>                 
                  <Image
                      src={SignupIcon}
                      alt="Signup"
                      preview={false}
                      style={{ width: '88px', height: '88px' }}
                  />
                  <h5 className='fs-24 fw-700 text-black'>We received your request</h5>
                  <p className='fs-16 fw-400' style={{ color: '#60616C' }}>
                  We adding the best offers for you. Check your given email or whatsapp to continue further
                  </p>                 
                </Col>
              </Row>
            </Modal>

          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BookingGroup;
