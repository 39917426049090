import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import JobDetails from '../../../common/JobDetails.json';
import './careerinfo.css';

const CATEGORIES = [
    { name: "All Jobs", key: 'all' },
    { name: "Human Resources Management", key: 'HR' },
    { name: "Management Jobs", key: 'Management' },
    { name: "Sales & Business Development", key: 'sales' },
    { name: "Food and beverages", key: 'food&beverages' },
    { name: "Finance & Accounting", key: 'Finance' },
    { name: "Life Guards", key: 'LifeGuards' },
    { name: "Technical Jobs", key: 'Technical' }
];

const CareerInfo = ({ jobs, setJobs, setSelectedJob }) => {
    const [category, setCategory] = useState(CATEGORIES[0]?.key);
    const [countObj, setCountObj] = useState({});

    useEffect(() => {
        if (CATEGORIES) {
            let count = {};

            for (let job of JobDetails) {
                if (count[job?.category?.toLowerCase()]) {
                    count[job?.category?.toLowerCase()]++;
                } else {
                    count[job?.category?.toLowerCase()] = 1;
                }
            }

            setCountObj({ ...count });
            setCategory(CATEGORIES[0]?.key);

            let list = JobDetails?.filter(item => {
                return CATEGORIES[0]?.key?.toLowerCase() == 'all' || item?.category?.toLowerCase() == CATEGORIES[0]?.key?.toLowerCase();
            });
            setJobs([...list]);
        }
    }, []);

    const handleChange = (item) => {
        if (item?.key == 'all') {
            setJobs([...JobDetails]);
            setSelectedJob(JobDetails[0]);
        } else {
            let list = JobDetails?.filter(job => job?.category?.toLowerCase() == item?.key?.toLowerCase());
            setJobs([...list]);
            setSelectedJob(list[0]);
        }
        setCategory(item?.key);
    };

    return (
        <div>
            <video
                className="banner-wrapper"
                autoPlay
                loop
                muted
                playsInline
                controls={false}
                disablePictureInPicture
                controlsList="nodownload noplaybackrate nofullscreen"
                style={{width: '100%', height: '600px', objectFit: 'cover', pointerEvents: "none"}}
            >
                <source 
                    src={require('../../../assets/videos/headervideo/header-video.mp4')} 
                    type="video/mp4"
                />
            </video>
      
            <div className="gradient-overlay-career">
                <Container className="py-5" >
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xxl={7} xl={8} lg={8} md={10} sm={10} xs={10} className="text-center">
                            <p className="fs-16 fw-400 text-black">
                                Careers
                            </p>
                            <p className='heading-text fw-800 italic'>
                                Explore your opportunities at JoynJoyy
                            </p>
                            <p className='sub-heading-text fw-400 text-black'>
                                We, at Joy n Joyy Waterpark, focus on providing you with a career that offers challenges and growth. We encourage innovative ideas and thoughts that lead to success and emerging opportunities.
                            </p>
                        </Col>
                    </Row>

                    <Row className='d-flex align-items-center justify-content-center pt-4'>
                        <Col xxl={8} xl={8} lg={10} md={12} sm={12} xs={12} className='text-center'>
                        <div className='button-container'>
                            {CATEGORIES?.map(item => (
                                <Button
                                    className={`fs-16 fw-600 m-1 booking-btn ${category?.toLowerCase() === item?.key?.toLowerCase() ? 'career-btn-active' : ''}`}
                                    size="large"
                                    style={{borderColor: '#272727'}}
                                    onClick={() => handleChange(item)}
                                >
                                    {item?.name} ({item?.key?.toLowerCase() === 'all' ? JobDetails?.length : countObj[item?.key?.toLowerCase()]})
                                </Button>
                            ))}
                    </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
        // <Container fluid={true} className='pt-5 career-info-page'>
        //     <Row className='d-flex align-items-center justify-content-center'>
        //         <Col xxl={6} xl={6} lg={7} md={10} sm={10} xs={12} className='text-center'>
        //             <p className={`${window?.innerWidth > 834.98 ? 'fs-48' : window?.innerWidth <= 425 ? 'fs-32' : 'fs-44'} fw-800 gradient-text italic`}>
        //                 Explore your opportunities at JoynJoyy
        //             </p>
        //             <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400`}>
        //                 We, at Joy n Joyy Waterpark, focus on providing you with a career that offers challenges and growth. We encourage innovative ideas and thoughts that lead to success and emerging opportunities.
        //             </p>
        //         </Col>
        //     </Row>
        //     <Row className='d-flex align-items-center justify-content-center py-5'>
        //         <Col xxl={8} xl={8} lg={10} md={12} sm={12} xs={12}>
        //             <div className='button-container'>
        //                 {CATEGORIES?.map(item => (
        //                     <Button
        //                         className={`fs-16 fw-600 m-1 booking-btn ${category?.toLowerCase() === item?.key?.toLowerCase() ? 'career-btn-active' : ''}`}
        //                         size="large"
        //                         style={{borderColor: '#272727'}}
        //                         onClick={() => handleChange(item)}
        //                     >
        //                         {item?.name} ({item?.key?.toLowerCase() === 'all' ? JobDetails?.length : countObj[item?.key?.toLowerCase()]})
        //                     </Button>
        //                 ))}
        //             </div>
        //         </Col>
        //     </Row>
        // </Container>
    );
};

export default CareerInfo;
